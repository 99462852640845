import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { NavLink } from 'react-router-dom';

import { ReactComponent as EyeIcon } from '../../assets/images/EyeIcon.svg';
import { BookType } from '../../redux/Home';

import styles from './BookComponent.module.scss';

type PropsType = {
  el: BookType;
  index: number;
  x: number | null;
  y: number | null;
};

const cutName = (str: string) => {
  if (str?.length > 40) {
    str = str.substring(0, 35) + '...';
  }
  return str;
};

const cutAuthor = (str: string) => {
  if (str?.length > 20) {
    str = str.substring(0, 15) + '...';
  }
  return str;
};

const BookComponent: React.FC<PropsType> = ({ el, index, x, y }) => {
  return (
    <NavLink
      className={`${styles.book} ${(index + 1) % 3 == 0 && styles.last_book} ${
        (index + 1) % 2 == 0 && styles.mobile_last_book
      }`}
      key={el.id}
      to={
        el.reading_progress
          ? `/reader?bookId=${el.id}&page=${el.reading_progress.page}`
          : `/chapters?bookId=${el.id}`
      }
    >
      <img
        src={`${process.env.REACT_APP_HOST_URL}storage/images/books/${el.id}/thumbs/${el.image}`}
        alt={el.name}
      />
      <div className={styles.bookInfoWrapper}>
        <div className={styles.bookInfo}>
          <h3>{el.author !== 'null' ? cutAuthor(el.author) : ''}</h3>
          <p>{el.name !== 'null' ? el.name : ''}</p>
        </div>
        <LinearProgress
          variant="determinate"
          value={
            el.reading_progress
              ? (el.reading_progress.page / el.pages_count) * 100
              : 0
          }
          classes={{
            root: styles.progressBar,
          }}
        />
        <div
          className={styles.hoverReadButton}
          style={{ left: `${x}px`, top: `${y}px` }}
        >
          <div className={styles.playIcon}>
            <EyeIcon />
          </div>
          <p>Read</p>
        </div>
      </div>

      <div className={styles.hoverPageProgress}>
        {el?.reading_progress?.page || '0'} - {el.pages_count}
      </div>
    </NavLink>
  );
};

export default BookComponent;
