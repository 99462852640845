import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dictionaryAPI } from '../api/dictionary-api';

export type DictionaryType = {
  pronunciation: string;
  definitions: [];
  error: string | null;
};

const defaultState: DictionaryType = {
  pronunciation: '',
  definitions: [],
  error: null,
};

const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState: defaultState,
  reducers: {
    setDictionary: (state, action: PayloadAction<any>) => {
      if (action.payload.pronunciation) {
        if (action.payload.pronunciation.all) {
          state.pronunciation = action.payload.pronunciation.all;
        } else {
          state.pronunciation = action.payload.pronunciation;
        }
      }
      state.definitions =
        action.payload.results && action.payload.results.length
          ? action.payload.results
          : [];
    },
    resetDictionary: (state) => defaultState,
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});

const dictionaryReducer = dictionarySlice.reducer;

export const { setDictionary, resetDictionary, setError } =
  dictionarySlice.actions;
export default dictionaryReducer;

export const getWordThunk =
  (word: string): any =>
  async (dispatch: any) => {
    try {
      const wordInfo = await dictionaryAPI.getWordInfo(word);
      dispatch(setDictionary(wordInfo.data));
    } catch (err) {
      //@ts-ignore
      dispatch(setError(err.response.data.message));
    }
  };
