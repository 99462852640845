import React, { useEffect, useState } from 'react';
import styles from './FocusPopup.module.scss';
import { Popover, IconButton, Input } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import CancelIcon from '@material-ui/icons/Cancel';
import { handlerCSS } from '../../../../../helpers/cssHelper';
import { ReactComponent as TimerIcon } from '../../../../../assets/images/timer.svg';
import Button from '@material-ui/core/Button';
import { setMode, setFocusModeSettings } from '../../../../../redux/Reader';
import { alertShowThunk } from '../../../../../redux/Alert';
import FocusModeTools from '../../../FocusModeTools/FocusModeTools';
import { ReactComponent as FocusIconBig } from '../../../../../assets/images/FocusIconBig.svg';

type Props = {
  open: boolean;
  anchorEl: any;
  handlePopoverClose?: any;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value?: string;
};

const readingHeight = () => {
  const reading = document.getElementById('reading');
  const measures = reading && reading.offsetHeight * 2 + 40;
  return measures;
};

const range = {
  min: 1,
  max: 180,
};

const FocusPopup: React.FC<Props> = (props) => {
  const { open, anchorEl, handlePopoverClose } = props;
  useEffect(() => {
    handlerCSS({ elTop: `${top + 'px'}` });
  });

  const top: any = readingHeight();

  return (
    <Popover
      id="mouse-over-popover"
      classes={{
        root: styles.popoverWrapper,
        paper: styles.popover,
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: -top,
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <div className={styles.wrapper}>
        <IconButton
          className={styles.closeButton}
          onClick={() => handlePopoverClose()}
        >
          <CancelIcon />
        </IconButton>
        <div className={styles.sidebar}>
          <div className={styles.sidebarIcon}>
            <FocusIconBig />
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.title}>Focus mode</div>

          <FocusModeTools
            focusType={'popover'}
            handlePopoverClose={handlePopoverClose}
          />
        </div>
      </div>
    </Popover>
  );
};

export default FocusPopup;
