import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, Provider } from 'react-redux';
import styles from './App.module.scss';
import store from './redux/store';
import Login from './views/Login/Login';
import Welcome from './views/Welcome/Welcome';
import Reader from './views/Reader/Reader';
import Register from './views/Register/Register';
import Profile from './views/Profile/Profile';
import Home from './views/Home/Home';
import Chapters from './views/Chapters/Chapters';
import { withSuspense } from './hoc/withSuspense';
import { profileThunk } from './redux/Auth';
import Alert from './components/common/Alert/Alert';
import { PrivateRoute } from './components/PrivateRoute';
import Terms from './views/Terms/Terms';
import Privacy from './views/Privacy/Privacy';
import FAQ from './views/FAQ/FAQ';
import { AdminRoute } from './components/AdminRoute';
import Dashboard from './views/Admin/Dashboard/Dashboard';
import Users from './views/Admin/Users/Users';
import Books from './views/Admin/Books/Books';
import ErrorPage from './views/ErrorPage/ErrorPage';

class App extends React.Component<any> {
  componentDidMount = () => {
    // profileThunk();
  };
  render() {
    return (
      <div className={styles.App}>
        <Alert />
        <Switch>
          <Route exact path="/admin/login" render={withSuspense(Login)} />
          <AdminRoute exact path="/admin" render={withSuspense(Dashboard)} />
          <AdminRoute exact path="/admin/users" render={withSuspense(Users)} />
          <AdminRoute exact path="/admin/books" render={withSuspense(Books)} />

          <Route exact path="/login" render={withSuspense(Welcome)} />
          <Route path="/register" render={withSuspense(Register)} />
          <Route path="/password/email" render={withSuspense(Register)} />
          <Route
            path="/password/reset/:token"
            render={withSuspense(Register)}
          />
          <Route exact path="/terms" render={withSuspense(Terms)} />
          <Route exact path="/privacy" render={withSuspense(Privacy)} />
          <Route exact path="/help" render={withSuspense(FAQ)} />
          <Route exact path="/404" render={withSuspense(ErrorPage)} />

          <PrivateRoute path="/reader" render={withSuspense(Reader)} />
          <PrivateRoute path="/profile" render={withSuspense(Profile)} />
          <PrivateRoute path="/chapters/:id?" render={withSuspense(Chapters)} />
          <PrivateRoute path="/:back?" exact render={withSuspense(Home)} />

          <Route path="*" render={withSuspense(ErrorPage)} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  initial: state
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ profileThunk }, dispatch);
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

const AppConnected: React.FC<any> = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </BrowserRouter>
  );
};

export default AppConnected;
