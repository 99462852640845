import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { chaptersAPI } from '../api/chapters-api';
import { DispatchType } from './store';

export type ChapterType = {
  id: number;
  book_id: number;
  number: string;
  name: string;
  description: string;
  color: string;
  read_by: string;
  intro_video: string;
  page: number;
  created_at: string;
  updated_at: string;
};

type ChaptersStateType = {
  chapters: Array<ChapterType>;
  currentChapter: ChapterType;
};

const defaultState: ChaptersStateType = {
  chapters: [],
  currentChapter: {} as ChapterType,
};

const chaptersSlice = createSlice({
  name: 'chapters',
  initialState: defaultState,
  reducers: {
    setChapters: (state, action: PayloadAction<Array<ChapterType>>) => {
      state.chapters = action.payload;
    },
    setChapter: (state, action: PayloadAction<ChapterType>) => {
      state.currentChapter = action.payload;
    },
    setChapterFromStore: (state, action: PayloadAction<number>) => {
      for (let key in state.chapters) {
        if (
          state.chapters.hasOwnProperty(key) &&
          state.chapters[key].id === action.payload
        ) {
          state.currentChapter = state.chapters[key];
        }
      }
    },
    resetChapters: (state) => {
      state.chapters = defaultState.chapters;
    },
  },
});

const chaptersReducer = chaptersSlice.reducer;

export const {
  setChapters,
  setChapter,
  setChapterFromStore,
  resetChapters
} = chaptersSlice.actions;
export default chaptersReducer;

export const chaptersListThunk = (bookId: number) => async (
  dispatch: DispatchType
) => {
  try {
    const chaptersList = await chaptersAPI.getChaptersList(bookId);
    const chaptersData = await chaptersList.data;

    dispatch(setChapters(chaptersData));
  } catch (err) {}
};

export const chapterDetailsThunk = (chapterId: number) => async (
  dispatch: DispatchType
) => {
  try {
    const chapterDetails = await chaptersAPI.getChapterDetails(chapterId);
    const chapterData = await chapterDetails.data;

    dispatch(setChapter(chapterData));
  } catch (err) {}
};
