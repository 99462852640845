import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chaptersListThunk, ChapterType } from '../../redux/Chapters';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { StateType } from '../../redux/store';
import { Backdrop, IconButton, Modal, Button } from '@material-ui/core';
import { booksListThunk, BookType, bookDetailsThunk } from '../../redux/Home';
import { useHistory } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import YouTube from 'react-youtube';
import CancelIcon from '@material-ui/icons/Cancel';
import { resetPage } from '../../redux/Page';
import Layout from '../../components/Layout/Layout';
import { showTitle } from '../../helpers/constants';

import { ReactComponent as LeftArrow } from '../../assets/images/LeftArrow.svg';
import { ReactComponent as ChapterVideo } from '../../assets/images/ChapterVideo.svg';
import { ReactComponent as ChapterVideoActive } from '../../assets/images/ChapterVideoActive.svg';

import styles from './Chapters.module.scss';

const queryString = require('query-string');

const videoOpts: any = {
  height: '360',
  width: '640',
  playerVars: {
    autoplay: 1,
  },
};

const Chapters = () => {
  //@ts-ignore

  let { id } = useParams();
  let history = useHistory();
  const chapters = useSelector((state: StateType) => state.chapters.chapters);
  const user = useSelector((state: StateType) => state.auth.userSession.user);
  const home = useSelector((state: StateType) => state.home.currentBook);
  const dispatch = useDispatch();

  showTitle(home ? home.name : 'Chapters');

  const [videoUrl, setVideoUrl] = useState<any>(null);
  const [openVideoPopup, setOpenVideoPopup] = useState(false);

  let query = queryString.parse(useLocation().search);

  useEffect(() => {
    dispatch(resetPage());
    if (home.id !== Number(query.bookId)) {
      dispatch(bookDetailsThunk(query.bookId));
    }
    if (chapters[0]?.book_id !== Number(query.bookId) || !chapters.length) {
      dispatch(chaptersListThunk(query.bookId));
    }
  }, [dispatch, query.bookId]);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleIntroVideo = (event: any, video: string) => {
    event.preventDefault();
    if (video) {
      setVideoUrl(video.replace('https://youtu.be/', ''));
      setOpenVideoPopup(true);
    }
  };

  const closeVideoPopup = () => {
    setOpenVideoPopup(false);
    // setVideoUrl(null);
  };

  return (
    <Layout>
      <main className={styles.main}>
        <div className={styles.toHome}>
          <div className={styles.button} onClick={handleBackClick}>
            <Button>
              <LeftArrow />
            </Button>
            <h1>{home.name}</h1>
          </div>
        </div>
        <div className={styles.chaptersWrapper}>
          {chapters.map((el: ChapterType) => {
            const isActiveChapter =
              home?.reading_progress?.chapter_id === el?.id;
            return (
              <NavLink
                className={`${styles.chapter} ${
                  isActiveChapter && styles.chapterActive
                }`}
                to={`/reader?bookId=${el.book_id}&page=${el.page}`}
                activeClassName={styles.activeLink}
              >
                <div className={styles.chapterHeader}>
                  {/* <div
                  className={styles.bookmark}
                  style={{
                    backgroundColor: el.color
                  }}
                ></div> */}
                  <h3>{el.name}</h3>
                  <div className={styles.chapterAppendix}>
                    {el.read_by || ' '}
                  </div>
                </div>
                <div className={styles.buttons}>
                  {el.intro_video && (
                    <div
                      onClick={(e: any) => {
                        handleIntroVideo(e, el.intro_video);
                      }}
                    >
                      <div className={styles.activeVideoIcon}>
                        <ChapterVideoActive />
                      </div>
                      <div className={styles.videoIcon}>
                        <ChapterVideo />
                      </div>
                    </div>
                  )}
                </div>
              </NavLink>
            );
          })}
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={styles.videoModal}
          open={openVideoPopup}
          onClose={closeVideoPopup}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openVideoPopup}>
            <div className={styles.videoModalContent}>
              <IconButton
                className={styles.closeButton}
                onClick={() => closeVideoPopup()}
              >
                <CancelIcon />
              </IconButton>
              <YouTube
                videoId={videoUrl}
                opts={videoOpts}
                className={styles.videoPlayer}
              />
            </div>
          </Fade>
        </Modal>
      </main>
    </Layout>
  );
};

export default Chapters;
