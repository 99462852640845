import {
  instance,
} from './api';
import {Settings} from "../redux/Reader";

export const readingSettingsApi = {
  getReadingSettings() {
    return instance.get('reading-settings');
  },
  saveReadingSettings(settings: Settings) {
    return instance.post('reading-settings',settings);
  },
  getFonts() {
    return instance.get('fonts');
  },
};
