export const FAQdata: any = {
    general: [
      {
        question: 'What is Leo?',
        answer: 'Leo is a free reading platform for ad students with dyslexia.  It\'s designed to make reading lists more readable.  Over time it\'s going to build up to be a library of the best ad books around.  Oh, and did we mention it\'s free?'
      },
      {
        question: 'Why are there so many options on how to take in the book?',
        answer: 'Everyone who\'s got dyslexia has their own best way to learn, whether it\'s customising the text so it\'s just the way they want it, or listening to the book, or watching, or even a combination of all three.  We want to give you all those options so you can have the best experience just for you.'
      },
      {
        question: 'What other books can I find on it?',
        answer: 'At the moment, you\'ll notice there\'s just one book, although it\'s a really good one!  Very soon it\'s going to be joined by others that are equally good, so watch this space.'
      },
      {
        question: 'Who is behind Leo?',
        answer: 'Leo was set up and is run by people who work in the ad industry.  We\'re not dyselxia experts, but we\'ve worked with them all throughout the development of Leo to make sure this platform if as useful as possible.  Leo is funded by Commercial Break and the DM Trust.'
      },
      {
        question: 'Why is it called Leo?',
        answer: 'Lots of reasons.  From the Rennaisance artist who was is presumed to be dyslexic, to the Spanish for \'I read\', to the strength we hope it gives our readers.  But if we\'re being honest, also because we thought it sounded quite cool.'
      },
      {
        question: 'I\'ve got a problem, what do I do?',
        answer: 'Sorry to hear that.  Have a look in the other sections in FAQ and they might help.  If not, email us at team@leoreader.com'
      },
      {
        question: 'How do I get in touch?',
        answer: 'We want to hear from you!  Your thoughts and questions will help make this better.  You can email us at team@leoreader.com or get in touch via our social channels.'
      },
      {
        question: 'Isn\'t it a bit odd these FAQs are just available as text?',
        answer: 'We thought you might spot that.  It is a bit.  Going forward we\'re going to make them more user-friendly.'
      },
    ],
    reading: [
      {
        question: 'How do I get into a book?',
        answer: 'You just click on the book on the homepage.  It really is as simple as that.'
      },
      {
        question: 'How do I choose a chapter?',
        answer: 'Just click anywhere on one of the chapter bars it and it\'ll take you to the start of the chapter.  And if you\'re wondering what the video player icon is next to it, that\'s a summary of the chapter that you can watch.'
      },
      {
        question: 'How do I customise the book?',
        answer: 'You\'ll see this toolbar [show it] on the left of the screen.  That\'s where you can make all the changes you need to have the book just the way you want it.'
      },
      {
        question: 'How do I customise the text?',
        answer: 'There are all sorts of ways to customise the text to make it just right for you.  On the toolbar on the left, click the \'Reading\' icon, and that will bring the options up.  Text let\'s you adapt the font, Spacing let\'s you adjust spacings, Background let\'s you change the background colour behind the text. '
      },
      {
        question: 'What if I want to undo a change?',
        answer: 'If all you want to do is undo your last change you can do so with the \'undo\' button at the bottom of each section. Or if you want to start it all again, the reset button will set leo back to its orginal default so you can start again.'
      },
      {
        question: 'What\'s focus mode?',
        answer: 'Focus mode strips everything back until all that\'s left is the book page.  If you\'re the kind of person who wants to concentrate for a short time, then select a set time, if you just prefer working without clutter, then you can set it to endless mode.'
      },
      {
        question: 'What does the ruler do?',
        answer: 'We know it\'s sometimes easy to lose your place in all the text, so the ruler function lets you block up the text below and / or above where you\'re reading.  There\'s a video explaining it here: <a href="http://bit.ly/leohowto" target="_blank">http://bit.ly/leohowto</a>'
      },
      {
        question: 'Do you have a dictionary function?',
        answer: 'We do.  If you right click on a word, it\'ll bring up its definition.  You can even have the word you\'ve selected sounded out too. There\'s a video explaining it here: <a href="http://bit.ly/leohowto" target="_blank">http://bit.ly/leohowto</a>'
      },
    ],
    listening: [
      {
        question: 'How can I listen to the book?',
        answer: 'On the toolbar you\'ll see the icon that looks like a speaker, below the audio icon..  Clicking it will turn on sound mode and bring up the audio player at the bottom of your screen.  To play, just hover over the paragraph block you want to start with.  You\'ll see it\'ll become highlighted.  If you click on it, it\'ll bring up two options - either to listen to just that paragraph block or to play the chapter from that point.  Once you\'re playing, you can control everything from the audio player at the bottom.  There\'s a video explaining it here: <a href="http://bit.ly/leohowto" target="_blank">http://bit.ly/leohowto</a>'
      },
      {
        question: 'Who\'s reading it?',
        answer: 'Each chapter is read by a leading creative in the ad industry.  You can find out who on the chapters page of each book.'
      },
      {
        question: 'Why are there different readers?',
        answer: 'Research shows that the same voice can become monotonous and it can be difficult to take in what they\'re saying after a while.  Plus, if you can\'t bear someone\'s voice, you don\'t have to put up with it for too long.  '
      },
      {
        question: 'What are the different ways I might want to use audio?',
        answer: 'In developing this we found some people just wanted to listen, other people wanted to listen and read along at the same time, others wanted to listen to a paragraph after they\'d read to solidify their learning... you get the idea.  We offer options to listen to the whole chapter or individual paragraphs; the rest is up to you.  '
      },
      {
        question: 'What if I want to listen to how a single word is pronounced?',
        answer: 'Click the word and it\'ll bring up the dictionary function. Click on \'Test to speech\' and the word will be sounded out.  Warning, it\'ll be in one of those automated voices.'
      }
    ],
    watching: [
      {
        question: 'How can I watch someone reading the book to me?',
        answer: 'On the toolbar you\'ll see an icon that looks like a rectangle with a \'play\' button in the middle.  Clicking it will select the video function.  As with the audio, you can then hover over a paragraph block that will become highlighted for you.  Then, when you click it, it will give you the option to watch that paragraph only or watch the whole chapter from that point.  Once you\'ve selected either option, the video will appear in the bottom right-hand corner, and will begin to play.  If you want to just have the video and not the text, you can maximise it.  Once it\'s playing, you can control it from the player itself.  There\'s a video explaining all of this, here: <a href="http://bit.ly/leohowto" target="_blank">http://bit.ly/leohowto</a>'
      },
      {
        question: 'Who\'s reading it?',
        answer: 'Each chapter is read by a leading creative in the ad industry.  You can find out who on the chapters page of each book.'
      },
      {
        question: 'Why are there different readers?',
        answer: 'Research shows that the same voice can become monotonous and it can be difficult to take in what they\'re saying after a while.  Plus, if you can\'t bear someone\'s voice, you don\'t have to put up with it for too long.  '
      },
      {
        question: 'What are the different ways I might want to use video?',
        answer: 'In developing this, we found some people just wanted to maximise the video and watch it through, other people wanted to watch it alongside the text to consolidate learning, others wanted to watch certain moments after reading the chapter, other wanted to see mouth shapes to help with pronunciation... you get the idea.  We offer options to watch the whole chapter or individual paragraphs; the rest is up to you.  '
      },
      {
        question: 'Why is every video so different?',
        answer: 'Yes, we\'ve been putting this together during the pandemic, so rather than being able to shoot everyone in the same studio, we\'ve been doing this across their computer screens at home.  So, the recordings might vary a fair bit.  But you get to have a nose around their houses...'
      },
      {
        question: 'What are the videos on the chapter pages for?',
        answer: 'Each chapter has a video summary by the ECD reading that chapter.  It\'ll give you an overview of the big themes in it and their take on them, and will hopefully help consolidate your learning before or after you head into the chapter.'
      }
    ],
  }
