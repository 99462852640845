import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_DICTIONARY_API_URL,
  withCredentials: false,
  headers: {
    "x-rapidapi-host": process.env.REACT_APP_DICTIONARY_API_HOST,
    "x-rapidapi-key": process.env.REACT_APP_DICTIONARY_API_KEY
  },
});

export const dictionaryAPI = {
  getWordInfo(word:string) {
    return instance.get(`words/${word}`);
  },
};
