import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DialogTitle, CircularProgress, Dialog } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { styled } from '@mui/material/styles';
import IconButton from '@material-ui/core/IconButton';

import { StateType, DispatchType } from '../../redux/store';
import { UserType, passwordEmailThunk, loginThunk } from '../../redux/Auth';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import styles from './BurgerMenuWelcome.module.scss';
import ButtonComponent from '../common/Button/Button';

type PropsType = {
  handleClose?: () => void;
};

const CustomTextField = styled(TextField)({
  //styles for TextField(inputs)
  '& .MuiFormHelperText-root.Mui-error': {
    //yup error text style
    color: '#FF8B8B',
    fontSize: 12,
    fontFamily: 'OpenSansBold',
    display: 'none' //REMOVED DEF ERRORS
  },
  '& label.Mui-focused': {
    color: '#24D399',
    borderWidth: 0,
    backgroundColor: 'red'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    '& fieldset': {
      //default styles
      borderColor: '#c5c5c5',
      borderWidth: 0,
      borderRadius: 12
    },
    '&:hover fieldset': {
      //hover
      borderColor: '#666666',
      borderWidth: 0
    },
    '&.Mui-focused fieldset': {
      //focused input
      borderColor: '#c5c5c5',
      borderWidth: 0
    },
    '&.Mui-error fieldset': {
      //error input
      borderWidth: 0,
      borderColor: '#FF8B8B'
    }
  }
});

const BurgerMenuWelcome: React.FC<PropsType> = ({ handleClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state: StateType) => state.auth);

  const { isLoading } = useSelector((state: StateType) => state.home);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassPressed, setIsForgotPassPressed] = useState(false);

  const SignUpSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  });

  const LogInSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    password: Yup.string()
      .min(8, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  });

  const PasswordEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required')
  });

  const initialValues = {
    fullName: ''
  };

  const initialValuesLogin = {
    email: '',
    password: ''
  };

  const initialValuesReset = {
    email: ''
  };

  function onSubmitSignUp(values: any, { setSubmitting }: any) {
    const fullNameSplitted = values.fullName.split(' ');

    localStorage.setItem('firstName', fullNameSplitted[0]);
    localStorage.setItem('lastName', fullNameSplitted[1]);

    history.push('/register');
  }

  function onSubmitLogin(values: any, { setSubmitting }: any) {
    Promise.resolve('').then(
      //@ts-ignore
      dispatch(loginThunk(values.email, values.password)).then(() => {
        setSubmitting(false);
      })
    );
  }

  function onSubmitForgot(values: any, { setSubmitting }: any) {
    Promise.resolve('')
      .then(() => dispatch(passwordEmailThunk(values.email)))
      .then(() => setSubmitting(false));
  }

  const returnStylesWrapper = (
    error: any,
    touched: any,
    values: any,
    creds?: any
  ) => {
    if (error || creds) {
      return styles.inputFieldWrapperError;
    } else if (values && touched && !error) {
      return styles.inputFieldWrapperSuccess;
    } else {
      return styles.inputFieldWrapper;
    }
  };

  const renderLogInModal = () => {
    if (isForgotPassPressed) {
      return (
        <div className={styles.burgerMenu}>
          <DialogTitle>
            <p className={styles.menuTitle}>
              Looks like you forgot <br />
              <span>your password?</span>
            </p>
          </DialogTitle>
          <Formik
            initialValues={initialValuesReset}
            validationSchema={PasswordEmailSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitForgot}
          >
            {({ errors, touched, isSubmitting = false, values }) => (
              <Form>
                <p className={styles.inputText}>Email address</p>
                <div
                  className={returnStylesWrapper(
                    errors.email,
                    touched.email,
                    values.email
                  )}
                >
                  <Field
                    component={CustomTextField}
                    classes={{
                      root: styles.textField
                    }}
                    id="email"
                    name="email"
                    placeholder="Email address"
                    type="email"
                    variant="outlined"
                  />
                </div>
                {!!(errors.email || profile.validation.errors.email) && (
                  <p className={styles.inputTextError}>
                    {!errors.email
                      ? profile.validation.errors.email
                      : errors.email}
                  </p>
                )}
                <ButtonComponent variant="primary" type="submit">
                  <p>Send me a link</p>
                </ButtonComponent>
              </Form>
            )}
          </Formik>
        </div>
      );
    } else {
      return (
        <div className={styles.burgerMenu}>
          <DialogTitle>
            <p className={styles.menuTitle}>
              Welcome back, <span>Let’s get reading!</span>
            </p>
          </DialogTitle>
          <Formik
            initialValues={initialValuesLogin}
            validationSchema={LogInSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitLogin}
          >
            {({ errors, touched, isSubmitting = false, values }) => (
              <Form>
                <p className={styles.inputText}>Email address</p>
                <div
                  className={returnStylesWrapper(
                    errors.email,
                    touched.email,
                    values.email,
                    profile.validation.errors.credentials
                  )}
                >
                  <Field
                    component={CustomTextField}
                    classes={{
                      root: styles.textField
                    }}
                    id="email"
                    name="email"
                    placeholder="Email address"
                    type="email"
                    variant="outlined"
                  />
                </div>
                {!!(errors.email || profile.validation.errors.email) && (
                  <p className={styles.inputTextError}>
                    {!errors.email
                      ? profile.validation.errors.email
                      : errors.email}
                  </p>
                )}

                <p className={styles.inputText}>Password</p>
                <div
                  className={returnStylesWrapper(
                    errors.email,
                    touched.email,
                    values.password,
                    profile.validation.errors.credentials
                  )}
                >
                  <Field
                    component={CustomTextField}
                    classes={{
                      root: styles.textField
                    }}
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    variant="outlined"
                    autoComplete="new-password"
                    InputProps={{
                      border: 'none',
                      endAdornment: (
                        <IconButton className={styles.passwordButton}>
                          <img
                            onClick={() => setShowPassword(!showPassword)}
                            src={require(showPassword
                              ? '../../assets/images/password-icon-visible.svg'
                              : '../../assets/images/password-icon-not-visible.svg')}
                            alt="password"
                            className={styles.passwordIcon}
                          />
                        </IconButton>
                      )
                    }}
                  />
                </div>
                {!!(errors.password || profile.validation.errors.password) && (
                  <p className={styles.inputTextError}>
                    {!errors.password
                      ? profile.validation.errors.password
                      : errors.password}
                  </p>
                )}
                {profile.validation.errors.credentials ? (
                  <p className={styles.inputTextError}>
                    {profile.validation.errors.credentials}
                  </p>
                ) : null}

                <div className={styles.passwordInfoContainer}>
                  <IconButton
                    className={styles.forgotPasswordButton}
                    onClick={() => setIsForgotPassPressed(true)}
                  >
                    Forgot your password?
                  </IconButton>
                </div>
                <ButtonComponent variant="primary" type="submit">
                  <p>Log in</p>
                </ButtonComponent>
              </Form>
            )}
          </Formik>
          <div className={styles.burgerMenu}>
            {isLoading && <CircularProgress className={styles.loader} />}
          </div>
        </div>
      );
    }
  };

  const renderSignUpModal = () => {
    return (
      <div className={styles.burgerMenu}>
        <DialogTitle>
          <p className={styles.menuTitle}>
            Hey, <span>what is you name? 🙂</span>
          </p>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={SignUpSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmitSignUp}
        >
          {({ errors, touched, isSubmitting = false, values }) => (
            <Form>
              <p className={styles.inputText}>Full Name</p>
              <div
                className={returnStylesWrapper(
                  errors.fullName,
                  touched.fullName,
                  values.fullName,
                  profile.validation.errors.credentials
                )}
              >
                <Field
                  component={CustomTextField}
                  classes={{
                    root: styles.textField
                  }}
                  id="fullName"
                  name="fullName"
                  placeholder="Your full name"
                  type="text"
                  variant="outlined"
                />
              </div>
              {!!(errors.fullName || profile.validation.errors.fullName) && (
                <p className={styles.inputTextError}>
                  {!errors.fullName
                    ? profile.validation.errors.fullName
                    : errors.fullName}
                </p>
              )}
              <ButtonComponent variant="primary" type="submit">
                <p>Sign Up!</p>
              </ButtonComponent>
              <div className={styles.alreadyHaveAccount}>
                Already have an account?
              </div>
              <ButtonComponent
                variant="ghost"
                onClick={() => setShowLoginModal(true)}
              >
                <p>Login</p>
              </ButtonComponent>
            </Form>
          )}
        </Formik>
        <div className={styles.burgerMenu}>
          {isLoading && <CircularProgress className={styles.loader} />}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={true}
      className={styles.burgerModal}
      disableEscapeKeyDown
    >
      {!showLoginModal ? renderSignUpModal() : renderLogInModal()}
    </Dialog>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state.auth
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => {
  return bindActionCreators({ loginThunk }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenuWelcome);
