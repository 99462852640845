import React from 'react';
import { Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../redux/store';
import { setSettings } from '../../../redux/Reader';
import SliderComponent from '../Slider/Slider';
import styles from './ToolPopupSlider.module.scss';

const ValueLabelComponent = (props: any) => {
  const { children, open, value } = props;

  return (
    <div>
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    </div>
  );
};

const ToolPopupSlider = () => {
  const dispatch = useDispatch();
  const { font_size, line_height } = useSelector(
    (state: StateType) => state.reader.settings
  );
  const handleFontSize = (event: any, value: any) => {
    dispatch(setSettings({ font_size: value }));
    if (value > line_height) {
      dispatch(setSettings({ line_height: value }));
    }
  };

  return (
    <div className={styles.sliderWrapper}>
      <SliderComponent
        ValueLabelComponent={ValueLabelComponent}
        aria-label="custom thumb label"
        min={12}
        max={72}
        value={Number(font_size)}
        onChange={handleFontSize}
      />
    </div>
  );
};

export default ToolPopupSlider;
