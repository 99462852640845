import React, { useState } from 'react';
import { SketchPicker, Color, ColorResult } from 'react-color';
import styles from './ColorPicker.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../redux/store';
import { setSettings } from '../../../redux/Reader';

const ColorPicker: React.FC = () => {
  const dispatch = useDispatch();
  const { font_color } = useSelector(
    (state: StateType) => state.reader.settings
  );
  const [open, setOpen] = useState(false);

  const handleChangeComplete = (color: ColorResult) => {
    dispatch(setSettings({ font_color: color.hex }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={styles.colorPreview} onClick={() => setOpen(!open)}></div>
      {open && (
        <div className={styles.colorPicker} onMouseLeave={handleClose}>
          <SketchPicker
            color={font_color}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
