import React from 'react';
import { Slider } from '@material-ui/core';
import styles from './Slider.module.scss';

type Props = {
  // eslint-disable-next-line no-unused-vars
  onChange: (e: any, v: any) => void;
  min?: number;
  max?: number;
  value: number;
  step?: number;
  ValueLabelComponent?: any;
};

export const SliderComponent: React.FC<Props> = ({
  min,
  max,
  value,
  onChange,
  step,
  ValueLabelComponent
}) => {
  return (
    <div className={styles.switchWrapper}>
      <Slider
        ValueLabelComponent={ValueLabelComponent}
        className={styles.slider}
        classes={{
          track: styles.track,
          rail: styles.rail,
          thumb: styles.thumb
        }}
        aria-label=""
        min={Number(min)}
        max={Number(max)}
        step={step}
        value={Number(value)}
        onChange={onChange}
      />
    </div>
  );
};

export default SliderComponent;
