import React, { useRef, useState, useEffect } from 'react';
import { Slider, Button } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import YouTube from 'react-youtube';

import { ReactComponent as SkipPreviousIcon } from '../../../assets/images/rewind_10_sec.svg';
import { ReactComponent as SkipNextIcon } from '../../../assets/images/skip_10_sec.svg';
import { ReactComponent as Play } from '../../../assets/images/Play.svg';
import { ReactComponent as Pause } from '../../../assets/images/Pause.svg';
import { ReactComponent as ModalCloseIcon } from '../../../assets/images/modalCloseIcon.svg';

import { setMode } from '../../../redux/Reader';
import { StateType } from '../../../redux/store';
import { useInterval } from '../../../helpers/customHooks';
import {
  setSelectedParagraph,
  ParagraphType,
  setPageNumber
} from '../../../redux/Page';

import styles from './AudioPlayer.module.scss';
import { isMobileWidth } from '../../../helpers/constants';

type PropsAudio = {
  videoId: string;
  startSeconds: number;
  endSeconds: number;
};

const Wave: React.FC<any> = ({
  paragraph,
  isChapter,
  closeAudio,
  chapterPlay,
  isPageEnded,
  playerHide,
  pause,
  start
}: any) => {
  const { page } = useSelector((state: StateType) => state);
  const dispatch = useDispatch();

  const [play, setPlay] = useState(false);
  const [propsAudio, setPropsAudio] = useState<PropsAudio>({} as PropsAudio);
  const [progress, setProgress] = useState(0);
  const [nextParagraph, setNextParagraph] = useState<any>({});
  const [chapterLength, setChapterLength] = useState(0);
  const [delay, setDelay] = useState(300);
  const [currentTime, setCurrentTime] = useState(0);
  const [chosenParagraph, setChosenParagraph] = useState<any>({});
  const [repeat, setRepeat] = useState(false);
  const [audioLength, setAudioLength] = useState(0);
  const [rate, setRate] = useState(1);

  const player = useRef<any>(null);

  useEffect(() => {
    return () => {
      dispatch(setSelectedParagraph(0));
      playerHide();
    };
  }, []);

  //pause video if user selected an other paragraph
  useEffect(() => {
    if (pause) {
      setPlay(false);
      player.current.internalPlayer.pauseVideo();
    }
  }, [pause]);

  useEffect(() => {
    if (page.chapterOrParagraph === '' && page.chosenParagraphId === 0) {
      setPlay(false);
      setProgress(0);
      setChosenParagraph(0);
      setPropsAudio({} as PropsAudio);
      player.current.internalPlayer.stopVideo();
    }
  }, [page.chapterOrParagraph, page.chosenParagraphId]);

  useEffect(() => {
    let next: any = {};
    paragraph &&
      page.paragraphs.forEach(
        (el, index) =>
          el.id === paragraph.id && (next = { ...page.paragraphs[index + 1] })
      );
    const nextPageParagraphTime =
      page.paragraphs[0].chapter_id === page.nextParagraphs[0].chapter_id &&
      page.nextParagraphs[0].audio_time;
    if (isChapter) {
      setPropsAudio({
        videoId:
          paragraph.audio_file &&
          paragraph.audio_file.replace('https://youtu.be/', ''),
        startSeconds:
          Number(paragraph.audio_time) === 0 ? 1 : Number(paragraph.audio_time),
        endSeconds: chapterLength
      });
      setNextParagraph(next);
    } else if (!isChapter && paragraph) {
      setPropsAudio({
        videoId:
          paragraph.audio_file &&
          paragraph.audio_file.replace('https://youtu.be/', ''),
        startSeconds:
          Number(paragraph.audio_time) === 0 ? 1 : Number(paragraph.audio_time),
        endSeconds: next.audio_time
          ? Number(next.audio_time)
          : Number(nextPageParagraphTime)
      });
      setNextParagraph(next);
    }
  }, [paragraph]);

  useEffect(() => {
    if (page.chosenParagraphId !== 0 && page.chapterOrParagraph !== '') {
      player.current.internalPlayer.loadVideoById(propsAudio);
      if (isMobileWidth) {
        forward();
        forward();
      } else {
        setPlay(true);
      }
    }
  }, [nextParagraph]);

  useInterval(
    () => {
      let audioLength = 0;

      if (isChapter) {
        audioLength = chapterLength - propsAudio.startSeconds;
      } else if (!isChapter && propsAudio.endSeconds !== 0) {
        audioLength = propsAudio.endSeconds - propsAudio.startSeconds;
      } else if (!isChapter && propsAudio.endSeconds === 0) {
        audioLength = chapterLength - propsAudio.startSeconds;
      }

      setAudioLength(audioLength);
      //take current playing time and compute the percent value for a progress bar
      player.current.internalPlayer
        .getCurrentTime()
        .then((res: number) => {
          setCurrentTime(res - propsAudio.startSeconds);
        })
        .then(() => {
          const progPercents = Math.round(currentTime / (audioLength / 100));

          if (progPercents > 0 && progPercents < 100) {
            setProgress(progPercents);
          }
          if (repeat && progPercents >= 99) {
            player.current.internalPlayer.loadVideoById(propsAudio);
          }
        });

      //set length of a chapter
      player.current.internalPlayer.getDuration().then((res: number) => {
        setChapterLength(res);
      });

      // check which paragraph is should be selected for
      page.paragraphs.forEach((el, index) => {
        if (
          el.id === page.chosenParagraphId &&
          page.nextParagraphs[0].chapter_id === page.paragraphs[0].chapter_id
        ) {
          setChosenParagraph({ ...page.paragraphs[index + 1] });
        }
      });

      const isInRange = (min: number, max: number) => {
        if (max !== undefined && currentTime + min >= Number(max)) {
          chosenParagraph.id && chapterPlay(chosenParagraph.id);
        } else if (
          max === undefined &&
          currentTime + min >= Number(page.nextParagraphs[0].audio_time)
        ) {
          chapterPlay(page.paragraphs[page.paragraphs.length - 1].id + 1);
          if (
            !chosenParagraph.id &&
            page.nextParagraphs[0].chapter_id === page.paragraphs[0].chapter_id
          ) {
            isPageEnded(page.paragraphs[page.paragraphs.length - 1].id + 1);
          } else if (
            page.nextParagraphs[0].chapter_id === page.paragraphs[0].chapter_id
          ) {
            isPageEnded(page.paragraphs[page.paragraphs.length - 1].id + 1);
          }

          //if there is no next selected paragraph than set the current as a selected
          // and also stop when the current paragraph is ended
          if (!chosenParagraph.id) {
            chapterPlay(page.paragraphs[page.paragraphs.length - 1].id);
            if (progress >= 99) {
              setPlay(false);
              setProgress(0);
              dispatch(setSelectedParagraph(0));
            }
          }
        }
      };

      currentTime > 0 &&
        isChapter &&
        isInRange(propsAudio.startSeconds, chosenParagraph.audio_time);

      //  logic for step back for rewind
      //let valueInSeconds = Math.round((audioLength / 100) * progress);
      const currentPosition = propsAudio.startSeconds + currentTime;

      if (isChapter) {
        page.paragraphs.forEach((el: ParagraphType, index) => {
          const nextEl = page.paragraphs[index + 1]
            ? page.paragraphs[index + 1].audio_time
            : page.nextParagraphs[0].audio_time;
          const prevEl = page.prevParagraphs[page.prevParagraphs.length - 1];
          if (
            currentPosition > Number(el.audio_time) &&
            currentPosition < Number(nextEl)
          ) {
            chapterPlay(el.id);
          } else if (
            currentPosition > Number(prevEl.audio_time) &&
            currentPosition < Number(page.paragraphs[0].audio_time)
          ) {
            chapterPlay(prevEl.id);
            isPageEnded(page.paragraphs[page.paragraphs.length - 1].id - 1);
            dispatch(setPageNumber(Number(el.page) - 1));
          }
        });
      }
      // handle play/pause when paragraph is end

      if (!isChapter && progress >= 99 && !repeat) {
        setProgress(0);
        setPlay(false);
      }
    },

    play ? delay : null
  );

  const opts: any = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0
    },
    height: '0',
    frameborder: 0,
    modestbranding: 1
  };

  const playPause = () => {
    if (play) {
      player.current.internalPlayer.pauseVideo();
      setPlay(false);
    } else {
      player.current.internalPlayer.playVideo();
      setPlay(true);
    }
  };

  const rewind = () => {
    player.current.internalPlayer.getCurrentTime().then((res: number) => {
      res > propsAudio.startSeconds + 10
        ? player.current.internalPlayer.seekTo(res - 10)
        : player.current.internalPlayer.seekTo(res - currentTime);
    });
  };
  const forward = () => {
    const endTime = propsAudio.startSeconds + audioLength;
    player.current.internalPlayer.getCurrentTime().then((res: number) => {
      if (res < endTime - 10) {
        player.current.internalPlayer.seekTo(res + 10);
      } else if (res < endTime - 5) {
        player.current.internalPlayer.seekTo(res + 5);
      }
    });
  };

  const handleRepeat = () => {
    setRepeat(!repeat);
  };

  const handlePlaybackRate = () => {
    rate > 1 ? setRate(1) : setRate(1.5);
    player.current.internalPlayer.setPlaybackRate(rate > 1 ? 1 : 1.5);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <Button
          className={styles.rewindButton}
          onClick={rewind}
          disabled={!play}
        >
          <SkipPreviousIcon />
        </Button>
        <Button
          variant="contained"
          className={styles.playButton}
          onClick={playPause}
          startIcon={!play ? <Play /> : <Pause />}
          disabled={isMobileWidth ? !paragraph : !play && progress === 0}
        />
        <Button
          className={styles.skipButton}
          onClick={forward}
          disabled={!play}
        >
          <SkipNextIcon />
        </Button>
        <Button className={styles.closeButton} onClick={closeAudio}>
          <ModalCloseIcon />
        </Button>
      </div>
      <div id="waveform" className={styles.waveform}>
        <Slider disabled={true} value={progress} />
        <YouTube ref={player} opts={opts} />
      </div>
    </div>
  );
};

export default Wave;
