import React, { useEffect, useState } from 'react';
import { Input, Slider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import { setSettings } from '../../../../../redux/Reader';
import styles from '../WordSpacingSlider/WordSpacingSlider.module.scss';
import { ReactComponent as LineHight } from '../../../../../assets/images/LineHight.svg';
import SliderComponent from '../../../Slider/Slider';

const range = {
  min: '14',
  max: '100'
};
const LineHeightSlider = () => {
  const dispatch = useDispatch();
  const { line_height } = useSelector(
    (state: StateType) => state.reader.settings
  );
  const [inputValue, setInputValue] = useState(line_height);

  useEffect(() => {
    setInputValue(line_height);
  }, [line_height]);

  const handleChange = (event: any, value: any) => {
    dispatch(setSettings({ line_height: value }));
  };

  const handleInputClick = (event: any) => event.target.select();

  const handleInputChange = (event: any) => {
    const ev = Number(event.target.value);
    if (ev === 0) {
      setInputValue('');
    }

    if (ev >= 1 && ev <= Number(range.max)) {
      setInputValue(String(ev));
    }
  };

  const handleInputKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      dispatch(
        setSettings({
          line_height:
            inputValue.length && Number(inputValue) >= Number(range.min)
              ? inputValue
              : range.min
        })
      );
    }
  };

  return (
    <div>
      <div className={styles.titleBlock}>
        <LineHight className={styles.icon} />
        <div className={styles.sliderTitle}>Line Height:</div>
        <Input
          className={styles.wordSpacingInput}
          value={inputValue}
          onClick={handleInputClick}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          inputProps={{
            type: 'number',
            'aria-labelledby': 'slider',
            min: Number(range.min),
            max: Number(range.max)
          }}
          disableUnderline={true}
        />
      </div>
      <SliderComponent
        min={Number(range.min)}
        max={Number(range.max)}
        step={1}
        value={Number(line_height)}
        onChange={handleChange}
      />
    </div>
  );
};

export default LineHeightSlider;
