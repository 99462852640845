import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type AlertType = {
  open: boolean;
  type: string;
  message: string;
};

const defaultState: AlertType = {
  open: false,
  type: 'success',
  message: ''
};

const alertSlice = createSlice({
  name: 'alert',
  initialState: defaultState,
  reducers: {
    setAlert: (state, action: PayloadAction<any>) => {
      state.open = true;
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
    closeAlert: (state) => {
      state.open = false;
      state.message = '';
    },
  }
});

const alertReducer = alertSlice.reducer;

export const { setAlert,closeAlert } = alertSlice.actions;
export default alertReducer;

export const alertShowThunk = (type: string, message: string): any => async (dispatch: any) => {
  const alertData = {
    type: type,
    message: message,
  }
  dispatch(setAlert(alertData))
}

export const alertCloseThunk = () => async (dispatch: any) => {
  dispatch(closeAlert())
}