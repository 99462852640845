import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import YouTube from 'react-youtube';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { TextField } from 'formik-material-ui';
import { styled } from '@mui/material/styles';
import { bindActionCreators } from 'redux';
import ReactPlayer from 'react-player';

import Layout from '../../components/Layout/Layout';
import CustomCloseButton from '../../components/common/CustomCloseButton/CustomCloseButton';
import ButtonComponent from '../../components/common/Button/Button';
//@ts-ignore
import Vid from '../../assets/video/letters.mp4';

import { DispatchType, StateType } from '../../redux/store';
import { passwordEmailThunk, loginThunk, clearErrors } from '../../redux/Auth';
import { isMobileWidth, showTitle } from '../../helpers/constants';
import { setBurgerTrigger } from '../../redux/Home';

import styles from './Welcome.module.scss';

const CustomTextField = styled(TextField)({
  //styles for TextField(inputs)
  '& .MuiFormHelperText-root.Mui-error': {
    //yup error text style
    color: '#FF8B8B',
    fontSize: 12,
    fontFamily: 'OpenSansBold',
    display: 'none' //REMOVED DEF ERRORS
  },
  '& label.Mui-focused': {
    color: '#24D399',
    borderWidth: 0,
    backgroundColor: 'red'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    '& fieldset': {
      //default styles
      borderColor: '#c5c5c5',
      borderWidth: 0,
      borderRadius: 12
    },
    '&:hover fieldset': {
      //hover
      borderColor: '#666666',
      borderWidth: 0
    },
    '&.Mui-focused fieldset': {
      //focused input
      borderColor: '#c5c5c5',
      borderWidth: 0
    },
    '&.Mui-error fieldset': {
      //error input
      borderWidth: 0,
      borderColor: '#FF8B8B'
    }
  }
});

const videoOpts: any = {
  height: '220',
  width: '325',
  playerVars: {
    autoplay: 0,
    controls: 0,
    rel: 0,
    showinfo: 0
  },
  frameborder: 0,
  modestbranding: 1
};

const Welcome = (props: any) => {
  const history = useHistory();
  const profile = useSelector((state: StateType) => state.auth);
  const dispatch: any = useDispatch();

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [isForgotPassPressed, setIsForgotPassPressed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  showTitle('Leo Reader');

  const initialValuesLogin = {
    email: '',
    password: ''
  };

  const initialValuesReset = {
    email: ''
  };

  const initialValuesSignUp = {
    fullName: ''
  };

  const isBackToHomeLogin = localStorage.getItem('isBackToHomeLogin');
  const isBackToHomeSignUp = localStorage.getItem('isBackToHomeSignUp');

  useEffect(() => {
    if (isBackToHomeLogin && isBackToHomeLogin?.length > 0) {
      setShowLoginModal(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (isBackToHomeSignUp && isBackToHomeSignUp?.length > 0) {
      setShowSignUpModal(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isBackToHomeLogin, isBackToHomeSignUp]);

  const LogInSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    password: Yup.string()
      .min(8, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  });

  const PasswordEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required')
  });

  const SignUpSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  });

  if (profile.userSession && profile.userSession.user) {
    return <Redirect to={'/'} />;
  }

  function onSubmit(values: any, { setSubmitting }: any) {
    Promise.resolve('').then(
      props.loginThunk(values.email, values.password).then(function() {
        setSubmitting(false);
        localStorage.removeItem('isBackToHomeLogin');
      })
    );
  }

  function onSubmitForgot(values: any, { setSubmitting }: any) {
    Promise.resolve('')
      .then(() => dispatch(passwordEmailThunk(values.email)))
      .then(() => setSubmitting(false));
  }

  function onSubmitSignUp(values: any, { setSubmitting }: any) {
    const fullNameSplitted = values.fullName.split(' ');

    localStorage.setItem('firstName', fullNameSplitted[0]);
    localStorage.setItem('lastName', fullNameSplitted[1]);

    localStorage.removeItem('isBackToHomeSignUp');

    history.push('/register');
  }

  const returnStylesWrapper = (
    error: any,
    touched: any,
    values: any,
    creds?: any
  ) => {
    if (error || creds) {
      return styles.inputFieldWrapperError;
    } else if (values && touched && !error) {
      return styles.inputFieldWrapperSuccess;
    } else {
      return styles.inputFieldWrapper;
    }
  };

  const onBottomSignUpPress = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    isMobileWidth ? props.setBurgerTrigger(true) : setShowSignUpModal(true);
  };

  const renderLogInModal = () => {
    if (isForgotPassPressed) {
      return (
        <div className={styles.modalContainer}>
          <div className={styles.loginContainer}>
            <CustomCloseButton
              top={10}
              right={12}
              onClick={() => {
                dispatch(clearErrors());
                setShowLoginModal(false);
                setIsForgotPassPressed(false);
                setShowPassword(false);
              }}
            />
            <p className={styles.modalText}>
              Looks like you forgot
              <br />
              <span className={styles.boldText}>your password?</span>
            </p>
            <div>
              <Formik
                initialValues={initialValuesReset}
                validationSchema={PasswordEmailSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onSubmitForgot}
              >
                {({ errors, touched, isSubmitting = false, values }) => (
                  <Form>
                    <p className={styles.inputText}>Email address</p>
                    <div
                      className={returnStylesWrapper(
                        errors.email,
                        touched.email,
                        values.email,
                        props.state.validation.errors.credentials
                      )}
                    >
                      <Field
                        component={CustomTextField}
                        classes={{
                          root: styles.textField
                        }}
                        id="email"
                        name="email"
                        placeholder="Email address"
                        type="email"
                        variant="outlined"
                        error={
                          !!(errors.email || profile.validation.errors.email)
                        }
                        // helperText={
                        //   !errors.email ? auth.validation.errors.email : errors.email
                        // }
                      />
                    </div>
                    {!!(errors.email || profile.validation.errors.email) && (
                      <p className={styles.inputTextError}>{errors.email}</p>
                    )}
                    {console.log('errorsDESKTOP', errors)}

                    <div className={styles.submitButtonContainer}>
                      <Button
                        className={styles.submitButton}
                        variant="contained"
                        type="submit"
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress className={styles.spinnerSize} />
                          ) : (
                            ''
                          )
                        }
                        disabled={isSubmitting}
                      >
                        Send me a link
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.modalContainer}>
          <div className={styles.loginContainer}>
            <CustomCloseButton
              top={10}
              right={12}
              onClick={() => {
                localStorage.removeItem('isBackToHomeLogin');
                dispatch(clearErrors());
                setShowLoginModal(false);
                setShowPassword(false);
              }}
            />
            <p className={styles.modalText}>
              Welcome back,
              <br />
              <span className={styles.boldText}>Let’s get reading!</span>
            </p>
            <div>
              <Formik
                initialValues={initialValuesLogin}
                validationSchema={LogInSchema}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, touched, isSubmitting = false, values }) => (
                  <Form>
                    <p className={styles.inputText}>Email address</p>
                    <div
                      className={returnStylesWrapper(
                        errors.email,
                        touched.email,
                        values.email,
                        props.state.validation.errors.credentials
                      )}
                    >
                      <Field
                        component={CustomTextField}
                        classes={{
                          root: styles.textField
                        }}
                        id="email"
                        name="email"
                        placeholder="Email address"
                        type="email"
                        variant="outlined"
                        // error={
                        //   !!(errors.email || profile.validation.errors.email)
                        // }
                        // helperText={
                        //   !errors.email
                        //     ? profile.validation.errors.email
                        //     : errors.email
                        // }
                      />
                    </div>
                    {!!(errors.email || profile.validation.errors.email) && (
                      <p className={styles.inputTextError}>
                        {!errors.email
                          ? profile.validation.errors.email
                          : errors.email}
                      </p>
                    )}
                    <p className={styles.inputText}>Password</p>
                    <div
                      className={returnStylesWrapper(
                        errors.password,
                        touched.password,
                        values.password,
                        props.state.validation.errors.credentials
                      )}
                    >
                      <Field
                        component={CustomTextField}
                        classes={{
                          root: styles.textField
                        }}
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        variant="outlined"
                        autoComplete="new-password"
                        // error={
                        //   !!(
                        //     errors.password ||
                        //     profile.validation.errors.password
                        //   )
                        // }
                        // helperText={
                        //   !errors.password
                        //     ? profile.validation.errors.password
                        //     : errors.password
                        // }
                        InputProps={{
                          border: 'none',
                          endAdornment: (
                            <IconButton className={styles.passwordButton}>
                              <img
                                onClick={() => setShowPassword(!showPassword)}
                                src={require(showPassword
                                  ? '../../assets/images/password-icon-visible.svg'
                                  : '../../assets/images/password-icon-not-visible.svg')}
                                alt="password"
                                className={styles.passwordIcon}
                              />
                            </IconButton>
                          )
                        }}
                      />
                    </div>
                    {!!(
                      errors.password || profile.validation.errors.password
                    ) && (
                      <p className={styles.inputTextError}>
                        {!errors.password
                          ? profile.validation.errors.password
                          : errors.password}
                      </p>
                    )}
                    {props.state.validation.errors.credentials ? (
                      <p className={styles.inputTextError}>
                        {props.state.validation.errors.credentials}
                      </p>
                    ) : null}
                    <div className={styles.passwordInfoContainer}>
                      <IconButton
                        className={styles.forgotPasswordButton}
                        onClick={() => setIsForgotPassPressed(true)}
                      >
                        Forgot your password?
                      </IconButton>
                    </div>
                    <div className={styles.submitButtonContainer}>
                      <Button
                        className={styles.submitButton}
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Log in
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderSignUpModal = () => {
    return (
      <div className={styles.modalSignUpContainer}>
        <div className={styles.signUpContainer}>
          <CustomCloseButton
            top={10}
            right={10}
            onClick={() => {
              setShowSignUpModal(false);
              localStorage.removeItem('isBackToHomeSignUp');
            }}
          />
          <p className={styles.modalText}>
            Hey,{' '}
            <span className={styles.boldText}>
              what is
              <br /> your name?
            </span>
          </p>
          <div>
            <Formik
              initialValues={initialValuesSignUp}
              validationSchema={SignUpSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={onSubmitSignUp}
            >
              {({ errors, touched, isSubmitting = false, values }) => (
                <Form>
                  <p className={styles.inputText}>Full name</p>
                  <div
                    className={returnStylesWrapper(
                      errors.fullName,
                      touched.fullName,
                      values.fullName
                    )}
                  >
                    <Field
                      component={CustomTextField}
                      classes={{
                        root: styles.textFieldSignUp
                      }}
                      id="fullName"
                      name="fullName"
                      placeholder="Your full name"
                      type="text"
                      variant="outlined"
                    />
                  </div>
                  {!!(
                    errors.fullName || profile.validation.errors.fullName
                  ) && (
                    <p className={styles.inputTextError}>{errors.fullName}</p>
                  )}

                  <div className={styles.submitButtonContainer}>
                    <Button
                      className={styles.submitButton}
                      variant="contained"
                      type="submit"
                      // disabled={isSubmitting}
                    >
                      Sign Up!
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div>
            <p className={styles.haveAccountText}>Already have an account?</p>
            <div>
              <ButtonComponent
                variant="ghost"
                onClick={() => {
                  setShowSignUpModal(false);
                  setShowLoginModal(true);
                }}
              >
                <p>Login</p>
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout
      signUpButtonPressedMain={() => setShowSignUpModal(true)}
      loginButtonPressedMain={() => setShowLoginModal(true)}
    >
      {showLoginModal && renderLogInModal()}
      {showSignUpModal && renderSignUpModal()}
      <main className={styles.main}>
        <div className={styles.top_container}>
          <div className={styles.left_top_block}>
            <div>Watching</div>
            <div>Listening</div>
            <div>Reading</div>
          </div>
          <div className={styles.right_top_block}>
            <div className={styles.logo_text}>
              <p>
                <span
                  className={`${styles.bold} ${styles.focusComponentWrapper}`}
                >
                  Where
                  <div className={styles.focusComponent}>
                    <div className={styles.focusMain}>
                      <div className={styles.focusArrow} />
                      <div className={styles.focusBody}>Focus</div>
                    </div>
                  </div>
                </span>
              </p>
              <p>
                <span className={styles.bold}>the</span>{' '}
                <span className={styles.regular}>books</span>
              </p>
              <p>
                <span className={styles.light}>adapt</span>
              </p>
              <p>
                <span className={styles.bold}>to you</span>
              </p>
            </div>
            <div className={styles.sub_logo_text}>
              We are a neurodivergent friendly eReader designed for education in
              the creative industries
            </div>
            <div className={styles.eye_logo}>
              <img src={require('../../assets/images/Gif_Eye.gif')} />
            </div>
          </div>
        </div>
        <div className={styles.bottom_container}>
          <ReactPlayer
            url={Vid}
            playing={true}
            controls={false}
            loop={true}
            muted={true}
            playsinline={true}
            className={styles.video}
          />
          <div className={styles.bottom_wrapper}>
            <div className={styles.bottom_body}>
              <div className={styles.bottom_content}>
                <div className={styles.bottom_content_left}>
                  <h2>
                    Welcome to <span>Leo</span>
                  </h2>
                  <p>
                    The e-reader designed for neurodivergent readers in the
                    creative industries.
                  </p>
                  <div>
                    <img src={require('../../assets/images/books.png')} />
                    <p>Read, watch or listen</p>
                  </div>
                  <div>
                    <img src={require('../../assets/images/book.png')} />
                    <p>For free</p>
                  </div>
                </div>
                <div className={styles.bottom_content_right}>
                  {/* <img src={require('../../assets/images/VideoTools.png')} /> */}
                  <YouTube
                    videoId={'gIMTIIDIzUo'}
                    opts={videoOpts}
                    className={styles.videoPlayer}
                  />
                </div>
              </div>
              <div className={styles.bottom_sign_up_button}>
                <ButtonComponent
                  variant="secondary"
                  onClick={onBottomSignUpPress}
                >
                  <p>
                    <span className={styles.boldText}>Sign Up</span>it's free
                  </p>
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state.auth
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => {
  return bindActionCreators({ loginThunk, setBurgerTrigger }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
