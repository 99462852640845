import React, { useEffect, useState } from 'react';
import styles from './RulerTools.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import { resetRulerSettings, setSettings } from '../../../../../redux/Reader';
import { ColorResult, SketchPicker } from 'react-color';
import { Backdrop, Button, Input, Modal } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import ButtonComponent from '../../../Button/Button';
import { ReactComponent as ModalCloseIcon } from '../../../../../assets/images/modalCloseIcon.svg';
import SwitchComponent from '../../../Switch/Switch';
import SliderComponent from '../../../Slider/Slider';

const range = {
  min: '1',
  max: '100'
};

export default function RulerTools() {
  const dispatch = useDispatch();
  const {
    ruler_top,
    ruler_top_color,
    ruler_top_opacity,
    ruler_bottom,
    ruler_bottom_color,
    ruler_bottom_opacity,
    line_height
  } = useSelector((state: StateType) => state.reader.settings);
  const [openTopPicker, setOpenTopPicker] = useState(false);
  const [openBottomPicker, setOpenBottomPicker] = useState(false);
  const [inputTopOpacityValue, setInputTopOpacityValue] = useState(
    Number(ruler_top_opacity) * 100
  );
  const [inputBottomOpacityValue, setInputBottomOpacityValue] = useState(
    Number(ruler_bottom_opacity) * 100
  );
  const [resetOpen, setResetOpen] = React.useState(false);

  useEffect(() => {
    setInputTopOpacityValue(Math.round(Number(ruler_top_opacity) * 100));
  }, [ruler_top_opacity]);

  useEffect(() => {
    setInputBottomOpacityValue(Math.round(Number(ruler_bottom_opacity) * 100));
  }, [ruler_bottom_opacity]);

  const handleInputClick = (event: any) => event.target.select();

  const handleInputChange = (event: any, setInputValue: Function) => {
    const ev = Number(event.target.value);
    if (ev === 0) {
      setInputValue('');
    }
    if (ev >= Number(range.min) && ev <= Number(range.max)) {
      setInputValue(String(ev));
    }
  };

  const handleInputKeyDown = (event: any, settingName: any) => {
    if (event.keyCode === 13) {
      dispatch(
        setSettings({
          [settingName]:
            (event.currentTarget.value.length
              ? event.currentTarget.value
              : range.min) / 100
        })
      );
    }
  };

  const handleTopPickerOpen = () => {
    setOpenTopPicker(true);
  };

  const handleBottomPickerOpen = () => {
    setOpenBottomPicker(true);
  };

  const handleTopPickerClose = () => {
    setOpenTopPicker(false);
  };

  const handleBottomPickerClose = () => {
    setOpenBottomPicker(false);
  };

  const handleChange = (name: string, value: any) => {
    dispatch(setSettings({ [name]: value }));
  };

  const handleResetClose = () => {
    setResetOpen(false);
  };

  const handleResetOpen = () => {
    setResetOpen(true);
  };

  const handleResetRuler = () => {
    dispatch(resetRulerSettings());
    setResetOpen(false);
  };

  const ResetModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={resetOpen}
        onClose={handleResetClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={resetOpen}>
          <div className={styles.paper}>
            <div className={styles.closeIcon} onClick={handleResetClose}>
              <ModalCloseIcon />
            </div>
            <div className={styles.titleWrapper}>
              <h3 id="transition-modal-title">You are about to</h3>
              <h3 id="transition-modal-subtitle">reset your ruler settings</h3>
            </div>
            <div className={styles.actions} id="transition-modal-description">
              <div className={styles.buttonWrapperMargin}>
                <ButtonComponent onClick={handleResetRuler} variant="ghost">
                  <p>Reset All</p>
                </ButtonComponent>
              </div>
              <div className={styles.buttonWrapper}>
                <ButtonComponent onClick={handleResetClose} variant="secondary">
                  <p>Cancel</p>
                </ButtonComponent>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.RulerToolBlock}>
        <div className={styles.title}>Ruler top:</div>
        <div className={styles.control}>
          <SwitchComponent
            checked={Boolean(ruler_top)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (!event.target.checked) {
                handleChange('ruler_top', ruler_top === 1 ? 0 : 1);
              } else {
                handleChange('ruler_top', Number(event.target.checked));
              }
            }}
            color="primary"
            name="rulerTop"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>
      {ruler_top ? (
        <div>
          <div className={styles.RulerToolBlock}>
            <div className={styles.title}>Top colour:</div>
            <div className={styles.control}>
              <div
                className={`${styles.colorPreview} ${styles.colorRulerTopPreview}`}
                onClick={handleTopPickerOpen}
              ></div>
              {openTopPicker && (
                <div
                  className={styles.colorPicker}
                  onMouseLeave={handleTopPickerClose}
                >
                  <SketchPicker
                    color={ruler_top_color}
                    onChangeComplete={(color: ColorResult) =>
                      handleChange('ruler_top_color', color.hex)
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.sliderBlock}>
            <div className={styles.sliderTitle}>
              <div>Top opacity:</div>
              <Input
                className={styles.rulerOpacityInput}
                value={inputTopOpacityValue}
                onClick={handleInputClick}
                onChange={(event: any) =>
                  handleInputChange(event, setInputTopOpacityValue)
                }
                onKeyDown={(event: any) =>
                  handleInputKeyDown(event, 'ruler_top_opacity')
                }
                inputProps={{
                  type: 'number',
                  'aria-labelledby': 'slider',
                  min: Number(range.min),
                  max: Number(range.max)
                }}
                disableUnderline={true}
              />
            </div>
            <div className={styles.control}>
              <SliderComponent
                aria-label=""
                min={Number(range.min)}
                max={Number(range.max)}
                step={1}
                value={Number(ruler_top_opacity) * 100}
                onChange={(event: any, value: any) =>
                  handleChange('ruler_top_opacity', value / 100)
                }
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <hr className={styles.topDivider} />
      <div className={styles.RulerToolBlock}>
        <div className={styles.title}>Ruler bottom:</div>
        <div className={styles.control}>
          <div className={styles.control}>
            <SwitchComponent
              checked={Boolean(ruler_bottom)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (!event.target.checked) {
                  handleChange('ruler_bottom', ruler_bottom === 1 ? 0 : 1);
                } else {
                  handleChange('ruler_bottom', Number(event.target.checked));
                }
              }}
              color="primary"
              name="rulerBottom"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </div>
      </div>
      {ruler_bottom ? (
        <div>
          <div className={styles.RulerToolBlock}>
            <div className={styles.title}>Bottom colour:</div>
            <div className={styles.control}>
              <div
                className={`${styles.colorPreview} ${styles.colorRulerBottomPreview}`}
                onClick={handleBottomPickerOpen}
              ></div>
              {openBottomPicker && (
                <div
                  className={styles.colorPicker}
                  onMouseLeave={handleBottomPickerClose}
                >
                  <SketchPicker
                    color={ruler_bottom_color}
                    onChangeComplete={(color: ColorResult) =>
                      handleChange('ruler_bottom_color', color.hex)
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.sliderBlock}>
            <div className={styles.sliderTitle}>
              <div>Bottom opacity:</div>
              <Input
                className={styles.rulerOpacityInput}
                value={inputBottomOpacityValue}
                onClick={handleInputClick}
                onChange={(event: any) =>
                  handleInputChange(event, setInputBottomOpacityValue)
                }
                onKeyDown={(event: any) =>
                  handleInputKeyDown(event, 'ruler_bottom_opacity')
                }
                inputProps={{
                  type: 'number',
                  'aria-labelledby': 'slider',
                  min: Number(range.min),
                  max: Number(range.max)
                }}
                disableUnderline={true}
              />
            </div>
            <div className={styles.control}>
              <SliderComponent
                aria-label=""
                min={Number(range.min)}
                max={Number(range.max)}
                step={1}
                value={Number(ruler_bottom_opacity) * 100}
                onChange={(event: any, value: any) =>
                  handleChange('ruler_bottom_opacity', value / 100)
                }
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <hr className={styles.bottomDivider} />
      <div className={styles.undoResetButtons}>
        <Button onClick={handleResetOpen} className={styles.resetButton}>
          Reset
        </Button>
      </div>
      <ResetModal />
    </div>
  );
}
