import React from 'react';

import { styled } from '@mui/material/styles';
import { IconButton } from '@material-ui/core';

import styles from './CustomCloseButton.module.scss';

type PropsT = {
  onClick?: () => void;
  top: number;
  right: number;
};

const CustomCloseButton = ({ onClick, top, right }: PropsT) => {
  const CustomizedButton = styled(IconButton)(() => ({
    position: 'absolute',
    right: right,
    height: 35,
    width: 35,
    top: top,
    zIndex: 999,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  }));
  return (
    <CustomizedButton onClick={onClick}>
      <img
        src={require('../../../assets/images/close_modal_x.png')}
        alt="close"
        className={styles.closeIcon}
      />
    </CustomizedButton>
  );
};

export default CustomCloseButton;
