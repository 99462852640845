import {
  instance,
} from './api';

type BookResponseType = {

};

export const booksAPI = {
  getBooksList() {
    return instance.get('books');
  },
  getBookDetails(bookId:number) {
    return instance.get(`books/${bookId}`);
  },
  saveReadingProgress(bookId:number,chapterId:number,page:number) {
    return instance.post(`reading-progress`, {'book_id':bookId, 'chapter_id':chapterId,'page':page});
  },
};
