import React, { useEffect } from 'react';
import styles from './ErrorPage.module.scss';
import { useDispatch } from 'react-redux';
import { StateType } from '../../redux/store';
import { logoutThunk } from '../../redux/Auth';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { showTitle } from '../../helpers/constants';

export const ErrorPage: React.FC<StateType> = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  showTitle('404');

  useEffect(() => {
    dispatch(logoutThunk);
  }, [dispatch]);

  const handleBackClick = () => {
    history.push('/login');
  };

  const handleBackClickSignUp = () => {
    localStorage.setItem('isBackToHomeSignUp', 'true');
    history.push('/login');
  };

  const handleBackClickLogin = () => {
    localStorage.setItem('isBackToHomeLogin', 'true');
    history.push('/login');
  };

  return (
    <Layout
      isSingle
      signUpButtonPressedMain={() => handleBackClickSignUp()}
      loginButtonPressedMain={() => handleBackClickLogin()}
    >
      <div className={styles.home}>
        <main>
          <div className={styles.errorBlock}>
            <span className={styles.bigLetter}>4</span>
            <img
              className={styles.image}
              src={require('../../assets/images/error_eye.svg')}
              alt="eye"
            />
            <span className={styles.bigLetter}>4</span>
          </div>
          <p className={styles.errorText}>Sorry, and error has occurred.</p>
          <div className={styles.buttonWrapper}>
            <div className={styles.button} onClick={handleBackClick}>
              <p>Take me back</p>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default ErrorPage;
