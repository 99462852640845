import { DispatchType } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { bookContentAPI } from '../api/book-content-api';
import { saveReadingProgressThunk } from './Home';

export type ParagraphType = {
  id: number;
  chapter_id: number;
  page: number;
  paragraph: string;
  audio_file: string;
  audio_time: string;
  video_file: string;
  video_time: string;
  image_url: string;
  is_chapter_title: boolean;
};

type PageType = {};

export type PageStateType = {
  bookId: number;
  pageNumber: number;
  paragraphs: Array<ParagraphType>;
  nextParagraphs: Array<ParagraphType>;
  prevParagraphs: Array<ParagraphType>;
  chosenParagraphId: number;
  isPlaying: boolean;
  chapterOrParagraph: string;
};

const defaultState: PageStateType = {
  bookId: 0,
  pageNumber: 0,
  paragraphs: [] as Array<ParagraphType>,
  nextParagraphs: [] as Array<ParagraphType>,
  prevParagraphs: [] as Array<ParagraphType>,
  chosenParagraphId: 0,
  isPlaying: false,
  chapterOrParagraph: '',
};

const pageSlice = createSlice({
  name: 'page',
  initialState: defaultState,
  reducers: {
    setBookId: (state, action: PayloadAction<number>) => {
      state.bookId = action.payload;
    },
    setPageNumber: (state, action: PayloadAction<number | Array<number>>) => {
      state.pageNumber = Number(action.payload);
    },
    setPage: (state, action: PayloadAction<Array<ParagraphType>>) => {
      state.paragraphs = action.payload;
    },
    setNextPage: (state, action: PayloadAction<Array<ParagraphType>>) => {
      state.nextParagraphs = action.payload;
    },
    setPrevPage: (state, action: PayloadAction<Array<ParagraphType>>) => {
      state.prevParagraphs = action.payload;
    },
    setSelectedParagraph: (state, action: PayloadAction<number>) => {
      state.chosenParagraphId = action.payload;
    },
    setPlaying: (state) => {
      state.isPlaying = !state.isPlaying;
    },
    setChapterOrParagraph: (state, action: PayloadAction<string>) => {
      state.chapterOrParagraph = action.payload;
    },
    resetPage: (state) => {
      state.paragraphs = defaultState.paragraphs;
    },
  },
});

const pageReducer = pageSlice.reducer;

export const {
  setPage,
  setBookId,
  setPageNumber,
  setSelectedParagraph,
  setPlaying,
  setNextPage,
  setPrevPage,
  setChapterOrParagraph,
  resetPage,
} = pageSlice.actions;

export default pageReducer;

export const paragraphsListThunk =
  (bookId: number, page: number) => async (dispatch: DispatchType) => {
    try {
      const paragraphsList = await bookContentAPI.getList(bookId, page);
      const nextParagraphsList = await bookContentAPI.getList(
        bookId,
        Number(page) + 1
      );
      const prevParagraphsList = await bookContentAPI.getList(
        bookId,
        Number(page) - 1
      );
      dispatch(setPage(paragraphsList.data));
      dispatch(setNextPage(nextParagraphsList.data));
      dispatch(setPrevPage(prevParagraphsList.data));
      if (paragraphsList.data.length) {
        dispatch(
          //@ts-ignore
          saveReadingProgressThunk(
            bookId,
            paragraphsList.data[0].chapter_id,
            page
          )
        );
      }
    } catch (err) {}
  };
