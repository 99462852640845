import React, {useEffect, useRef, useState} from 'react';
import styles from './Cookie.module.scss';
import { NavLink } from 'react-router-dom';
import Popover from "@material-ui/core/Popover";
import {Button} from "@material-ui/core";
import { ReactComponent as CookieIcon } from '../../../assets/images/cookie.svg';
import {useCookies} from "react-cookie";

const Cookie = (props:any) => {
  const [cookies, setCookie, removeCookie] = useCookies(['cookiesAccepted']);
  const [cookiePopup, setCookiePopup] = useState(false);

  useEffect(() => {
    if(!cookies.cookiesAccepted){
      setCookiePopup(true);
    }
  },[]);

  const handleCookieAccept = () => {
    setCookie('cookiesAccepted', 1, { path: '/' });
    setCookiePopup(false)
  }

  return (
    <Popover
      open={cookiePopup}
      anchorEl={props.anchorEl}
      classes={{
        paper: styles.cookiePopover,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <div className={styles.cookieIcon}>
        <CookieIcon />
      </div>
      <div className={styles.cookieText}>
        We use third-party cookies to personalize content, ads, and analyze site traffic.<br />
        <NavLink to={'/privacy'}>Learn more</NavLink>
      </div>
      <div className={styles.cookieAccept}>
        <Button variant="contained" color="primary" onClick={handleCookieAccept}>
          Okay
        </Button>
      </div>
    </Popover>
  );
};

export default Cookie;
