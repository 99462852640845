import React, { useState } from 'react';
import AudioPlayer from '../common/AudioPlayer/AudioPlayer';
import { Button } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styles from './FooterAudio.module.scss';

const FooterAudio: React.FC<any> = ({
  paragraph,
  closeAudio,
  chapterPlay,
  isChapter,
  isPageEnded,
  playerHide,
  pause,
  start
}: any) => {
  const [show, setShow] = useState(true);

  return (
    <footer>
      <div className={`${styles.footer}`}>
        <div className={styles.sliderAudio}>
          <AudioPlayer
            paragraph={paragraph}
            closeAudio={closeAudio}
            chapterPlay={chapterPlay}
            isChapter={isChapter}
            isPageEnded={isPageEnded}
            playerHide={playerHide}
            pause={pause}
            start={start}
          />
        </div>
      </div>
    </footer>
  );
};

export default FooterAudio;
