import React, { ReactNode, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';

import { StateType } from '../../redux/store';
import { UserType, logoutThunk } from '../../redux/Auth';

import Search from '../common/Search/Search';

import styles from './BurgerMenu.module.scss';

type PropsType = {
  user: UserType;
  handleClose?: () => void;
};

const BurgerMenu: React.FC<PropsType> = ({ user, handleClose }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: StateType) => state.home);

  const logout = () => {
    dispatch(logoutThunk());
  };

  return (
    <div className={styles.burgerMenu}>
      <p className={styles.menuTitle}>
        Hey, <span>{user?.firstname}!</span>
      </p>
      <div className={styles.searchWrapper}>
        <Search handleClose={handleClose} />
      </div>
      <div className={styles.linksWrapper}>
        <Link to={'/help'}>Faq</Link>
        <Link to={'/terms'}>Terms of use</Link>
        <Link to={'/privacy'}>Privacy Policy</Link>
      </div>
      <Button
        variant="outlined"
        className={styles.logoutButton}
        onClick={logout}
      >
        Logout
      </Button>
      {isLoading && <CircularProgress className={styles.loader} />}
    </div>
  );
};

export default BurgerMenu;
