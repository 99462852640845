import React, { useEffect } from 'react';
import styles from './ToolPopup.module.scss';

import { ReactComponent as Fonts } from '../../../../assets/images/fonts.svg';
import { ReactComponent as Spacing } from '../../../../assets/images/spacing.svg';
import { ReactComponent as Background } from '../../../../assets/images/background.svg';
import { ReactComponent as Ruler } from '../../../../assets/images/RulerIcon.svg';

import {
  Popover,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Box
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../redux/store';
import FontsList from '../../FontsList/FontsList';
import ColorPicker from '../../ColorPicker/ColorPicker';
import ToolPopupSlider from '../../ToolPopupSlider/ToolPopupSlider';
import { setSubMode } from '../../../../redux/Toolbar';
import CancelIcon from '@material-ui/icons/Cancel';
import FontWeightPicker from '../../FontWeightPicker/FontWeightPicker';
import { handlerCSS } from '../../../../helpers/cssHelper';
import UndoReset from './UndoReset/UndoReset';
import WordSpacingSlider from './WordSpacingSlider/WordSpacingSlider';
import ParagraphSpacingSlider from './ParagraphSpacingSlider/ParagraphSpacingSlider';
import LineHeightSlider from './LineHeightSlider/LineHeightSlider';
import BackgroundPicker from './BackgroundPicker/BackgroundPicker';
import WallpaperPicker from './WallpaperPicker/WallpaperPicker';
import RulerTools from './Ruler/RulerTools';

type Props = {
  open: boolean;
  anchorEl: any;
  handlePopoverClose?: any;
};

const icons: any = {
  fonts: <Fonts />,
  spacing: <Spacing />,
  background: <Background />,
  ruler: <Ruler />
};

const arr = [
  { id: 0, name: 'fonts', title: 'Fonts' },
  { id: 1, name: 'spacing', title: 'Spacing' },
  { id: 2, name: 'background', title: 'Background' }
];

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value?: string;
};
const readingHeight = () => {
  const reading = document.getElementById('reading');
  const measures = reading && reading.offsetHeight * 2 + 40;
  return measures;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.tabBox}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
};

const ToolPopup: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const { open, anchorEl, handlePopoverClose } = props;
  const { subMode, fonts } = useSelector((state: StateType) => state.toolbar);
  useEffect(() => {
    handlerCSS({ elTop: `${top + 'px'}` });
  });
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    dispatch(setSubMode(newValue));
  };

  const top: any = readingHeight();

  return (
    <Popover
      id="mouse-over-popover"
      classes={{
        root: styles.popoverWrapper,
        paper: styles.popover
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: -top,
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={open}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <div className={styles.wrapper}>
        <Tabs
          orientation="vertical"
          value={subMode}
          onChange={handleTabChange}
          aria-label="Reading options"
          className={styles.tabs}
          classes={{ indicator: styles.tabIndicator }}
        >
          {arr.map(el => (
            <Tab
              key={el.id}
              classes={{ selected: styles.tabSelected }}
              icon={icons[el.name]}
              aria-label={el.title}
              value={el.name}
              {...a11yProps(el.name)}
            />
          ))}
        </Tabs>
        <div className={styles.tabBody}>
          <TabPanel value={subMode} index={'fonts'}>
            <div className={styles.main}>
              <div className={styles.tabBodyHeader}>
                <div className={styles.title}>Text options</div>
                <IconButton
                  className={styles.closeButton}
                  onClick={() => handlePopoverClose()}
                >
                  <CancelIcon />
                </IconButton>
              </div>
              <hr />
              <div className={styles.sliderFonts}>
                <Fonts onClick={() => readingHeight()} />
                <div className={styles.sliderWrapper}>
                  <ToolPopupSlider />
                </div>
                <Fonts />
              </div>
              <hr />
              <div className={styles.fontFamily}>
                <FontsList fonts={fonts} />
              </div>
              <hr />
              <div className={styles.colors}>
                <div className={styles.colorsTitle}>Colour:</div>
                <ColorPicker />
              </div>
              <hr />
              <div className={styles.fontWeight}>
                <FontWeightPicker />
              </div>
              <hr />
              <UndoReset />
            </div>
          </TabPanel>
          <TabPanel value={subMode} index={'spacing'}>
            <div className={styles.main}>
              <div className={styles.tabBodyHeader}>
                <div className={styles.title}>Spacing options</div>
                <IconButton
                  className={styles.closeButton}
                  onClick={() => handlePopoverClose()}
                >
                  <CancelIcon />
                </IconButton>
              </div>
              <hr className={styles.spacingOptionsTopSeparator} />
              <WordSpacingSlider />
              <LineHeightSlider />
              <ParagraphSpacingSlider />
              <hr className={styles.spacingOptionsBottomSeparator} />
              <UndoReset />
            </div>
          </TabPanel>
          <TabPanel value={subMode} index={'background'}>
            <div className={styles.main}>
              <div className={styles.tabBodyHeader}>
                <div className={styles.title}>Background</div>
                <IconButton
                  className={styles.closeButton}
                  onClick={() => handlePopoverClose()}
                >
                  <CancelIcon />
                </IconButton>
              </div>
              <hr className={styles.backgroundTopSeparator} />
              <BackgroundPicker />
              <hr className={styles.backgroundMiddleSeparator} />
              <WallpaperPicker />
              <hr className={styles.backgroundBottomSeparator} />
              <UndoReset />
            </div>
          </TabPanel>
        </div>
      </div>
    </Popover>
  );
};

export default ToolPopup;
