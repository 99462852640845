import React, { useEffect, useState } from 'react';

import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg';
import { setLoading, setSearchValue } from '../../../redux/Home';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../redux/store';

import styles from './Search.module.scss';

type PropsType = {
  handleClose?: () => void;
};

const Search: React.FC<PropsType> = ({ handleClose }) => {
  const [searchText, changeSearchText] = useState<null | string>(null);
  const { searchValue } = useSelector((state: StateType) => state.home);

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchText !== null) {
      dispatch(setLoading(true));
    }
    const timer = setTimeout(() => {
      if (searchText !== null) {
        dispatch(setLoading(false));
      }
      if (searchText || searchText === '') {
        dispatch(setSearchValue(searchText));
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchText]);

  return (
    <div className={styles.main}>
      <input
        defaultValue={searchValue || ''}
        className={styles.input}
        placeholder={'Search'}
        onChange={e => changeSearchText(e.target.value)}
      />
      <div
        className={styles.search}
        onClick={() => handleClose && handleClose()}
      >
        <SearchIcon />
      </div>
    </div>
  );
};

export default Search;
