import React from 'react';
import { SketchPicker } from 'react-color';
import styles from './BackgroundPicker.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import { setSettings } from '../../../../../redux/Reader';

const BackgroundPicker = () => {
  const dispatch = useDispatch();
  const { background_color } = useSelector(
    (state: StateType) => state.reader.settings
  );
  const handleChangeComplete = (color: any) => {
    dispatch(setSettings({ background_color: color.hex }));
  };

  const preserColors = [
    '#96AFFA',
    '#DBE2F1',
    '#ADF094',
    '#D8D3D6',
    '#EDDC76',
    '#EDD1B0',
    '#B68BDA',
    '#DEA6AB'
  ];

  return (
    <div>
      <div className={styles.colorPicker}>
        <SketchPicker
          disableAlpha={true}
          className={styles.sketchPicker}
          color={background_color}
          presetColors={preserColors}
          onChangeComplete={handleChangeComplete}
        />
      </div>
    </div>
  );
};

export default BackgroundPicker;
