import React, { useState } from 'react';
import { Redirect, useParams, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { passwordResetThunk } from '../../redux/Auth';
import { Button } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../redux/store';
import styles from './ResetPassword.module.scss';
import { styled } from '@mui/material/styles';
import IconButton from '@material-ui/core/IconButton';
import * as queryString from 'query-string';

const CustomTextField = styled(TextField)({
  //styles for TextField(inputs)
  '& .MuiFormHelperText-root.Mui-error': {
    //yup error text style
    color: '#FF8B8B',
    fontSize: 12,
    fontFamily: 'OpenSansBold',
    display: 'none', //REMOVED DEF ERRORS
  },
  '& label.Mui-focused': {
    color: '#24D399',
    borderWidth: 0,
    backgroundColor: 'red',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    '& fieldset': {
      //default styles
      borderColor: '#c5c5c5',
      borderWidth: 0,
      borderRadius: 12,
    },
    '&:hover fieldset': {
      //hover
      borderColor: '#666666',
      borderWidth: 0,
    },
    '&.Mui-focused fieldset': {
      //focused input
      borderColor: '#c5c5c5',
      borderWidth: 0,
    },
    '&.Mui-error fieldset': {
      //error input
      borderWidth: 0,
      borderColor: '#FF8B8B',
    },
  },
});

const ResetPassword: React.FC<any> = React.memo(() => {
  const auth = useSelector((state: StateType) => state.auth);
  const dispatch = useDispatch();
  // @ts-ignore
  const { token } = useParams();
  const query = queryString.parse(useLocation().search);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRetype, setShowPasswordRetype] = useState(false);

  const initialValues = {
    token: token,
    email: query.email,
    password: '',
    password_confirmation: '',
  };

  const PasswordResetSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Required'),
  });

  function onSubmit(values: any, { setSubmitting }: any) {
    Promise.resolve('')
      .then(() => dispatch(passwordResetThunk(values)))
      .then(() => setSubmitting(false));
  }

  if (auth.userSession && auth.userSession.user) {
    return <Redirect to={'/'} />;
  }

  const returnStylesWrapper = (error: any, touched: any, values: any) => {
    if (error) {
      return styles.inputFieldWrapperError;
    } else if (values && touched && !error) {
      return styles.inputFieldWrapperSuccess;
    } else {
      return styles.inputFieldWrapper;
    }
  };

  return (
    <main className={styles.mainWrapper}>
      <div className={styles.container}>
        <p className={styles.mainText}>
          Reset your
          <br />
          <span className={styles.boldText}>password!</span>
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={PasswordResetSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting = true, values }) => (
            <Form>
              <Field variant="outlined" id="token" name="token" type="hidden" />
              <p className={styles.inputText}>Email address</p>
              <div
                className={returnStylesWrapper(
                  errors.email,
                  touched.email,
                  values.email
                )}
              >
                <Field
                  component={CustomTextField}
                  classes={{
                    root: styles.textField,
                  }}
                  id="email"
                  name="email"
                  placeholder="Email address"
                  type="email"
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                  // error={!!(errors.email || auth.validation.errors.email)}
                  // helperText={
                  //   !errors.email ? auth.validation.errors.email : errors.email
                  // }
                />
              </div>
              {!!(errors.email || auth.validation.errors.email) && (
                <p className={styles.inputTextError}>{errors.email}</p>
              )}

              <p className={styles.inputText}>New password</p>
              <div
                className={returnStylesWrapper(
                  errors.password,
                  touched.password,
                  values.password
                )}
              >
                <Field
                  component={CustomTextField}
                  variant="outlined"
                  classes={{
                    root: styles.textField,
                  }}
                  id="password"
                  name="password"
                  placeholder="New password"
                  type={showPassword ? 'text' : 'password'}
                  // error={!!(errors.password || auth.validation.errors.password)}
                  // helperText={
                  //   !errors.password
                  //     ? auth.validation.errors.password
                  //     : errors.password
                  // }
                  InputProps={{
                    border: 'none',
                    endAdornment: (
                      <IconButton className={styles.passwordButton}>
                        <img
                          onClick={() => setShowPassword(!showPassword)}
                          src={require(showPassword
                            ? '../../assets/images/password-icon-visible.svg'
                            : '../../assets/images/password-icon-not-visible.svg')}
                          alt="password"
                          className={styles.passwordIcon}
                        />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              {!!(errors.password || auth.validation.errors.password) && (
                <p className={styles.inputTextError}>
                  {errors.password_confirmation}
                </p>
              )}
              <p className={styles.inputText}>Retype password</p>
              <div
                className={returnStylesWrapper(
                  errors.password_confirmation,
                  touched.password_confirmation,
                  values.password_confirmation
                )}
              >
                <Field
                  component={CustomTextField}
                  variant="outlined"
                  classes={{
                    root: styles.textField,
                  }}
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder="Retype password"
                  type={showPasswordRetype ? 'text' : 'password'}
                  // error={
                  //   !!(
                  //     errors.password_confirmation ||
                  //     auth.validation.errors.password_confirmation
                  //   )
                  // }
                  // helperText={
                  //   !errors.password_confirmation
                  //     ? auth.validation.errors.password_confirmation
                  //     : errors.password_confirmation
                  // }
                  InputProps={{
                    border: 'none',
                    endAdornment: (
                      <IconButton className={styles.passwordButton}>
                        <img
                          onClick={() =>
                            setShowPasswordRetype(!showPasswordRetype)
                          }
                          src={require(showPasswordRetype
                            ? '../../assets/images/password-icon-visible.svg'
                            : '../../assets/images/password-icon-not-visible.svg')}
                          alt="password"
                          className={styles.passwordIcon}
                        />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              {!!(
                errors.password_confirmation ||
                auth.validation.errors.password_confirmation
              ) && (
                <p className={styles.inputTextError}>
                  {errors.password_confirmation}
                </p>
              )}
              <div className={styles.submitButton}>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
});

export default ResetPassword;
