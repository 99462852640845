import React, { useEffect, useRef, useState } from 'react';
import styles from './ReaderPagination.module.scss';
import { Button, Input } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setPageNumber } from '../../redux/Page';
import { styled } from '@mui/material/styles';

import { StateType } from '../../redux/store';
import { ReactComponent as LeftArrow } from '../../assets/images/LeftArrow.svg';

let timer: any = null;

const CustomInput = styled(Input)({
  '& .MuiInputBase-input': {
    textAlign: 'center',
  },
});

const ReaderPagination: React.FC = () => {
  const dispatch = useDispatch();

  const { reader, page, chapters, home } = useSelector(
    (state: StateType) => state
  );
  const [show, setShow] = useState(true);
  const [pageInputValue, setPageInputValue] = useState(page.pageNumber);
  const footerHide = show ? styles.footerShow : styles.footerHide;
  const [sliderValue, setSliderValue] = React.useState<number>(page.pageNumber);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setSliderValue(newValue as number);
    setPageInputValue(newValue as number);
  };

  const pageInput: any = useRef(null);
  const pagePrev: any = useRef(null);
  const pageNext: any = useRef(null);
  const footerRef: any = useRef();

  useEffect(() => {
    setPageInputValue(page.pageNumber);
    setSliderValue(page.pageNumber);
  }, [page.pageNumber]);

  useEffect(() => {
    if (pageInputValue !== page.pageNumber) {
      pageInput.current.firstChild.focus();
    }
  }, [pageInputValue]);

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        pageNext.current.click();
      }
      if (e.key === 'ArrowLeft') {
        pagePrev.current.click();
      }
    };

    document.addEventListener('keydown', keydownListener);
    return () => {
      document.removeEventListener('keydown', keydownListener);
    };
  }, []);

  const handleSlider = (event: any, newValue: number | number[]) => {
    dispatch(setPageNumber(newValue));
  };

  const handleClick = (event: any) => {
    event.currentTarget.focus();
    event.target.select();
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ev = Number(event.target.value);
    if (ev === 0) {
      setPageInputValue(0);
    }
    if (ev >= 1 && ev <= home.currentBook.pages_count) {
      setPageInputValue(ev);
      clearTimeout(timer);

      timer = setTimeout(() => {
        // dispatch(setPageNumber(ev));
      }, 1000);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      dispatch(setPageNumber(Number(pageInputValue) ? pageInputValue : 1));
    }
  };

  const handlePrevNext = (sign: string) => {
    let currentValue = Number(page.pageNumber);
    sign === '+' ? currentValue++ : currentValue--;
    if (currentValue >= 1 && currentValue <= home.currentBook.pages_count) {
      dispatch(setPageNumber(currentValue));
    }
  };

  return (
    <div
      className={`${styles.pagination} ${
        reader?.mode === 'sound' && styles.mobileHidePagination
      } ${reader?.mode === 'focus' && styles.focusMode}`}
    >
      <div className={styles.paginationArrowBack}>
        <Button onClick={() => handlePrevNext('-')}>
          <LeftArrow />
        </Button>
      </div>
      <div className={styles.paginationPages}>
        {/* <div className={styles.paginationCurrentPages}>{pageInputValue}</div> */}
        <CustomInput
          ref={pageInput}
          className={styles.paginationCurrentPages}
          value={pageInputValue}
          onClick={handleClick}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          // inputProps={{
          //   type: 'number',
          //   'aria-labelledby': 'slider',
          //   min: 1,
          //   max: home.currentBook.pages_count,
          // }}
          disableUnderline={true}
        />
        <div className={styles.paginationMaxPages}>
          {home.currentBook.pages_count}
        </div>
      </div>
      <div className={styles.paginationArrowNext}>
        <Button onClick={() => handlePrevNext('+')}>
          <LeftArrow />
        </Button>
      </div>
    </div>
  );
};

export default ReaderPagination;
