import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ReactComponent as Profile } from '../../assets/images/profile.svg';
import { ReactComponent as Burger } from '../../assets/images/burger.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/closeIcon.svg';
import { ReactComponent as Eye_logo } from '../../assets/images/Header_eye.svg';
import { UserType, logoutThunk } from '../../redux/Auth';

import Search from '../common/Search/Search';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import CustomCloseButton from '../common/CustomCloseButton/CustomCloseButton';
import ButtonComponent from '../common/Button/Button';
import BurgerMenuWelcome from '../BurgerMenuWelcome/BurgerMenuWelcome';

import styles from './Header.module.scss';

type Props = {
  user?: UserType;
  signIn?: boolean;
  mode?: string;
  isModalOpen?: boolean;
  handelOpenModal?: () => void;
  handelCloseModal?: () => void;
  signUpButtonPressed?: () => void;
  loginButtonPressed?: () => void;
  signUpButtonPressedMain?: () => void;
  loginButtonPressedMain?: () => void;
  isWhiteBackground?: boolean;
};

const LoginOrSignUp: React.FC<Props> = ({
  signIn,
  signUpButtonPressed,
  loginButtonPressed,
}) => {
  return signIn ? (
    <div className={styles.authButtons}>
      <div className={styles.withoutAcc}>
        <p>Already have an account?</p>
        {/* <div className={styles.buttonMarginContainer}>
          <ButtonComponent variant="secondary" onClick={signUpButtonPressed}>
            <p>
              Sign Up<span id={styles.lightText}> it's free</span>
            </p>
          </ButtonComponent>
        </div> */}
        <div className={styles.buttonContainerSingle}>
          <ButtonComponent variant="ghost" onClick={loginButtonPressed}>
            <p>Login</p>
          </ButtonComponent>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.authButtons}>
      <div className={styles.withoutAcc}>
        <p>Don't have an account?</p>
        <div className={styles.buttonMarginContainer}>
          <ButtonComponent variant="secondary" onClick={signUpButtonPressed}>
            <p>
              Sign Up<span id={styles.lightText}> it's free</span>
            </p>
          </ButtonComponent>
        </div>
        <div className={styles.buttonContainer}>
          <ButtonComponent variant="ghost" onClick={loginButtonPressed}>
            <p>Login</p>
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

const Header: React.FC<Props> = ({
  user,
  signIn = false,
  mode,
  isModalOpen,
  handelOpenModal,
  handelCloseModal,
  signUpButtonPressedMain,
  loginButtonPressedMain,
  isWhiteBackground,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showMenuModal, setShowMenuModal] = useState(false);

  const history = useHistory();
  const logout = () => {
    dispatch(logoutThunk());
  };

  const adminPanel = () => {
    history.push({
      pathname: '/admin',
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setShowMenuModal(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderModal = () => {
    if (user) {
      return <BurgerMenu user={user} handleClose={handelCloseModal} />;
    } else {
      return <BurgerMenuWelcome handleClose={handelCloseModal} />;
    }
  };

  const renderDesktopModal = () => {
    return (
      <div className={styles.modalWrapper}>
        <div className={styles.modal}>
          <CustomCloseButton
            top={12}
            right={12}
            onClick={() => setShowMenuModal(false)}
          />
          <p className={styles.menuTitle}>
            Hey,{' '}
            <span className={styles.menuTitleBold}>{user?.firstname}!</span>
          </p>

          <div className={styles.logoutButtonWrapper}>
            <ButtonComponent
              variant="ghost"
              onClick={() => {
                logout();
                setShowMenuModal(false);
              }}
            >
              <p>Logout</p>
            </ButtonComponent>
          </div>
        </div>
      </div>
    );
  };

  return (
    <header
      className={`${styles.header} ${isWhiteBackground && styles.headerWhite}`}
    >
      {showMenuModal && renderDesktopModal()}
      {mode !== 'focus' && (
        <div className={styles.shadowWrapper}>
          <div className={styles.logo}>
            <Link to={'/'}>
              <img
                src={require('../../assets/images/Header_eye.png')}
                alt="eye"
                className={styles.eye}
              />
            </Link>
            <Link to={'/'}>
              <img
                src={require('../../assets/images/logo.png')}
                alt="logo"
                className={styles.logoIcon}
              />
            </Link>
          </div>
          {user ? (
            <>
              {/* <div className={styles.filtersWrapper}>
                <Button>All</Button>
                <Button>Books</Button>
                <Button>Magazines</Button>
                <Button>Articles</Button>
              </div> */}
              <div className={styles.avatarWrapper}>
                <div className={styles.searchWrapper}>
                  <Search />
                </div>
                <div
                  aria-controls="userMenu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <div className={styles.avatar}>
                    <Profile />
                  </div>
                </div>
                {/* <Menu
                  id="userMenu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  PopoverClasses={{
                    root: styles.userMenuRoot,
                    paper: styles.userPaper,
                  }}
                >
                  {/* <MenuItem className={styles.test} onClick={() => logout()}>Logout</MenuItem> */}
                {/* </Menu> */}
              </div>
            </>
          ) : (
            <LoginOrSignUp
              signIn={signIn}
              signUpButtonPressed={signUpButtonPressedMain}
              loginButtonPressed={loginButtonPressedMain}
            />
          )}
          {handelCloseModal && handelOpenModal ? (
            <div
              className={styles.mobileBurger}
              onClick={() =>
                isModalOpen ? handelCloseModal() : handelOpenModal()
              }
            >
              {isModalOpen ? <CloseIcon /> : <Burger />}
            </div>
          ) : null}
        </div>
      )}
      {isModalOpen && renderModal()}
    </header>
  );
};

export default Header;
