import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { readingSettingsApi } from '../api/reading-settings-api';
import { DispatchType } from './store';

export type ToolbarStateType = {
  mode?: string;
  subMode?: string;
  fonts: Array<Font>;
};

export type Font = {
  id: number;
  name: string;
  face: string;
  style: string;
};

const defaultState: ToolbarStateType = {
  mode: 'reading',
  subMode: 'fonts',
  fonts: [] as Array<Font>,
};

const toolbarSlice = createSlice({
  name: 'toolbar',
  initialState: defaultState,
  reducers: {
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setSubMode: (state, action: PayloadAction<string>) => {
      state.subMode = action.payload;
    },
    setFonts: (state, action: PayloadAction<Array<Font>>) => {
      state.fonts = action.payload;
    },
  },
});

const toolbarReducer = toolbarSlice.reducer;

export const { setMode, setSubMode, setFonts } = toolbarSlice.actions;
export default toolbarReducer;

export const getFontsThunk = () => async (dispatch: DispatchType) => {
  try {
    const fonts = await readingSettingsApi.getFonts();

    dispatch(setFonts(fonts.data));
  } catch (err) {}
};
