import React, { useEffect } from 'react';
import styles from './Terms.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../redux/store';
import { logoutThunk } from '../../redux/Auth';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { showTitle } from '../../helpers/constants';

export const Terms: React.FC<StateType> = () => {
  let history = useHistory();
  showTitle('Terms of Use');
  window.scrollTo({ top: 0, behavior: 'smooth' });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutThunk);
  }, [dispatch]);

  const handleBackClickSignUp = () => {
    localStorage.setItem('isBackToHomeSignUp', 'true');
    history.push('/login');
  };

  const handleBackClickLogin = () => {
    localStorage.setItem('isBackToHomeLogin', 'true');
    history.push('/login');
  };

  return (
    <Layout
      signUpButtonPressedMain={() => handleBackClickSignUp()}
      loginButtonPressedMain={() => handleBackClickLogin()}
    >
      <main className={styles.main}>
        <div className={styles.title}>
          <p className={styles.posted}>Posted: Januari 1, 2023</p>
          <h1>Terms of Use</h1>
        </div>
        <div className={styles.terms}>
          <div className={styles.chapter}>
            <p>1. Introduction</p>

            <p>
              1.1. These terms and conditions (the Terms) set out the basis on
              which you may access the e-books and use our website
              www.leoreader.com.
            </p>

            <p>
              1.2. This site is owned and operated by Commercial Break London
              Ltd, registered address at Commerial Break 840 Harrow Road N10
              5JU, UK (we, us or our).
            </p>

            <p>
              1.3. Your use of the Site and App will be governed by these Terms
              and any other documents contained in these Terms, which will form
              a legal contract between us and you. By browsing or using the Site
              and App you are agreeing to be legally bound by the Terms.
            </p>

            <p>
              1.4. If you would like to contact us for any reason please do so
              using the following details:
            </p>

            <p>
              Address: c/o Commercial Break, 840 Harrow Road London NW10 5JU, UK
            </p>

            <p>Email: team@leoreader.com</p>
          </div>
          <div className={styles.chapter}>
            <p>2. Changes to the Terms</p>

            <p>
              2.1. We may revise the Terms from time to time in the event that
              changes in relevant laws and regulatory requirements or there are
              changes to our business practices.
            </p>

            <p>
              2.2. If you are an existing customer we will email you and give
              you reasonable advance notice of any changes to these Terms before
              they take effect. You can choose to cancel a subscription in
              accordance with Clause 7 if you are not happy with these changes.
            </p>

            <p>
              2.3. Your use of the Site will be subject to the most recent
              version of the Terms available on the Site. We recommend that you
              read through the Terms available on the Site regularly so that you
              can be sure that you are aware of any changes that may apply to
              you.
            </p>
          </div>
          <div className={styles.chapter}>
            <p>3. Access and use of the Site</p>

            <p>
              3.1. Access to the Site is permitted on a temporary basis. We may
              suspend, withdraw, discontinue or change all or any part of the
              Site without notice to you.
            </p>

            <p>
              3.2. You must not misuse the Site by knowingly introducing
              viruses, trojans, worms, logic bombs or any other material which
              is malicious or technologically harmful. You must not attempt to
              gain unauthorised access to the Site, the server on which the Site
              is stored or any server, computer or database connected to the
              Site. You must not attack the Site or App via a denial-of-service
              attack or a distributed denial-of service attack. We will report
              any such breach to the relevant law enforcement authorities and we
              will co-operate with those authorities by disclosing your identity
              to them. In the event of such a breach, your right to use the Site
              will cease immediately.
            </p>
          </div>
          <div className={styles.chapter}>
            <p>4. Account</p>

            <p>
              4.1. To access our Content made available on the Site, you will
              need an account. You will be required to provide personal
              information about yourself (including name, email address and
              password).
            </p>

            <p>
              4.2. You agree that you will provide accurate, current and
              complete information about yourself, and maintain and promptly
              update your personal information contained in your account
              profile. For more information on about how we will use your
              personal information please see our Privacy Policy.
            </p>

            <p>
              4.3. You agree to not share your account password, and to keep
              your password confidential. You must notify us immediately in the
              event of any security breach or unauthorised use of your account.
            </p>

            <p>
              4.4. We may terminate access to your account at any time if you
              have breached these Terms.
            </p>
          </div>
          <div className={styles.chapter}>
            <p>6. Intellectual property rights</p>

            <p>
              6.1. We are the owner or the licensee of all intellectual property
              rights in the Site and Content. You may not:
            </p>

            <p>
              6.1.1. download, copy, print or reproduce any of the Content or
              include it in any other work or publication in any medium;
              <br />
              6.1.2. modify or alter the Content in any way;
              <br />
              6.1.3. distribute or sell the Content to any third party; and
              <br />
              6.1.4. remove any copyright or other proprietary notices contained
              in the Content
              <br />
            </p>
          </div>
          <div className={styles.chapter}>
            <p>
              7.2. If you copy or use any part of the Site or Content in breach
              of the Terms, your right to use the Site and to access the Content
              will cease immediately and you must destroy any copies of the
              materials you have made.
            </p>
          </div>
          <div className={styles.chapter}>
            <p>8. Data protection and privacy</p>

            <p className={styles.red}>
              8.1. Any personal information that we collect from you as you
              browse and use the Site will be used in accordance with our
              privacy policy. To find out more about the data we collect from
              you and how we use it please read our privacy policy available
              here.
            </p>

            <p>
              8.2. By accepting the Terms you also consent to our use of your
              personal data in accordance with our Privacy Policy.
            </p>
          </div>
          <div className={styles.chapter}>
            <p>9. Complaints or Queries</p>

            <p>
              9.1. If you have any queries or complaints about the Site or
              Content, please contact us using the contact information as
              detailed in Clause 1.4. We will use reasonable efforts to respond
              to you promptly.
            </p>

            <p className={styles.underline}>
              9.2. Alternative dispute resolution is a process where an
              independent body considers the facts of a dispute and seeks to
              resolve it, without you having to go to court. If you are not
              happy with how we have handled any complaint, you may want to
              submit the dispute for online resolution to the European
              Commission Online Dispute Resolution platform.
            </p>
          </div>
          <div className={styles.chapter}>
            <p>10. Links to and from the Site</p>

            <p>
              10.1. You may link to any page of the Site, provided you do so in
              a way that is fair and legal and does not damage our reputation or
              take advantage of it. You are not permitted to link to any page of
              the Content. You must not establish a link in such a way as to
              suggest any form of association, approval or endorsement on our
              part where none exists, nor establish a link to the Site in any
              website that is not owned by you.
            </p>

            <p>
              10.2. We reserve the right to withdraw linking permission without
              notice.
            </p>

            <p>
              10.3. Where the Site contains links to other websites and
              resources provided by third parties, these links are provided for
              your information only. We have no control over the content of
              those websites or resources and we are not responsible or liable
              for them in any way.
            </p>
          </div>
          <div className={styles.chapter}>
            <p>11. Availability</p>

            <p>
              11.1. We do not guarantee that the Content and site will always be
              available, be uninterrupted, secure or free from bugs or viruses,
              nor that the Content, Site and App will be free from errors or
              omissions.
            </p>

            <p>
              11.2. Content is provided by our licensors who may need to replace
              or remove any Content from time to time for reasons including but
              not limited to intellectual property infringements or licensing
              requirements. We shall use reasonable endeavours to notify you
              prior to such removal. Removal of any Content specified as part of
              a rent-to-own plan will be subject to your rights to retain access
              to such Content.
            </p>

            <p>
              11.3. Neither we nor our licensors shall be liable for any delay
              or failure to perform resulting from causes outside of our
              reasonable control, including, without limitation, any failure to
              provide the Site or App to you due to unforeseen circumstances or
              cause beyond our control such as acts of god, war, terrorism,
              riots, embargoes, acts of civil or military authorities, fire,
              floods, accidents, strikes, epidemic or other natural disaster,
              shortages of transportation facilities, fuel, energy, labour or
              materials or a failure of public or private telecommunications
              networks.
            </p>

            <p>
              11.4. If an event outside of our reasonable control takes place
              that affects the performance of our obligations under these Terms
              we will contact you as soon as reasonably possible to notify you
              and our obligations under these Terms will be suspended and the
              time for performance of our obligations will be extended for the
              duration of the event outside of our reasonable control.
            </p>
          </div>
          <div className={styles.chapter}>
            <p>12. Limitation of our liability</p>

            <p>
              12.1. You acknowledge that the Content and Site have not been
              developed to meet your individual requirements. You agree not to
              use the Content, Site or App for resale purposes.
            </p>

            <p>
              12.2. We only supply the Content for domestic and private use. You
              agree not to use the Content for any commercial, business or
              re-sale purpose, and we have no liability to you for any
              unforeseeable loss or damage, such as loss of profit, loss of
              business, business interruption, or loss of business opportunity.
            </p>

            <p>
              12.3. If we fail to comply with these Terms, we are responsible
              for loss or damage you suffer that is a foreseeable result of our
              breach of the Terms or our negligence, but neither we are nor our
              licensors are responsible for any loss or damage that is not
              foreseeable. Loss or damage is foreseeable if it is an obvious
              consequence of our breach or if it was contemplated by you and us
              at the time we entered into this contract.
            </p>

            <p>
              12.4. Nothing in these Terms excludes or limits our liability for:
            </p>

            <p>
              12.4.1. death or personal injury caused by our negligence,
              <br />
              12.4.2. fraud or fraudulent misrepresentation; or
              <br />
              12.4.3. any other liability which cannot be excluded or limited by
              law.
              <br />
            </p>
          </div>
          <div className={styles.chapter}>
            <p>13. General</p>

            <p>
              13.1. The Terms do not create or infer any rights that are
              enforceable by any person who is not a party to them.
            </p>

            <p>
              13.2. We may transfer our rights and obligations under these Terms
              to another organisation, but this will not affect your rights or
              our obligations under these Terms. You may only transfer your
              rights or obligations under these Terms to another person if we
              agree in writing.
            </p>

            <p>
              13.3. If we decide not to exercise or enforce any right that we
              have against you at a particular time, then this does not prevent
              us from later deciding to exercise or enforce that right.
            </p>

            <p>
              13.4. Each of the conditions of these Terms operates separately.
              If any court or competent authority decides that any of them are
              unlawful or unenforceable, the remaining conditions will remain in
              full force and effect.
            </p>

            <p>
              13.5. These Terms and any non-contractual obligations arising out
              of them shall be governed by and construed in accordance with
              English law and the non-exclusive jurisdiction of the English
              Courts.
            </p>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Terms;
