import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { readingSettingsApi } from '../api/reading-settings-api';
import { Font } from './Toolbar';
import { DispatchType } from './store';

export type ToolbarItem = {
  id: number;
  name: string;
  title: string;
  url: string;
  isSelected: boolean;
};

type Page = {
  id: number;
  paragraphs: {
    bookId: number;
    chapterId: number;
    page: number;
  };
};

export type Settings = {
  font_id: number;
  font_size: string;
  font_color: string;
  font_weight: string;
  word_spacing: string;
  paragraph_spacing: string;
  line_height: string;
  background_color: string;
  wallpaper_on: number;
  background_image: string;
  ruler_top: number;
  ruler_top_color: string;
  ruler_top_opacity: string;
  ruler_bottom: number;
  ruler_bottom_color: string;
  ruler_bottom_opacity: string;
  tour_watched: number;
  font: Font;
};

export type FocusModeSettings = {
  type: string;
  timeMinutes: number;
  status: string;
};

export type ReaderStateType = {
  mode: string;
  chapter: number;
  menu: Array<ToolbarItem>;
  toolbar: Array<ToolbarItem>;
  user: Array<ToolbarItem>;
  settings: Settings;
  reseted_settings: Settings;
  settingsLoaded: boolean;
  settingsStates: Array<Settings>;
  focusModeSettings: FocusModeSettings;
  openTooltipMobile: boolean;
};

const defaultState: ReaderStateType = {
  mode: 'reading',
  chapter: 5,
  menu: [
    { id: 0, name: 'home', title: 'Home', url: 'home', isSelected: false },
    {
      id: 1,
      name: 'chapters',
      title: 'Chapters',
      url: 'chapters',
      isSelected: false
    }
  ],
  toolbar: [
    { id: 0, name: 'reading', title: 'Reading', url: '', isSelected: false },
    { id: 1, name: 'sound', title: 'Sound', url: '', isSelected: false },
    { id: 2, name: 'video', title: 'Video', url: '', isSelected: false },
    { id: 3, name: 'ruler', title: 'Ruler', url: '', isSelected: false },
    // { id: 3, name: 'notes', title: 'Notes', url: '', isSelected: false },
    { id: 4, name: 'focus', title: 'Focus', url: '', isSelected: false }
  ],
  user: [
    {
      id: 0,
      name: 'logout',
      title: 'Logout',
      url: 'logout',
      isSelected: false
    }
  ],
  settings: {
    font_id: 9,
    font_size: '14',
    font_color: '#191818',
    font_weight: '400',
    word_spacing: '1.15',
    paragraph_spacing: '30',
    line_height: '16.8',
    background_color: '#FFFDF8',
    wallpaper_on: 1,
    background_image: 'none',
    ruler_top: 0,
    ruler_top_color: '#000000',
    ruler_top_opacity: '0.25',
    ruler_bottom: 0,
    ruler_bottom_color: '#000000',
    ruler_bottom_opacity: '0.5',
    tour_watched: 0,
    font: {
      id: 9,
      name: 'Open Sans',
      face: 'Open Sans',
      style: ''
    }
  },
  reseted_settings: {
    font_id: 9,
    font_size: '14',
    font_color: '#191818',
    font_weight: '400',
    word_spacing: '1.15',
    paragraph_spacing: '30',
    line_height: '16.8',
    background_color: '#FFFDF8',
    wallpaper_on: 1,
    background_image: 'none',
    ruler_top: 0,
    ruler_top_color: '#000000',
    ruler_top_opacity: '0.25',
    ruler_bottom: 0,
    ruler_bottom_color: '#000000',
    ruler_bottom_opacity: '0.5',
    tour_watched: 1,
    font: {
      id: 9,
      name: 'Open Sans',
      face: 'Open Sans',
      style: ''
    }
  },
  settingsLoaded: false,
  settingsStates: [],
  focusModeSettings: {
    type: 'endless',
    timeMinutes: 0,
    status: 'inProcess'
  },
  openTooltipMobile: false
};

const readerSlice = createSlice({
  name: 'reading',
  initialState: defaultState,
  reducers: {
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
      state.toolbar = state.toolbar.map(el =>
        (el.name === 'sound' || el.name === 'video') && el.name !== state.mode
          ? { ...el, isSelected: false }
          : el
      );
    },
    setActive: (state, action: PayloadAction<string>) => {
      state.toolbar = state.toolbar.map(el =>
        el.name === action.payload
          ? { ...el, isSelected: true }
          : { ...el, isSelected: false }
      );
    },
    setSettings: (state, action: PayloadAction<Object>) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setOpenTooltipMobile: (state, action: PayloadAction<boolean>) => {
      state.openTooltipMobile = action.payload;
    },
    setSettingsLoaded: state => {
      state.settingsLoaded = true;
    },
    saveSettingsState: (state, action: PayloadAction<Settings>) => {
      state.settingsStates.push(action.payload);
    },
    reduceSettingsState: state => {
      state.settingsStates.pop();
    },
    applySavedSettingsState: state => {
      const prevState = state.settingsStates.pop();
      if (prevState) {
        state.settings = prevState;
      }
    },
    resetAllSettings: state => {
      state.settings = defaultState.reseted_settings;
      state.settingsStates = defaultState.settingsStates;
    },
    resetSettings: state => {
      state.settings.font = defaultState.settings.font;
      state.settings.font_id = defaultState.settings.font_id;
      state.settings.font_size = defaultState.settings.font_size;
      state.settings.font_color = defaultState.settings.font_color;
      state.settings.font_weight = defaultState.settings.font_weight;
    },
    resetSpacingSettings: state => {
      state.settings.word_spacing = defaultState.settings.word_spacing;
      state.settings.paragraph_spacing =
        defaultState.settings.paragraph_spacing;
      state.settings.line_height = defaultState.settings.line_height;
    },
    resetBackgroundSettings: state => {
      state.settings.background_color = defaultState.settings.background_color;
      state.settings.wallpaper_on = defaultState.settings.wallpaper_on;
      state.settings.background_image = defaultState.settings.background_image;
    },
    resetRulerSettings: state => {
      state.settings.ruler_top = defaultState.settings.ruler_top;
      state.settings.ruler_top_color = defaultState.settings.ruler_top_color;
      state.settings.ruler_top_opacity =
        defaultState.settings.ruler_top_opacity;
      state.settings.ruler_bottom = defaultState.settings.ruler_bottom;
      state.settings.ruler_bottom_color =
        defaultState.settings.ruler_bottom_color;
      state.settings.ruler_bottom_opacity =
        defaultState.settings.ruler_bottom_opacity;
    },
    setChapter: (state, action: PayloadAction<number>) => {
      state.chapter = action.payload;
    },
    setPlayerSpeed: (state, action: PayloadAction<number>) => {},
    setFocusModeSettings: (state, action: PayloadAction<Object>) => {
      state.focusModeSettings = {
        ...state.focusModeSettings,
        ...action.payload
      };
    },
    resetFocusModeSettings: state => {
      state.focusModeSettings = defaultState.focusModeSettings;
    }
  }
});

const readerReducer = readerSlice.reducer;

export const {
  setMode,
  setActive,
  setChapter,
  setPlayerSpeed,
  setSettings,
  setSettingsLoaded,
  saveSettingsState,
  reduceSettingsState,
  applySavedSettingsState,
  resetAllSettings,
  resetSettings,
  resetSpacingSettings,
  resetBackgroundSettings,
  resetRulerSettings,
  setFocusModeSettings,
  resetFocusModeSettings,
  setOpenTooltipMobile
} = readerSlice.actions;
export default readerReducer;

export const getReadingSettingsThunk = () => async (dispatch: DispatchType) => {
  try {
    const readingSettings = await readingSettingsApi.getReadingSettings();
    if (Object.keys(readingSettings.data).length) {
      dispatch(setSettings(readingSettings.data));
      dispatch(saveSettingsState(readingSettings.data));
    }
    dispatch(setSettingsLoaded());
  } catch (err) {}
};

export const saveReadingSettingsThunk = (settings: Settings) => async (
  dispatch: DispatchType
) => {
  try {
    const readingSettings = await readingSettingsApi.saveReadingSettings(
      settings
    );
  } catch (err) {}
};
