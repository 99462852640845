import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { booksAPI } from '../api/books-api';
import { DispatchType } from './store';

export type ReadingProgressType = {
  chapter_id: number;
  page: number;
};

export type BookType = {
  id: number;
  name: string;
  description: string;
  image: string;
  author: string;
  pages_count: number;
  is_published: boolean;
  reading_progress: ReadingProgressType | null;
};

type HomeStateType = {
  books: Array<BookType>;
  currentBook: BookType;
  isLoading: boolean;
  searchValue: null | string;
  openBurgerTrigger: boolean;
};

const defaultState: HomeStateType = {
  books: [],
  currentBook: {} as BookType,
  isLoading: false,
  searchValue: null,
  openBurgerTrigger: false
};

const homeSlice = createSlice({
  name: 'home',
  initialState: defaultState,
  reducers: {
    setBurgerTrigger: (state, action: PayloadAction<boolean>) => {
      state.openBurgerTrigger = action.payload;
    },
    setBooks: (state, action: PayloadAction<Array<BookType>>) => {
      state.books = action.payload;
    },
    setBook: (state, action: PayloadAction<BookType>) => {
      state.currentBook = action.payload;
    },
    resetBook: state => {
      state.currentBook = defaultState.currentBook;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setReadingProgress: (state, action: PayloadAction<ReadingProgressType>) => {
      state.currentBook.reading_progress = action.payload;
    }
  }
});

const homeReducer = homeSlice.reducer;

export const {
  setBooks,
  setBook,
  resetBook,
  setLoading,
  setReadingProgress,
  setSearchValue,
  setBurgerTrigger
} = homeSlice.actions;
export default homeReducer;

export const booksListThunk = () => async (dispatch: DispatchType) => {
  try {
    const booksList = await booksAPI.getBooksList();
    dispatch(setBooks(booksList.data));
  } catch (err) {}
};

export const bookDetailsThunk = (bookId: number) => async (
  dispatch: DispatchType
) => {
  try {
    const booksDetails = await booksAPI.getBookDetails(bookId);
    dispatch(setBook(booksDetails.data));
  } catch (err) {}
};

export const saveReadingProgressThunk = (
  bookId: number,
  chapterId: number,
  page: number
) => async (dispatch: any) => {
  try {
    const readingProgress = await booksAPI.saveReadingProgress(
      bookId,
      chapterId,
      page
    );
    dispatch(setReadingProgress(readingProgress.data));
  } catch (err) {}
};
