import React, { useEffect, useState } from 'react';
import { Input } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import { setSettings } from '../../../../../redux/Reader';
import styles from '../WordSpacingSlider/WordSpacingSlider.module.scss';
import { ReactComponent as Paragraph } from '../../../../../assets/images/Paragraph.svg';
import SliderComponent from '../../../Slider/Slider';
const range = {
  min: '10',
  max: '100'
};
const ParagraphSpacingSlider = () => {
  const dispatch = useDispatch();
  const { paragraph_spacing } = useSelector(
    (state: StateType) => state.reader.settings
  );
  const [inputValue, setInputValue] = useState(paragraph_spacing);

  useEffect(() => {
    setInputValue(paragraph_spacing);
  }, [paragraph_spacing]);

  const handleChange = (event: any, value: any) => {
    dispatch(setSettings({ paragraph_spacing: value }));
  };

  const handleInputClick = (event: any) => event.target.select();

  const handleInputChange = (event: any) => {
    const ev = Number(event.target.value);
    if (ev === 0) {
      setInputValue('');
    }

    if (ev >= 1 && ev <= Number(range.max)) {
      setInputValue(String(ev));
    }
  };

  const handleInputKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      dispatch(
        setSettings({
          paragraph_settings:
            inputValue.length && Number(inputValue) >= Number(range.min)
              ? inputValue
              : range.min
        })
      );
    }
  };

  return (
    <div>
      <div className={styles.titleBlock}>
        <Paragraph className={styles.icon} />
        <div className={styles.sliderTitle}>Paragraph:</div>
        <Input
          className={styles.wordSpacingInput}
          value={inputValue}
          onClick={handleInputClick}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          inputProps={{
            type: 'number',
            'aria-labelledby': 'slider',
            min: Number(range.min),
            max: Number(range.max)
          }}
          disableUnderline={true}
        />
      </div>
      <SliderComponent
        min={Number(range.min)}
        max={Number(range.max)}
        step={1}
        value={Number(paragraph_spacing)}
        onChange={handleChange}
      />
    </div>
  );
};

export default ParagraphSpacingSlider;
