export const isMobileWidth: boolean = document.body.clientWidth <= 480;
export const isBigMobileWidth: boolean = document.body.clientWidth <= 640;
export const isTabletWidth: boolean = document.body.clientWidth <= 1024;

export const showTitle = (name: string) => {
  document.addEventListener(
    'visibilitychange',
    function() {
      if (document.hidden) {
        document.title = `${name} - We miss you ${String.fromCodePoint(
          0x2764
        )}`;
      } else {
        document.title = name;
      }
    },
    false
  );
};
