import React, { useEffect, useRef } from 'react';
import styles from './Reader.module.scss';
import Toolbar from '../../components/Toolbar/Toolbar';
import BasePage from '../../components/BasePage/BasePage';
import FooterReader from '../../components/FooterReader/FooterReader';
import { useDispatch, useSelector } from 'react-redux';
import { chapterDetailsThunk, setChapterFromStore } from '../../redux/Chapters';
import { bookDetailsThunk } from '../../redux/Home';
import { showTitle } from '../../helpers/constants';
import { Redirect, useLocation } from 'react-router-dom';
import {
  getReadingSettingsThunk,
  saveReadingSettingsThunk,
  saveSettingsState
} from '../../redux/Reader';
import { StateType } from '../../redux/store';
import Header from '../../components/Header/Header';
import { handlerCSS } from '../../helpers/cssHelper';
import ReaderPagination from '../../components/ReaderPagination/ReaderPagination';
import RulerArrow from '../../components/RulerArrow/RulerArrow';

const queryString = require('query-string');
let timer: any = null;

const useDidMountEffect = (func: Function, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

type Props = {
  history: History;
  location: Location;
  match: {
    isExact: boolean;
    params: any;
    path: string;
    url: string;
  };
  staticContext: any;
};

export const Reader: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const { chapters, page, auth, home } = useSelector(
    (state: StateType) => state
  );
  const query = queryString.parse(useLocation().search);

  const { settings, settingsLoaded, mode } = useSelector(
    (state: StateType) => state.reader
  );

  const currentBook = useSelector((state: StateType) => state.home.currentBook);
  showTitle(currentBook ? currentBook.name : 'Reading');

  useDidMountEffect(() => {
    if (settingsLoaded) {
      dispatch(saveSettingsState(settings));
      clearTimeout(timer);

      timer = setTimeout(() => {
        dispatch(saveReadingSettingsThunk(settings));
      }, 1000);
    }
  }, [settings]);

  useEffect(() => {
    if (query.bookId !== home.currentBook.id) {
      dispatch(bookDetailsThunk(query.bookId));
    }
  }, [dispatch, query.bookId]);

  useEffect(() => {
    if (
      page.paragraphs.length &&
      page.paragraphs[0].chapter_id !== chapters.currentChapter.id
    ) {
      if (chapters.chapters.length > 0) {
        dispatch(setChapterFromStore(page.paragraphs[0].chapter_id));
      } else {
        dispatch(chapterDetailsThunk(page.paragraphs[0].chapter_id));
      }
    }
  }, [dispatch, page.paragraphs]);

  useEffect(() => {
    if (!settingsLoaded) {
      dispatch(getReadingSettingsThunk());
    }
  }, [dispatch]);

  if (!query.bookId) {
    return <Redirect to={'/'} />;
  }

  if (query.bookId && !query.page) {
    return <Redirect to={`/chapters?bookId=${query.bookId}`} />;
  }

  handlerCSS({
    wallpaperColor: settings.wallpaper_on ? '' : settings.background_color
  });

  return (
    <div className={`${styles.main} ${mode === 'focus' && styles.focusMode}`}>
      <Header mode={mode} user={auth.userSession.user} />
      {mode !== 'focus' && <Toolbar />}
      <BasePage {...props} />
      <ReaderPagination />
    </div>
  );
};

export default Reader;
