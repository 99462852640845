import React, { Suspense } from 'react';

export const withSuspense = (Component: React.ComponentType<any>) => {
  return (props: any | null) => {
    return (
      <Suspense fallback={<h1>wait a little</h1>}>
        <Component {...props} />
      </Suspense>
    );
  };
};
