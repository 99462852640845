import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import styles from './RegisterMain.module.scss';
import { Button, FormHelperText } from '@material-ui/core';
import { TextField, Select, Checkbox } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { StateType } from '../../redux/store';
import { registerThunk } from '../../redux/Auth';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';

const CustomTextField = styled(TextField)({
  //styles for TextField(inputs)
  '& .MuiFormHelperText-root.Mui-error': {
    //yup error text style
    color: '#FF8B8B',
    fontSize: 12,
    fontFamily: 'OpenSansBold',
    display: 'none', //REMOVED DEF ERRORS
  },
  '& label.Mui-focused': {
    color: '#24D399',
    borderWidth: 0,
    backgroundColor: 'red',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    '& fieldset': {
      //default styles
      borderColor: '#c5c5c5',
      borderWidth: 0,
      borderRadius: 12,
    },
    '&:hover fieldset': {
      //hover
      borderColor: '#666666',
      borderWidth: 0,
    },
    '&.Mui-focused fieldset': {
      //focused input
      borderColor: '#c5c5c5',
      borderWidth: 0,
    },
    '&.Mui-error fieldset': {
      //error input
      borderWidth: 0,
      borderColor: '#FF8B8B',
    },
  },
});

const RegisterMain: React.FC<any> = () => {
  const auth = useSelector((state: StateType) => state.auth);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRetype, setShowPasswordRetype] = useState(false);

  const initialValues = {
    firstname: localStorage.getItem('firstName'),
    lastname:
      localStorage.getItem('lastName') === 'undefined'
        ? ''
        : localStorage.getItem('lastName'),
    email: '',
    where_studying: '',
    password: '',
    password_confirmation: '',
    employment_status: 'Studying',
    email_updates: false,
    terms: false,
  };

  const SignUpSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
      .min(8, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Required'),
    employment_status: Yup.string().required('Required'),
    terms: Yup.boolean().oneOf([true], 'You must accept Terms of Use'),
  });

  function onSubmit(values: any, { setSubmitting, setErrors }: any) {
    Promise.resolve('')
      .then(() => dispatch(registerThunk(values)))
      .then(() => setSubmitting(false));
  }

  if (auth.userSession && auth.userSession.user) {
    return <Redirect to={'/'} />;
  }

  const returnStylesWrapper = (
    error: any,
    touched: any,
    values: any,
    creds?: any
  ) => {
    if (error || creds) {
      return styles.inputFieldWrapperError;
    } else if (values && touched && !error) {
      return styles.inputFieldWrapperSuccess;
    } else {
      return styles.inputFieldWrapper;
    }
  };

  return (
    <div className={styles.registerBlock}>
      <p className={styles.heading}>
        Let's
        <span className={styles.boldText}> finish up!</span>
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={SignUpSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting, values }) => (
          <Form>
            <p className={styles.upperLabel}>Email address</p>
            <div
              className={returnStylesWrapper(
                errors.email,
                touched.email,
                values.email,
                auth.validation.errors.email
              )}
            >
              <Field
                component={CustomTextField}
                classes={{
                  root: styles.textField,
                }}
                id="email"
                name="email"
                placeholder="Email address"
                variant="outlined"
                type="email"
                // error={!!(errors.email || auth.validation.errors.email)}
                // helperText={
                //   !errors.email ? auth.validation.errors.email : errors.email
                // }
              />
            </div>
            {!!(errors.email || auth.validation.errors.email) && (
              <p className={styles.inputTextError}>
                {!errors.email ? auth.validation.errors.email : errors.email}
              </p>
            )}
            <div className={styles.passwordWrapper}>
              <div className={styles.passwordColumn}>
                <p className={styles.upperLabel}>Password</p>
                <div
                  className={returnStylesWrapper(
                    errors.password,
                    touched.password,
                    values.password,
                    auth.validation.errors.password
                  )}
                >
                  <Field
                    component={CustomTextField}
                    classes={{
                      root: styles.textFieldPassword,
                    }}
                    id="password"
                    name="password"
                    placeholder="Password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    // error={
                    //   !!(errors.password || auth.validation.errors.password)
                    // }
                    // helperText={
                    //   !errors.password
                    //     ? auth.validation.errors.password
                    //     : errors.password
                    // }
                    InputProps={{
                      classes: { adornedEnd: styles.blockWithButton },
                      border: 'none',
                      endAdornment: (
                        <IconButton className={styles.passwordButton}>
                          <img
                            onClick={() => setShowPassword(!showPassword)}
                            src={require(showPassword
                              ? '../../assets/images/password-icon-visible.svg'
                              : '../../assets/images/password-icon-not-visible.svg')}
                            alt="password"
                            className={styles.passwordIcon}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                {!!(errors.password || auth.validation.errors.password) && (
                  <p className={styles.inputTextError}>
                    {!errors.password
                      ? auth.validation.errors.password
                      : errors.password}
                  </p>
                )}
              </div>
              <div className={styles.passwordColumn}>
                <p className={styles.upperLabel}>Retype password</p>
                <div
                  className={returnStylesWrapper(
                    errors.password_confirmation,
                    touched.password_confirmation,
                    values.password_confirmation,
                    auth.validation.errors.password_confirmation
                  )}
                >
                  <Field
                    component={CustomTextField}
                    classes={{
                      root: styles.textFieldPassword,
                    }}
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="Retype password"
                    variant="outlined"
                    type={showPasswordRetype ? 'text' : 'password'}
                    // error={
                    //   !!(
                    //     errors.password_confirmation ||
                    //     auth.validation.errors.password_confirmation
                    //   )
                    // }
                    // helperText={
                    //   !errors.password_confirmation
                    //     ? auth.validation.errors.password_confirmation
                    //     : errors.password_confirmation
                    // }
                    InputProps={{
                      classes: { adornedEnd: styles.blockWithButton },
                      border: 'none',
                      endAdornment: (
                        <IconButton className={styles.passwordButton}>
                          <img
                            onClick={() =>
                              setShowPasswordRetype(!showPasswordRetype)
                            }
                            src={require(showPasswordRetype
                              ? '../../assets/images/password-icon-visible.svg'
                              : '../../assets/images/password-icon-not-visible.svg')}
                            alt="password"
                            className={styles.passwordIcon}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                {!!(
                  errors.password_confirmation ||
                  auth.validation.errors.password_confirmation
                ) && (
                  <p className={styles.inputTextError}>
                    {!errors.password_confirmation
                      ? auth.validation.errors.password_confirmation
                      : errors.password_confirmation}
                  </p>
                )}
              </div>
            </div>
            <p className={styles.upperLabel}>I am currently:</p>
            <Field
              component={Select}
              className={styles.selectField}
              id="employment_status"
              name="employment_status"
              placeholder="I am currently"
              variant="outlined"
              // displayEmpty
              // error={
              //   !!(
              //     errors.employment_status ||
              //     auth.validation.errors?.employment_status
              //   )
              // }
            >
              {/*<MenuItem value="" disabled>
                Choose option
              </MenuItem>*/}
              <MenuItem value={'Studying'}>Studying</MenuItem>
              <MenuItem value={'Working'}>Working</MenuItem>
              <MenuItem value={'Looking for work'}>Looking for work</MenuItem>
              <MenuItem value={'Prefer not to say'}>Prefer not to say</MenuItem>
            </Field>
            <FormHelperText
              className={styles.selectError}
              error={true}
              variant="outlined"
            >
              {!errors.employment_status
                ? auth.validation.errors.employment_status
                : errors.employment_status}
            </FormHelperText>
            <label className={styles.checkBoxWrapper}>
              <Field
                component={Checkbox}
                className={styles.emailUpdates}
                classes={{ checked: styles.checkboxChecked }}
                id="email_updates"
                type="checkbox"
                name="email_updates"
              />
              <span className={styles.label}>
                I would like to receive email updates from Leo
              </span>
            </label>
            <label className={styles.checkBoxWrapper}>
              <Field
                component={Checkbox}
                className={styles.termsUpdates}
                classes={{ checked: styles.checkboxChecked }}
                id="terms"
                type="checkbox"
                name="terms"
              />
              <span className={styles.label}>
                I confirm I have read and accepted the{' '}
                <Link to="/terms">
                  {' '}
                  <span className={styles.link}>Terms of Use </span>
                </Link>{' '}
                and{' '}
                <Link to="/privacy">
                  {' '}
                  <span className={styles.link}>Privacy Policy </span>
                </Link>
              </span>
            </label>
            <FormHelperText
              className={styles.selectError}
              error={true}
              variant="outlined"
            >
              {!errors.terms ? (
                <p className={styles.inputTextError}>
                  {auth.validation.errors.terms}
                </p>
              ) : (
                <p className={styles.inputTextError}>{errors.terms}</p>
              )}
            </FormHelperText>
            <div className={styles.submitButton}>
              <Button type="submit" disabled={isSubmitting}>
                Let’s get reading!
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterMain;
