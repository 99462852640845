import React, {useEffect, useState} from 'react';
import styles from './SelectedTextMenu.module.scss';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {tts} from "../../intergations/tts";
import {withStyles} from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HearingIcon from '@material-ui/icons/Hearing';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { Divider } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch, useSelector} from "react-redux";
import {getWordThunk, resetDictionary} from "../../redux/Dictionary";
import {StateType} from "../../redux/store";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import CancelIcon from '@material-ui/icons/Cancel';
import {alertShowThunk} from "../../redux/Alert";
import {ParagraphType} from "../../redux/Page";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPopover-paper':{
        width: '400px',
        minHeight: '200px',
        borderRadius: '15px',
        backgroundColor: '#f7f4ec',
        color: '#191918'
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    typography: {
      padding: theme.spacing(2),
    },
  }),
);

const initialPopoverState = {
  mouseX: null,
  mouseY: null,
  word: null,
};

const SelectedTextMenu = (props:any) => {
  const dispatch = useDispatch();
  const {dictionary} = useSelector((state:StateType) => state);

  const [selectedMenuState, setSelectedMenuState] = useState(props.initialState);
  const [popover, setPopover] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
    word: null | string;
  }>(initialPopoverState);
  const [popoverLoading, setPopoverLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setSelectedMenuState(props.state);
  },[props.state])

  const handleTTS = () => {
    const selection = window.getSelection();
    if(selection){
      tts(selection.toString());
    }
  };

  const handleClose = () => {
    setSelectedMenuState(props.initialState);
  };

  const handlePopoverClose = () => {
    dispatch(resetDictionary());
    setPopover(initialPopoverState);
  };

  const handleDictionary = () => {
    setSelectedMenuState(props.initialState);
    const selection = window.getSelection();
    if(selection && selection.toString().split(' ').length === 1){
      setPopoverLoading(true);
      const popoverState = {
        mouseX: selectedMenuState.mouseX + 110,
        mouseY: selectedMenuState.mouseY + 105,
        word: selection.toString(),
      };
      setPopover(popoverState);

      Promise.resolve('').then(dispatch(getWordThunk(selection.toString())).then(function (){
        setPopoverLoading(false);
      }));
    }
    else{
      dispatch(alertShowThunk('error', 'Please select one word to get info from dictionary'));
    }
  }

  const handleDictionaryTTS = () => {
    if(popover.word){
      tts(popover.word);
    }
  }

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: '#f7f4ec',
      overflow: 'visible',
      color: '#191818',
      borderRadius: '5px',
      boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.12)',
      '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& li:hover': {
        backgroundColor: '#eeeeed',
        borderRadius: '5px'
      },
    },
  })(Menu);

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#191818',
        minWidth: '36px',
      }
    },
  }))(MenuItem);

  return (
    <div>
      <StyledMenu
        className={styles.contextMenu}
        keepMounted
        open={selectedMenuState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          selectedMenuState.mouseY !== null && selectedMenuState.mouseX !== null
            ? { top: selectedMenuState.mouseY, left: selectedMenuState.mouseX }
            : undefined
        }
      >
        <div className={styles.arrow}> </div>
        <StyledMenuItem onClick={handleDictionary}>
          <ListItemIcon>
            <ImportContactsIcon className={styles.icon} fontSize="small" />
          </ListItemIcon>
          Dictionary
        </StyledMenuItem>
        <Divider className={styles.divider} />
        <StyledMenuItem onClick={handleTTS}>
          <ListItemIcon>
            <HearingIcon className={styles.icon} fontSize="small" />
          </ListItemIcon>
          Text to speech
        </StyledMenuItem>
      </StyledMenu>

      <Backdrop className={classes.backdrop} open={popover.mouseY !== null} onClick={handleClose}> </Backdrop>
      <Popover
        className={classes.root}
        open={popover.mouseY !== null}
        onClose={handlePopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={
          popover.mouseY !== null && popover.mouseX !== null
            ? { top: popover.mouseY, left: popover.mouseX }
            : undefined
        }
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>
          <CancelIcon className={styles.dictionaryClose} fontSize="default" onClick={handlePopoverClose} />
          <div>
            <VolumeUpIcon className={styles.dictionaryTTS} fontSize="small" onClick={handleDictionaryTTS} />
            <strong className={styles.dictionaryWord}>{popover.word}</strong>
          </div>
          {popoverLoading?<CircularProgress className={styles.loader} />:''}
          {dictionary.error?<div className={styles.dictionaryError}>{dictionary.error}</div>:''}
          <div className={styles.dictionaryPronunciation}>{dictionary.pronunciation?`[${dictionary.pronunciation}]`:''}</div>
          {dictionary.definitions.map((el: any, i) => (
            <div className={styles.dictionaryMainDefinition}>{++i+'. '+el.definition}</div>
          ))}
        </Typography>
      </Popover>
    </div>
  );
};

export default SelectedTextMenu;
