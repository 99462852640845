import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { BookType } from '../../redux/Home';
import { booksListThunk } from '../../redux/Home';
import { StateType } from '../../redux/store';
import { resetBook } from '../../redux/Home';
import { resetChapters } from '../../redux/Chapters';
import { logoutThunk } from '../../redux/Auth';
import { resetPage } from '../../redux/Page';
import { useMousePosition } from '../../helpers/customHooks';
import { showTitle } from '../../helpers/constants';

import Layout from '../../components/Layout/Layout';
import BookComponent from '../../components/BookComponent/BookComponent';

export const Home: React.FC<StateType> = (props) => {
  const home = useSelector((state: StateType) => state.home);
  showTitle('Leo Reader');
  let history = useHistory();

  const [viewedBooks, setViewedBooks] = useState<Array<BookType>>([]);
  const { x, y } = useMousePosition();
  const dispatch = useDispatch();
  const location = window.location.pathname;
  const param = window.location.search;

  useEffect(() => {
    dispatch(resetBook());
    dispatch(resetChapters());
    dispatch(resetPage());
    dispatch(booksListThunk());
    dispatch(logoutThunk);
  }, [dispatch]);

  useEffect(() => {
    if (home?.searchValue) {
      setViewedBooks(
        home.books.filter(
          (book) =>
            book.name
              .toLowerCase()
              .includes(home?.searchValue?.toLocaleLowerCase().trim() || '') ||
            book.author
              .toLowerCase()
              .includes(home?.searchValue?.toLocaleLowerCase().trim() || '')
        )
      );
    } else {
      setViewedBooks(home.books);
    }
  }, [home.searchValue, home?.books?.length]);

  useEffect(() => {
    if (param === '?back=true') {
      window.location.href = '/';
    }
  }, [location]);

  return (
    <Layout>
      <main className={styles.main}>
        <h1>
          My <span>Books</span>
        </h1>
        {!viewedBooks?.length && home.searchValue !== null ? (
          <div className={styles.noResult}>Search returned no results</div>
        ) : (
          <div className={styles.booksList}>
            {viewedBooks.map((el: BookType, index: number) => {
              return (
                <BookComponent el={el} index={index} key={index} x={x} y={y} />
              );
            })}
          </div>
        )}
        {home?.isLoading && <CircularProgress className={styles.loader} />}
      </main>
    </Layout>
  );
};

export default Home;
