import React, { ReactComponentElement, useState } from 'react';
import styles from './ToolbarButton.module.scss';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import { isMobileWidth } from '../../../helpers/constants';

type Props = {
  imageName: string;
  child: ReactComponentElement<any>;
  childBlue?: ReactComponentElement<any>;
  setButton?: Function;
  setElClick?: Function;
  active?: boolean;
  isHideActiveArrow?: boolean;
  open?: boolean;
};

const ToolbarButton: React.FC<Props> = ({
  imageName,
  child,
  childBlue,
  setButton,
  setElClick,
  active,
  isHideActiveArrow,
  open
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`${styles.button} ${imageName === 'Home' &&
        styles.homeButton} ${active &&
        styles.buttonActive} ${isHideActiveArrow && styles.hideActiveArrow}`}
      onClick={(e: any) => setElClick && setElClick(e)}
    >
      <Tooltip
        PopperProps={{
          disablePortal: true
        }}
        open={isMobileWidth ? imageName !== 'Home' && open : undefined}
        classes={{
          popper: styles.popper,
          popperArrow: styles.popperArrow,
          tooltip: styles.tooltip,
          arrow: styles.arrow
        }}
        title={imageName}
        placement="right"
        arrow
      >
        <IconButton
          className={active ? styles.active : ''}
          onClick={() => setButton && setButton(imageName)}
        >
          {child}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ToolbarButton;
