import React, { ReactNode, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StateType } from '../../redux/store';
import { setBurgerTrigger } from '../../redux/Home';
import { clearErrors } from '../../redux/Auth';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

import styles from './Layout.module.scss';

type PropsType = {
  children?: ReactNode;
  mode?: string;
  signUpButtonPressedMain?: () => void;
  loginButtonPressedMain?: () => void;
  isSingle?: boolean;
  signIn?: boolean;
};

const Layout: React.FC<PropsType> = ({
  children,
  mode,
  signUpButtonPressedMain,
  loginButtonPressedMain,
  isSingle,
  signIn,
}) => {
  const profile = useSelector((state: StateType) => state.auth);
  const dispatch = useDispatch();
  const { openBurgerTrigger } = useSelector((state: StateType) => state.home);
  const [isOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (openBurgerTrigger) {
      handelOpenModal();
    }
  }, [openBurgerTrigger]);

  const handelOpenModal = () => {
    dispatch(clearErrors());
    setIsModalOpen(true);
  };

  const handelCloseModal = () => {
    dispatch(clearErrors());

    dispatch(setBurgerTrigger(false));
    setIsModalOpen(false);
  };

  const user = profile.userSession && profile.userSession.user;

  const isWhiteBackground = (!!user && isOpen) || (!user && isOpen);
  const isHidedContent = !!user && isOpen;

  return (
    <div className={isSingle ? styles.homeSingle : styles.home}>
      <Header
        user={user}
        mode={mode}
        signIn={signIn}
        isModalOpen={isOpen}
        handelOpenModal={handelOpenModal}
        handelCloseModal={handelCloseModal}
        isWhiteBackground={isWhiteBackground}
        signUpButtonPressedMain={signUpButtonPressedMain}
        loginButtonPressedMain={loginButtonPressedMain}
      />
      {isHidedContent ? <div className={styles.emptyComponent} /> : children}
      <Footer isWhiteBackground={isWhiteBackground} />
    </div>
  );
};

export default Layout;
