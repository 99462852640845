import React, { useEffect } from 'react';
import styles from './Privacy.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../redux/store';
import { logoutThunk } from '../../redux/Auth';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { showTitle } from '../../helpers/constants';

export const Privacy: React.FC<StateType> = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  showTitle('Privacy Policy');
  window.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    dispatch(logoutThunk);
  }, [dispatch]);

  const handleBackClickSignUp = () => {
    localStorage.setItem('isBackToHomeSignUp', 'true');
    history.push('/login');
  };

  const handleBackClickLogin = () => {
    localStorage.setItem('isBackToHomeLogin', 'true');
    history.push('/login');
  };

  return (
    <Layout
      signUpButtonPressedMain={() => handleBackClickSignUp()}
      loginButtonPressedMain={() => handleBackClickLogin()}
    >
      <main className={styles.main}>
        <div className={styles.title}>
          <p className={styles.posted}>Posted: Januari 1, 2023</p>
          <h1>Privacy Policy</h1>
        </div>
        <div className={styles.privacy}>
          <p>
            This website is operated by Commerical Break London Limited and
            whose registered address is 840 Harrow Road NW10 5JU, UK (“We”) are
            committed to protecting and preserving the privacy of our visitors
            when visiting our site or communicating electronically with us.
          </p>

          <p>
            This policy sets out how we process any personal data we collect
            from you or that you provide to us through our website. We confirm
            that we will keep your information secure and that we will comply
            fully with all applicable UK Data Protection legislation and
            regulations. Please read the following carefully to understand what
            happens to personal data that you choose to provide to us, or that
            we collect from you when you visit this site. By visiting
            www.leoreader.com (our website) you are accepting and consenting to
            the practices described in this policy.
          </p>

          <p className={styles.bold}>
            Types of information we may collect from you
          </p>
          <p className={styles.bold}></p>
          <p>
            We may collect, store and use the following kinds of personal
            information about individuals who visit and use our website:
          </p>

          <p>
            Information you supply to us. You may supply us with information
            about you by filling in forms on our website. This includes
            information you provide when you create an account. The information
            you give us may include your name, address, e-mail address and phone
            number.
          </p>

          <p>
            Information our website automatically collects about you. With
            regard to each of your visits to our website we may automatically
            collect information including the following: technical information,
            including a truncated and anonymised version of your Internet
            protocol (IP) address, browser type and version, operating system
            and platform; information about your visit, including what pages you
            visit, how long you are on the site, how you got to the site
            (including date and time); page response times, length of visit, and
            what you click on
          </p>

          <p className={styles.bold}>Cookie Policy</p>

          <p>
            Our website uses cookies to distinguish you from other users of our
            website. This helps us to provide you with a good experience when
            you browse our website and also allows us to improve our site.{' '}
          </p>

          <p className={styles.bold}>
            How we may use the information we collect
          </p>

          <p>We use the information in the following ways:</p>

          <p>
            <span className={styles.bold}>
              Information you supply to us. We will use this information:
            </span>
            <br /> to provide you with information and/or services that you
            request from us;
          </p>

          <p>
            <span className={styles.bold}>
              Information we automatically collect about you. We will use this
              information:
            </span>
            <br />
            to administer our site including troubleshooting and statistical
            purposes;
            <br />
            to improve our site to ensure that content is presented in the most
            effective manner for you and for your computer; <br />
            security and debugging as part of our efforts to keep our site safe
            and secure. <br />
          </p>

          <p>
            This information is collected anonymously and is not linked to
            information that identifies you as an individual. We use Google
            Analytics to track this information. Find out how Google uses your
            data at https://support.google.com/analytics/answer/6004245.
          </p>

          <p className={styles.bold}>Disclosure of your information</p>

          <p>
            Any information you provide to us will either be emailed directly to
            us or may be stored on a secure server. We use a trusted third party
            website and hosting provider to facilitate the running and
            management of this website.
          </p>

          <p>
            We do not rent, sell or share personal information about you with
            other people or non-affiliated companies.
          </p>

          <p>
            We will use all reasonable efforts to ensure that your personal data
            is not disclosed to regional/national institutions and authorities,
            unless required by law or other regulations.
          </p>

          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we will do our best to protect your
            personal data, we cannot guarantee the security of your data
            transmitted to our site; any transmission is at your own risk. Once
            we have received your information, we will use strict procedures and
            security features to try to prevent unauthorised access.
          </p>

          <p className={styles.bold}>Third party links</p>

          <p>
            Our site may, from time to time, contain links to and from the third
            party websites. If you follow a link to any of these websites,
            please note that these websites have their own privacy policies and
            that we do not accept any responsibility or liability for these
            policies. Please check these policies before you submit any personal
            data to these websites.
          </p>

          <p className={styles.bold}>
            Your rights – access to your personal data
          </p>

          <p>
            You have the right to ensure that your personal data is being
            processed lawfully (“Subject Access Right”). Your subject access
            right can be exercised in accordance with data protection laws and
            regulations. Any subject access request must be made in writing to
            Commercial Break Ltd London. We will provide your personal data to
            you within the statutory time frames. To enable us to trace any of
            your personal data that we may be holding, we may need to request
            further information from you. If you have a complaint about how we
            have used your information, you have the right to complain to the
            Information Commissioner’s Office (ICO).
          </p>

          <p className={styles.bold}>Changes to our privacy policy</p>

          <p>
            Any changes we may make to our privacy policy in the future will be
            posted on this page and, where appropriate, notified to you by
            e-mail. Please check back frequently to see any updates or changes
            to our privacy policy.
          </p>

          <p className={styles.bold}>Contact</p>

          <p>
            Questions, comments and requests regarding this privacy policy are
            welcomed and should be addressed to team@leoreader.com
          </p>
        </div>
      </main>
    </Layout>
  );
};

export default Privacy;
