import React, { useEffect, useState } from 'react';
import styles from './RulerPopup.module.scss';
import { Popover, IconButton, Input } from '@material-ui/core';
import { handlerCSS } from '../../../../../helpers/cssHelper';
import { ReactComponent as Ruler } from '../../../../../assets/images/RulerIcon.svg';
import FocusModeTools from '../../../FocusModeTools/FocusModeTools';
import RulerTools from '../Ruler/RulerTools';
import CancelIcon from '@material-ui/icons/Cancel';

type Props = {
  open: boolean;
  anchorEl: any;
  handlePopoverClose?: any;
};

const readingHeight = () => {
  const reading = document.getElementById('reading');
  const measures = reading && reading.offsetHeight * 2 + 40;
  return measures;
};

const RulerPopup: React.FC<Props> = props => {
  const { open, anchorEl, handlePopoverClose } = props;
  useEffect(() => {
    handlerCSS({ elTop: `${top + 'px'}` });
  });

  const top: any = readingHeight();

  return (
    <Popover
      id="mouse-over-popover"
      classes={{
        root: styles.popoverWrapper,
        paper: styles.popover
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: -top,
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={open}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <div className={styles.wrapper}>
        <IconButton
          className={styles.closeButton}
          onClick={() => handlePopoverClose()}
        >
          <CancelIcon />
        </IconButton>
        <div className={styles.sidebar}>
          <div className={styles.sidebarIcon}>
            <Ruler />
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.title}>Ruler</div>
          <hr />
          <RulerTools />
        </div>
      </div>
    </Popover>
  );
};

export default RulerPopup;
