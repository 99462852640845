import React, { useEffect, useState } from 'react';
import styles from './FocusModeTools.module.scss';
import { Popover, IconButton, Input } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../redux/store';
import CancelIcon from '@material-ui/icons/Cancel';
import { ReactComponent as TimerIcon } from '../../../assets/images/timer.svg';
import Button from '@material-ui/core/Button';
import { setMode, setFocusModeSettings } from '../../../redux/Reader';
import { alertShowThunk } from '../../../redux/Alert';

type Props = {
  handlePopoverClose?: any;
  handleCloseFocusMode?: any;
  focusType: string;
};

const range = {
  min: 1,
  max: 180
};

const FocusModeTools: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const { handlePopoverClose, handleCloseFocusMode, focusType } = props;

  const [inputValue, setInputValue] = useState<any>(5);

  const handleEndlessActivateButton = () => {
    dispatch(setMode('focus'));
  };

  const handleTimedFocus = (minutes: number) => {
    if (minutes > 0) {
      dispatch(
        setFocusModeSettings({
          type: 'timed',
          timeMinutes: minutes,
          status: 'inProcess'
        })
      );
      dispatch(setMode('focus'));
    } else {
      dispatch(alertShowThunk('error', 'Please fill correct timer value'));
    }
  };

  const handleInputClick = (event: any) => {
    event.target.select();
    event.target.focus();
  };

  const handleInputChange = (event: any) => {
    const ev = Number(event.target.value);
    if (ev === 0) {
      setInputValue('');
    }

    if (ev >= Number(range.min) && ev <= Number(range.max)) {
      setInputValue(ev);
    }
  };

  const handleFocusInputKeyDown = (event: any, minutes: number) => {
    if (event.keyCode === 13) {
      handleTimedFocus(minutes);
    }
  };

  return (
    <>
      {focusType === 'popover' ? (
        <div className={styles.main}>
          <div className={styles.timerPredefineButtons}>
            <Button variant="contained" onClick={() => handleTimedFocus(10)}>
              10 min
            </Button>
            <Button variant="contained" onClick={() => handleTimedFocus(20)}>
              20 min
            </Button>
            <Button variant="contained" onClick={() => handleTimedFocus(30)}>
              30 min
            </Button>
          </div>
          <div>
            <div className={styles.manualTimer}>
              <TimerIcon />
              <div>
                <Input
                  className={styles.focusTimerInput}
                  value={inputValue}
                  onClick={handleInputClick}
                  onChange={handleInputChange}
                  onKeyDown={event =>
                    handleFocusInputKeyDown(event, inputValue)
                  }
                  inputProps={{
                    type: 'number',
                    'aria-labelledby': 'slider',
                    min: range.min,
                    max: range.max
                  }}
                  disableUnderline={true}
                />
                <span className={styles.minText}>Min</span>
              </div>
            </div>
          </div>
          <div className={styles.focusPopupFooterPopOver}>
            <Button
              onClick={() => handleTimedFocus(Number(inputValue))}
              className={styles.manualTimerActivateButton}
              variant="outlined"
            >
              Activate timed focus
            </Button>
            <div className={styles.separator} />
            <Button
              className={styles.endlessActivateButton}
              variant="contained"
              onClick={handleEndlessActivateButton}
            >
              Activate endless focus
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.focusPaper}>
          <h3 id="transition-modal-title">
            Focus mode has expired.
            <br />
            Well done!
            <br />
          </h3>
          <p> Would you like to extend?</p>
          <div className={styles.actions} id="transition-modal-description">
            <div className={styles.buttonsWrapper}>
              <Button variant="contained" onClick={() => handleTimedFocus(10)}>
                10 min
              </Button>
              <Button variant="contained" onClick={() => handleTimedFocus(20)}>
                20 min
              </Button>
              <Button variant="contained" onClick={() => handleTimedFocus(30)}>
                30 min
              </Button>
            </div>
            <div className={styles.focusManualTimerBlockWrapper}>
              <TimerIcon />
              <div className={styles.focusManualTimerBlock}>
                <Input
                  className={styles.focusTimerInput}
                  value={inputValue}
                  onClick={handleInputClick}
                  onChange={handleInputChange}
                  onKeyDown={event =>
                    handleFocusInputKeyDown(event, inputValue)
                  }
                  inputProps={{
                    type: 'number',
                    'aria-labelledby': 'slider',
                    min: 1,
                    max: 180
                  }}
                  disableUnderline={true}
                />
                <span className={styles.minText}>Min</span>
              </div>
            </div>
          </div>
          <div className={styles.focusPopupFooter}>
            <Button
              className={styles.activateFocusButton}
              variant="contained"
              onClick={() => handleTimedFocus(inputValue)}
            >
              Activate timed focus
            </Button>
            <div className={styles.separator} />
            <Button
              className={styles.exitFocusButton}
              variant="outlined"
              onClick={() => handleCloseFocusMode()}
            >
              Exit focus mode
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default FocusModeTools;
