import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import {
  applySavedSettingsState,
  reduceSettingsState,
  resetAllSettings,
  resetSettings,
  resetSpacingSettings,
  resetBackgroundSettings,
  resetRulerSettings
} from '../../../../../redux/Reader';
import styles from './UndoReset.module.scss';
import { Backdrop, Button, IconButton, Modal } from '@material-ui/core';
import { ReactComponent as Undo } from '../../../../../assets/images/undo.svg';
import Fade from '@material-ui/core/Fade';
import ButtonComponent from '../../../Button/Button';
import { ReactComponent as ModalCloseIcon } from '../../../../../assets/images/modalCloseIcon.svg';

const UndoReset = () => {
  const dispatch = useDispatch();
  const [resetOpen, setResetOpen] = React.useState(false);
  const { settingsStates } = useSelector((state: StateType) => state.reader);
  const { subMode } = useSelector((state: StateType) => state.toolbar);

  const handleResetOpen = () => {
    setResetOpen(true);
  };

  const handleResetPartly = () => {
    switch (subMode) {
      case 'fonts':
        dispatch(resetSettings());
        break;
      case 'spacing':
        dispatch(resetSpacingSettings());
        break;
      case 'background':
        dispatch(resetBackgroundSettings());
        break;
      case 'ruler':
        dispatch(resetRulerSettings());
        break;
    }
    setResetOpen(false);
  };

  const handleResetAll = () => {
    dispatch(resetAllSettings());
    setResetOpen(false);
  };

  const handleResetClose = () => {
    setResetOpen(false);
  };

  const handleUndo = () => {
    dispatch(reduceSettingsState());
    dispatch(applySavedSettingsState());
  };

  const ResetModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={resetOpen}
        onClose={handleResetClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={resetOpen}>
          <div className={styles.paper}>
            <div className={styles.closeIcon} onClick={handleResetClose}>
              <ModalCloseIcon />
            </div>
            <div className={styles.titleWrapper}>
              <h3 id="transition-modal-title">You are about to</h3>
              <h3 id="transition-modal-subtitle">
                reset your reading settings
              </h3>
            </div>
            <div className={styles.actions} id="transition-modal-description">
              <div className={styles.buttonWrapperMargin}>
                <ButtonComponent onClick={handleResetAll} variant="ghost">
                  <p>Reset All</p>
                </ButtonComponent>
              </div>
              <div className={styles.buttonWrapperMargin}>
                <ButtonComponent
                  onClick={handleResetPartly}
                  variant="secondary"
                >
                  <p> Reset {subMode}</p>
                </ButtonComponent>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  };

  return (
    <div className={styles.undoResetButtons}>
      <IconButton disabled={settingsStates.length === 1} onClick={handleUndo}>
        <Undo />
        <span>undo</span>
      </IconButton>
      <hr />
      <Button onClick={handleResetOpen} className={styles.resetButton}>
        Reset
      </Button>
      <ResetModal />
    </div>
  );
};

export default UndoReset;
