import React, { useEffect, useRef, useState } from 'react';
import styles from './BasePage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  paragraphsListThunk,
  ParagraphType,
  setSelectedParagraph,
  setChapterOrParagraph
} from '../../redux/Page';
import { handlerCSS } from '../../helpers/cssHelper';
import { setPageNumber } from '../../redux/Page';
import { useFocus, useScrollPosition } from '../../helpers/customHooks';
import SelectedTextMenu from '../SelectedTextMenu/SelectedTextMenu';
import SelectedMenu from '../SelectedMenu/SelectedMenu';
import { StateType } from '../../redux/store';
import { useLocation, useHistory } from 'react-router-dom';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { ReactComponent as Focus } from '../../assets/images/FocusIcon.svg';
import { ReactComponent as Home } from '../../assets/images/HomeIcon.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import { Backdrop, IconButton, Modal, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  setFocusModeSettings,
  resetFocusModeSettings,
  setMode,
  setSettings,
  ToolbarItem
} from '../../redux/Reader';
import Fade from '@material-ui/core/Fade';
import FocusModeTools from '../common/FocusModeTools/FocusModeTools';
import FooterAudio from '../FooterAudio/FooterAudio';
import YouTube from 'react-youtube';
import { chaptersListThunk } from '../../redux/Chapters';
import RulerArrow from '../RulerArrow/RulerArrow';
import { isTabletWidth } from '../../helpers/constants';

const queryString = require('query-string');

const initialSelectedMenuState = {
  mouseX: null,
  mouseY: null
};
let rulerTopHeight = 0;
let rulerBottomHeight = 0;

const videoOpts: any = {
  height: '360',
  width: '640',
  playerVars: {
    autoplay: 1
  }
};

const BasePage = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { reader, chapters, page, home } = useSelector(
    (state: StateType) => state
  );

  const [selectedMenuState, setSelectedMenuState] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialSelectedMenuState);

  const pagePrev: any = useRef(null);
  const pageNext: any = useRef(null);

  const query = queryString.parse(useLocation().search);

  const [fullScreen, setFullscreen] = useState(false);
  const [chosenClass, setChosenClass] = useState<any>({});
  const [modeClass, setModeClass] = useState<any>({});
  const [paragraph, setParagraph] = useState<any>(page.paragraphs[0]);
  const [isChapter, setIsChapter] = useState<any>(false);
  const [start, setStart] = useState(false);
  const [pause, setPause] = useState(false);
  const [openVideoPopup, setOpenVideoPopup] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [focusContinueOpen, setFocusContinueOpen] = useState(false);

  useEffect(() => {
    if (reader.mode === 'reading' || reader.mode === 'focus') {
      setModeClass({});

      handlerCSS({
        fullScreen:
          reader.settings.ruler_top || reader.settings.ruler_bottom
            ? 'auto'
            : '3'
      });
    } else if (reader.mode === 'sound' || reader.mode === 'video') {
      setModeClass(styles[reader.mode]);
    }
  }, [reader.mode]);

  useEffect(() => {
    if (reader.settings.ruler_top || reader.settings.ruler_bottom) {
      rulerTopHeight = 0;
      rulerBottomHeight = 0;
    }
    // document
    //   .getElementsByTagName('header')[0]
    //   .scrollIntoView({ block: 'start' });
  }, [page.pageNumber]);

  useEffect(() => {
    if (page.paragraphs.length) {
      document
        .getElementsByTagName('header')[0]
        .scrollIntoView({ block: 'start' });
    }
  }, [page.paragraphs]);

  useEffect(() => {
    if (
      (reader.settings.ruler_top || reader.settings.ruler_bottom) &&
      page.paragraphs.length &&
      reader.settingsLoaded
    ) {
      handlerCSS({ fullScreen: 'auto' });
      const headerHeight = document.getElementsByTagName('header')[0]
        .offsetHeight;
      let paragraphIndex = 0;
      const paragraph = document.getElementById(
        `paragraph-${
          page.paragraphs[0].is_chapter_title
            ? page.paragraphs[++paragraphIndex].id
            : page.paragraphs[paragraphIndex].id
        }`
      );
      if (paragraph) {
        const rulerDrag = document.getElementsByTagName('main')[0];
        const arrowDrag = document.getElementById('arrow');
        // const hasTopRuler = window.getComputedStyle(rulerDrag,':before')['content'] != 'none';
        // const hasBottomRuler = window.getComputedStyle(rulerDrag,':after')['content'] != 'none';
        rulerDrag.onmousedown = function(e: any) {
          //console.log(window.getComputedStyle(e?.target, ':before')['cursor']);
          //if(e?.target?.localName === 'main'){
          let moved = false;
          handlerCSS({ rulerCursor: 'grabbing' });
          document.onmousemove = function(e) {
            moved = true;
            rulerTopHeight = rulerTopHeight + e.movementY;
            rulerBottomHeight = rulerBottomHeight + e.movementY;

            handlerCSS({ rulerTopHeight: rulerTopHeight + 'px' });
            handlerCSS({ rulerBottomHeight: rulerBottomHeight + 'px' });
          };
          document.onmouseup = function(e) {
            handlerCSS({ rulerCursor: 'grab' });
            if (!moved) {
              const mousePositionCorrection =
                // @ts-ignore
                e.layerY +
                headerHeight -
                Number(reader.settings.line_height) / 2;
              const elements = document.elementsFromPoint(e.clientX, e.clientY);
              if (
                (elements[0].tagName === 'MAIN' &&
                  elements[1].tagName === 'DIV') ||
                elements[1].tagName === 'MAIN' ||
                elements[1].tagName === 'P'
              ) {
                rulerTopHeight = mousePositionCorrection;
                rulerBottomHeight =
                  rulerTopHeight -
                  headerHeight +
                  Number(reader.settings.line_height);
                handlerCSS({ rulerTopHeight: rulerTopHeight + 'px' });
                handlerCSS({ rulerBottomHeight: rulerBottomHeight + 'px' });
              }
            }
            document.onmousemove = null;
            rulerDrag.onmouseup = null;
          };
        };

        if (!isTabletWidth) {
          //@ts-ignore
          arrowDrag.onmousedown = function(e: any) {
            //console.log(window.getComputedStyle(e?.target, ':before')['cursor']);
            //if(e?.target?.localName === 'main'){
            let moved = false;
            handlerCSS({ rulerCursor: 'grabbing' });
            document.onmousemove = function(e) {
              moved = true;
              rulerTopHeight = rulerTopHeight + e.movementY;
              rulerBottomHeight = rulerBottomHeight + e.movementY;

              handlerCSS({
                rulerTopHeight: rulerTopHeight + 'px'
              });
              handlerCSS({
                rulerBottomHeight: rulerBottomHeight + 'px'
              });
            };
            document.onmouseup = function(e) {
              handlerCSS({ rulerCursor: 'grab' });
              if (!moved) {
                const mousePositionCorrection =
                  // @ts-ignore
                  e.layerY +
                  headerHeight -
                  Number(reader.settings.line_height) / 2;
                const elements = document.elementsFromPoint(
                  e.clientX,
                  e.clientY
                );
                if (
                  (elements[0].tagName === 'MAIN' &&
                    elements[1].tagName === 'DIV') ||
                  elements[1].tagName === 'MAIN' ||
                  elements[1].tagName === 'P'
                ) {
                  rulerTopHeight = mousePositionCorrection;
                  rulerBottomHeight =
                    rulerTopHeight -
                    headerHeight +
                    Number(reader.settings.line_height);
                  handlerCSS({
                    rulerTopHeight: rulerTopHeight + 'px'
                  });
                  handlerCSS({
                    rulerBottomHeight: rulerBottomHeight + 'px'
                  });
                }
              }
              document.onmousemove = null;
              //@ts-ignore
              arrowDrag.onmouseup = null;
            };
          };
        }
        //}
        rulerTopHeight = rulerTopHeight
          ? rulerTopHeight
          : paragraph.offsetTop + headerHeight;
        handlerCSS({ rulerTopHeight: rulerTopHeight + 'px' });
        rulerBottomHeight =
          rulerTopHeight - headerHeight + Number(reader.settings.line_height);
        handlerCSS({ rulerBottomHeight: rulerBottomHeight + 'px' });
      }
    }

    return () => {
      document.onwheel = null;
    };
  }, [reader.settings, reader.settingsLoaded, page.paragraphs]);

  useEffect(() => {
    dispatch(setPageNumber(query.page));
    // @ts-ignore
    dispatch(paragraphsListThunk(query.bookId, query.page)).then(() => {
      setImageLoaded(false);
    });
    dispatch(chaptersListThunk(query.bookId));
    return () => {
      dispatch(setPageNumber(0));
      // dispatch(resetPage());
    };
  }, [dispatch, query.bookId, query.page]);

  useEffect(() => {
    if (page.pageNumber > 0 && page.pageNumber !== query.page) {
      props.history.push({
        pathname: '/reader',
        search: `?bookId=${query.bookId}&page=${page.pageNumber}`
      });
    }
  }, [dispatch, page.pageNumber]);

  useEffect(() => {
    setSelectedMenuState(initialSelectedMenuState);
    if (reader.mode == 'focus') {
      const keydownListener = (e: KeyboardEvent) => {
        if (e.key === 'ArrowRight') {
          pageNext.current.click();
        }
        if (e.key === 'ArrowLeft') {
          pagePrev.current.click();
        }
      };
      document.addEventListener('keydown', keydownListener);
      return () => {
        document.removeEventListener('keydown', keydownListener);
      };
    } else if (reader.mode === 'reading') {
      const ref = mainRef && mainRef.current;
      const keydownListener = (e: KeyboardEvent) => {
        if (e.key === 'ArrowUp') {
          ref && ref.focus();
        }
        if (e.key === 'ArrowDown') {
          ref && ref.focus();
        }
      };
      document.addEventListener('keydown', keydownListener);
      return () => {
        document.removeEventListener('keydown', keydownListener);
      };
    }
  }, [reader.mode]);

  useEffect(() => {
    if (
      reader.mode === 'focus' &&
      reader.focusModeSettings.type == 'timed' &&
      reader.focusModeSettings.status == 'inProcess'
    ) {
      let i = 1;
      const focusTime = reader.focusModeSettings.timeMinutes * 60;
      const intervalId = setInterval(() => {
        if (i === focusTime) {
          dispatch(setFocusModeSettings({ type: 'timed', status: 'timeOver' }));
          handleFocusContinueOpen();
        }
        i++;
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [reader.focusModeSettings]);

  useEffect(() => {
    if (page.chosenParagraphId === 0) {
      setStart(false);
    }
  }, [page.chosenParagraphId]);

  const mainRef = useRef<HTMLElement>(null);
  useFocus(mainRef);

  useEffect(() => {
    if (reader.settingsLoaded && !reader.settings.tour_watched) {
      setOpenVideoPopup(true);
    }
  }, [reader.settings, reader.settingsLoaded]);

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    const selection = window.getSelection();
    if (selection && selection.isCollapsed) {
      // @ts-ignore
      selection.modify('move', 'forward', 'character');
      // @ts-ignore
      selection.modify('move', 'backward', 'word');
      // @ts-ignore
      selection.modify('extend', 'forward', 'word');
    }

    if (
      selection &&
      selection.focusNode &&
      selection.focusNode.nodeName === '#text' &&
      selection.toString().trim().length
    ) {
      setSelectedMenuState({
        mouseX: event.clientX - 20,
        mouseY: event.clientY + 22
      });
    } else {
      setSelectedMenuState(initialSelectedMenuState);
    }
  };
  const handleAudioVideo = (
    event: React.MouseEvent<HTMLDivElement>,
    id: number
  ) => {
    setPause(true);
    event.preventDefault();
    dispatch(setSelectedParagraph(id));
    setSelectedMenuState({
      mouseX: event.clientX - 20,
      mouseY: event.clientY + 22
    });
  };

  const handlePrevNext = (sign: string) => {
    let currentValue = Number(page.pageNumber);
    sign === '+' ? currentValue++ : currentValue--;
    if (currentValue >= 1 && currentValue <= home.currentBook.pages_count) {
      dispatch(setPageNumber(currentValue));
    }
    if (reader.mode === 'sound' || reader.mode === 'video') {
      dispatch(setSelectedParagraph(0));
      dispatch(setChapterOrParagraph(''));
      setStart(false);
    }

    pagePrev.current.blur();
    pageNext.current.blur();
  };

  const handleFocusContinueOpen = () => {
    setFocusContinueOpen(true);
  };

  const handleFocusContinueClose = () => {
    setFocusContinueOpen(false);
  };

  const handleCloseFocusMode = () => {
    dispatch(setMode('reading'));
    dispatch(resetFocusModeSettings());
  };

  const handleCloseAudionMode = () => {
    setStart(false);
    dispatch(setMode('reading'));
  };

  handlerCSS({ chapterFlagColor: chapters.currentChapter.color });

  const readerSettingsCss = {
    fontFamily: reader.settings.font ? reader.settings.font.name : '',
    fontSize: reader.settings.font_size + 'px',
    fontColor: reader.settings.font_color,
    fontWeight: reader.settings.font_weight,
    wordSpacing: reader.settings.word_spacing + 'px',
    paragraphSpacing: reader.settings.paragraph_spacing + 'px',
    lineHeight: reader.settings.line_height + 'px',
    backgroundColor: reader.settings.background_color,
    pageBoxShadow: reader.settings.wallpaper_on
      ? '0px 5px 15px rgba(0, 0, 0, 0.1)'
      : 'none',
    backgroundImage: reader.settings.background_image,
    rulerTopColor: reader.settings.ruler_top_color,
    rulerTopOpacity: reader.settings.ruler_top_opacity,
    rulerBottomColor: reader.settings.ruler_bottom_color,
    rulerBottomOpacity: reader.settings.ruler_bottom_opacity
  };
  handlerCSS(readerSettingsCss);

  const highlightHandler = (id: number) => {
    if (id === page.chosenParagraphId && reader.mode === 'video') {
      setChosenClass(styles.chosen);
    } else if (id === page.chosenParagraphId && reader.mode === 'sound') {
      setChosenClass(styles.chosen);
    }
  };

  const paragraphPlay = (id: number) => {
    const selected = page.paragraphs.find(el => el.id === id);

    setParagraph({ ...selected });
    setIsChapter(false);
    setStart(true);
    setPause(false);
    dispatch(setChapterOrParagraph('paragraph'));
  };
  const chapterPlay = (id: number) => {
    const selected = page.paragraphs.find(el => el.id === id);

    setParagraph({ ...selected });
    setIsChapter(true);
    setStart(true);
    setPause(false);
    dispatch(setChapterOrParagraph('chapter'));
  };

  const closeVideoPopup = () => {
    setOpenVideoPopup(false);
    dispatch(setSettings({ tour_watched: 1 }));
  };

  if (reader.mode === 'focus') {
    handlerCSS({ wallpaperColor: '#091626' });
  }

  const goHome = () => {
    history.push('/');
  };

  const onTopArrowClick = () => {
    if (isTabletWidth) {
      rulerTopHeight = rulerTopHeight - Number(reader.settings.line_height);
      rulerBottomHeight =
        rulerBottomHeight - Number(reader.settings.line_height);

      handlerCSS({ rulerTopHeight: rulerTopHeight + 'px' });
      handlerCSS({ rulerBottomHeight: rulerBottomHeight + 'px' });
    }
  };

  const onBottomArrowClick = () => {
    if (isTabletWidth) {
      rulerTopHeight = rulerTopHeight + Number(reader.settings.line_height);
      rulerBottomHeight =
        rulerBottomHeight + Number(reader.settings.line_height);

      handlerCSS({ rulerTopHeight: rulerTopHeight + 'px' });
      handlerCSS({
        rulerBottomHeight: rulerBottomHeight + 'px'
      });
    }
  };

  const replaceUCWithThumbnail = (url: string) => {
    return `${url.replace('uc?', 'thumbnail?')}&sz=w1000`;
  };

  return (
    <main
      id="main"
      ref={mainRef}
      className={`
        ${styles.bookPage}
        ${
          (reader.settings.ruler_top || reader.settings.ruler_bottom) &&
          reader.mode !== 'sound' &&
          reader.mode != 'video'
            ? styles.ruler
            : ''
        }
        ${reader.settings.ruler_top ? styles.rulerTop : ''}
        ${reader.settings.ruler_bottom ? styles.rulerBottom : ''}
        ${reader.mode === 'focus' ? styles.focusMode : ''}
        ${reader.mode === 'video' && start ? styles.videoFullPage : ''}
        `}
      tabIndex={1}
      onContextMenu={(e: React.MouseEvent<HTMLDivElement>) =>
        reader.mode === 'reading' && handleRightClick(e)
      }
    >
      {(reader.settings.ruler_top || reader.settings.ruler_bottom) &&
      reader.mode !== 'sound' &&
      reader.mode != 'video' ? (
        <RulerArrow
          onTopArrowClick={onTopArrowClick}
          onBottomArrowClick={onBottomArrowClick}
        />
      ) : null}
      <IconButton className={styles.homeMobileButton} onClick={goHome}>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          open={!start && reader?.openTooltipMobile}
          classes={{
            popper: styles.popper,
            popperArrow: styles.popperArrow,
            tooltip: styles.tooltip,
            arrow: styles.arrow
          }}
          title={'Home'}
          placement="right"
          arrow
        >
          <Home />
        </Tooltip>
      </IconButton>
      <div>
        {reader.mode === 'focus' && (
          <div className={styles.focusTopControls}>
            <IconButton
              className={styles.focusCloseButton}
              onClick={handleCloseFocusMode}
            >
              <CancelIcon />
            </IconButton>
            <Focus className={styles.focusIcon} />
            <div className={styles.text}>Focus mode: Active</div>
          </div>
        )}
        {/* <div className={styles.focusArrowsControls}>
            <div className={styles.prevBlock}>
              <IconButton
                ref={pagePrev}
                className={styles.prevNextButton}
                onClick={() => handlePrevNext('-')}
              >
                <NavigateBeforeIcon />
              </IconButton>
            </div>
            <div> </div>
            <div className={styles.nextBlock}>
              <IconButton
                ref={pageNext}
                className={styles.prevNextButton}
                onClick={() => handlePrevNext('+')}
              >
                <NavigateNextIcon />
              </IconButton>
            </div>
          </div> */}
        {reader.mode === 'focus' &&
          reader.focusModeSettings.status === 'timeOver' && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={styles.focusModal}
              open={focusContinueOpen}
              onClose={handleFocusContinueClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={focusContinueOpen}>
                <FocusModeTools
                  focusType={'modal'}
                  handleCloseFocusMode={handleCloseFocusMode}
                />
              </Fade>
            </Modal>
          )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={styles.videoModal}
          open={openVideoPopup}
          onClose={closeVideoPopup}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={openVideoPopup}>
            <div className={styles.videoModalContent}>
              <IconButton
                className={styles.closeButton}
                onClick={() => closeVideoPopup()}
              >
                <CancelIcon />
              </IconButton>
              <YouTube
                videoId={'gIMTIIDIzUo'}
                opts={videoOpts}
                className={styles.videoPlayer}
              />
            </div>
          </Fade>
        </Modal>
      </div>
      <div className={styles.contentBlock}>
        <div className={styles.chapterFlag}>
          <div className={styles.chapterArrowWrapper}>
            <div className={styles.chapterArrowRight} />
            <div className={styles.chapterArrowLeft} />
          </div>
        </div>
        <div className={styles.chapter}>
          <span>{home.currentBook.name}</span>
        </div>
        {reader.settingsLoaded &&
          page.paragraphs.map((el: ParagraphType, index: number) => {
            return (
              <div key={el.id}>
                {el.image_url ? (
                  <div
                    style={{
                      textAlign: 'center',
                      marginBottom: 10,
                      minHeight: 100
                    }}
                  >
                    <img
                      className={styles.image}
                      style={{ display: imageLoaded ? 'initial' : 'none' }}
                      src={replaceUCWithThumbnail(el.image_url)}
                      onLoad={() => {
                        setImageLoaded(true);
                      }}
                    />
                    {!imageLoaded ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%'
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                <div
                  id={`paragraph-${el.id}`}
                  className={`${
                    el.is_chapter_title
                      ? styles.chapterTitle
                      : index === 0
                      ? styles.paragraphTitle
                      : styles.paragraph
                  } 
                    ${el.image_url ? styles.imageCaption : ''} 
                    ${modeClass} 
                    ${el.id === page.chosenParagraphId && styles.chosen}`}
                  key={el.id}
                  dangerouslySetInnerHTML={{ __html: el.paragraph }}
                  // onClick={() => highlightHandler(el.id)}
                  onClick={e =>
                    (reader.mode === 'sound' && handleAudioVideo(e, el.id)) ||
                    (reader.mode === 'video' && handleAudioVideo(e, el.id))
                  }
                />
              </div>
            );
          })}
      </div>

      {reader.mode === 'reading' && (
        <SelectedTextMenu
          initialState={initialSelectedMenuState}
          state={selectedMenuState}
        />
      )}
      {(reader.mode === 'sound' || reader.mode === 'video') && (
        <SelectedMenu
          initialState={initialSelectedMenuState}
          state={selectedMenuState}
          paragraph={() => paragraphPlay(page.chosenParagraphId)}
          chapter={() => chapterPlay(page.chosenParagraphId)}
        />
      )}
      {reader.mode === 'video' && start && (
        <VideoPlayer
          setFullscreen={setFullscreen}
          fullScreen={fullScreen}
          paragraph={paragraph}
          closeVideo={() => {
            dispatch(setSelectedParagraph(0));
            setStart(false);
          }}
          chapterPlay={(el: number) => {
            dispatch(setSelectedParagraph(el));
          }}
          isChapter={isChapter}
          isPageEnded={(id: number) => {
            const lastEl = page.paragraphs[page.paragraphs.length - 1];
            if (id === lastEl.id + 1) {
              dispatch(setPageNumber(lastEl.page + 1));
            }
          }}
          playerHide={() => {
            setStart(false);
            setFullscreen(false);
          }}
          pause={pause}
        />
      )}
      {reader.mode === 'sound' && (
        <FooterAudio
          paragraph={paragraph}
          closeAudio={handleCloseAudionMode}
          chapterPlay={(el: number) => {
            dispatch(setSelectedParagraph(el));
          }}
          isChapter={isChapter}
          isPageEnded={(id: number) => {
            const lastEl = page.paragraphs[page.paragraphs.length - 1];
            if (id === lastEl.id + 1) {
              dispatch(setPageNumber(lastEl.page + 1));
            }
          }}
          pause={pause}
          start={start}
          playerHide={() => setStart(false)}
        />
      )}
    </main>
  );
};

export default BasePage;
