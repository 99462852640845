import {
  instance,
} from './api';

type BookResponseType = {

};

export const chaptersAPI = {
  getChaptersList(bookId:number) {
    return instance.get('chapters', {
      params: {
        book_id: bookId
      }
    });
  },
  getChapterDetails(chapterId:number) {
    return instance.get(`chapters/${chapterId}`);
  },
};
