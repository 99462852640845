export const tts = (text:string) => {
  window.speechSynthesis.cancel();
  let mainVoice = window.speechSynthesis.getVoices().filter(function(voice) {
    return voice.lang === 'en';
  })[0];

  let utterance = new SpeechSynthesisUtterance(text);

  // utterance.voice = mainVoice;
  utterance.pitch = 1;
  utterance.rate = 1;
  utterance.volume = 1;

  speechTimeout = setTimeout(myTimer, 10000);
  utterance.onend = () => { clearTimeout(speechTimeout) }
  window.speechSynthesis.speak(utterance);

}

let speechTimeout: NodeJS.Timeout;
function myTimer() {
  window.speechSynthesis.pause();
  window.speechSynthesis.resume();
  speechTimeout = setTimeout(myTimer, 10000);
}
