import {
  ResponseType, instance,
} from './api';

type ProfileResponseType = {
  userId: number;
};

export const userAPI = {
  profile() {
    return instance.get<ResponseType<ProfileResponseType>>('auth/user-profile');
  },
};
