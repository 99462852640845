import React, { useEffect, useState } from 'react';
import styles from './SelectedMenu.module.scss';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { tts } from '../../intergations/tts';
import { withStyles } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Divider } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { getWordThunk, resetDictionary } from '../../redux/Dictionary';
import { StateType } from '../../redux/store';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import CancelIcon from '@material-ui/icons/Cancel';
import { alertShowThunk } from '../../redux/Alert';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import NoteIcon from '@material-ui/icons/Note';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPopover-paper': {
        width: '230px',
        height: '200px',
        borderRadius: '15px',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    typography: {
      padding: theme.spacing(2),
    },
  })
);

const initialPopoverState = {
  mouseX: null,
  mouseY: null,
  word: null,
};

const SelectedMenu = (props: any) => {
  const dispatch = useDispatch();
  const { dictionary } = useSelector((state: StateType) => state);

  const [selectedMenuState, setSelectedMenuState] = useState(
    props.initialState
  );
  const [popover, setPopover] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
    word: null | string;
  }>(initialPopoverState);

  const classes = useStyles();

  useEffect(() => {
    setSelectedMenuState(props.state);
  }, [props.state]);

  const handleClose = () => {
    setSelectedMenuState(props.initialState);
  };

  const handlePopoverClose = () => {
    dispatch(resetDictionary());
    setPopover(initialPopoverState);
  };

  const handleParagraph = () => {
    props.paragraph();
    handleClose();
  };

  const handleChapter = () => {
    props.chapter();
    handleClose();
  };

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: '#f7f4ec',
      overflow: 'visible',
      color: '#191818',
      borderRadius: '5px',
      boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.12)',
      '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& li:hover': {
        backgroundColor: '#eeeeed',
        borderRadius: '5px',
      },
    },
  })(Menu);

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#191818',
        minWidth: '36px',
      },
    },
  }))(MenuItem);

  return (
    <div>
      <StyledMenu
        className={styles.contextMenu}
        keepMounted
        open={selectedMenuState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          selectedMenuState.mouseY !== null && selectedMenuState.mouseX !== null
            ? { top: selectedMenuState.mouseY, left: selectedMenuState.mouseX }
            : undefined
        }
      >
        <div className={styles.arrow}> </div>
        <StyledMenuItem onClick={handleParagraph}>
          <ListItemIcon>
            <NoteIcon fontSize="small" />
          </ListItemIcon>
          Paragraph
        </StyledMenuItem>
        <Divider className={styles.divider} />
        <StyledMenuItem onClick={handleChapter}>
          <ListItemIcon>
            <LocalLibraryIcon fontSize="small" />
          </ListItemIcon>
          Chapter
        </StyledMenuItem>
      </StyledMenu>

      <Backdrop
        className={classes.backdrop}
        open={popover.mouseY !== null}
        onClick={handleClose}
      ></Backdrop>
    </div>
  );
};

export default SelectedMenu;
