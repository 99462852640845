import React from 'react';
import styles from './FontsList.module.scss';
import { Select, MenuItem } from '@material-ui/core';
import { ToolbarStateType } from '../../../redux/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../redux/store';
import { setSettings } from '../../../redux/Reader';
import { ReactComponent as LeftArrow } from '../../../assets/images/LeftArrow.svg';

const FontsList: React.FC<ToolbarStateType> = ({ fonts }) => {
  const dispatch = useDispatch();
  const { font } = useSelector((state: StateType) => state.reader.settings);

  const handleChange = (
    event: React.ChangeEvent<{ value: string | unknown }>
  ) => {
    const newFont = fonts.find(
      (element, index, array) => element.id === event.target.value
    );

    dispatch(setSettings({ font: newFont ? newFont : font }));
    dispatch(setSettings({ font_id: newFont ? newFont.id : font.id }));
  };

  return (
    <div className={styles.wrapper}>
      <Select
        labelWidth={100}
        value={font.id}
        onChange={handleChange}
        IconComponent={() => (
          <div className={styles.arrowWrapper}>
            <LeftArrow />
          </div>
        )}
      >
        {fonts.map((el: any) => (
          <MenuItem
            style={{ fontFamily: el.name }}
            key={`${el.id}`}
            value={el.id}
          >
            {el.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default FontsList;
