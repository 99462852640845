import React from 'react';
import { useDispatch } from 'react-redux';
import { setSettings } from '../../../redux/Reader';

const FontWeightPicker = () => {
  const dispatch = useDispatch();

  const handleClick = (value: any) => {
    dispatch(setSettings({ font_weight: value }));
  };

  return (
    <div>
      <span onClick={() => handleClick(200)}>Aa</span>
      <span onClick={() => handleClick(400)}>Aa</span>
      <span onClick={() => handleClick(600)}>Aa</span>
    </div>
  );
};

export default FontWeightPicker;
