import {
  instance,
} from './api';

type BookResponseType = {

};

export const bookContentAPI = {
  getList(bookId:number, page:number) {
    return instance.get('book-content',{
      params: {
        book_id: bookId,
        page: page
      }
    });
  },
};
