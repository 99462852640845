import React, { useEffect, useState } from 'react';
import { ReactComponent as Home } from '../../assets/images/HomeIcon.svg';
import { ReactComponent as Chapters } from '../../assets/images/ChapterIcon.svg';
import { ReactComponent as Reading } from '../../assets/images/ReadingIcon.svg';
import { ReactComponent as Video } from '../../assets/images/VideoIcon.svg';
import { ReactComponent as Sound } from '../../assets/images/SoundIcon.svg';
import { ReactComponent as Notes } from '../../assets/images/notes.svg';
import { ReactComponent as Focus } from '../../assets/images/FocusIcon.svg';
import { ReactComponent as Streamline } from '../../assets/images/streamline.svg';
import { ReactComponent as Ruler } from '../../assets/images/RulerIcon.svg';
import { ReactComponent as Menu } from '../../assets/images/toolsMenu.svg';

import ButtonPopup from '../common/ButtonPopup/ButtonPopup';
import { setMode, ToolbarItem, setOpenTooltipMobile } from '../../redux/Reader';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../redux/store';
import { NavLink } from 'react-router-dom';
import ToolbarButton from '../common/ToolbarButton/ToolbarButton';
import { getFontsThunk } from '../../redux/Toolbar';
import { alertShowThunk } from '../../redux/Alert';

import { handlerCSS } from '../../helpers/cssHelper';

import styles from './Toolbar.module.scss';

const icons: any = {
  home: <Home />,
  chapters: <Chapters />,
  reading: <Reading />,
  video: <Video />,
  sound: <Sound />,
  ruler: <Ruler />,
  notes: <Notes />,
  focus: <Focus />,
  streamline: <Streamline />,
  menu: <Menu />
};

type PropsType = {};

const Toolbar: React.FC<PropsType> = () => {
  const dispatch = useDispatch();
  const { fonts } = useSelector((state: StateType) => state.toolbar);
  const { reader, page, home } = useSelector((state: StateType) => state);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    dispatch(setOpenTooltipMobile(isMenuOpen));
  }, [isMenuOpen]);

  useEffect(() => {
    if (!fonts.length) {
      dispatch(getFontsThunk());
    }
  }, [dispatch]);

  handlerCSS({ asideZindex: '3' });

  const setModeVariant = (name: string) => {
    if (
      name === 'video' &&
      (page.paragraphs.length === 0 || !page.paragraphs[0].video_file)
    ) {
      dispatch(
        alertShowThunk(
          'warning',
          'Sorry, video for this chapter is not available yet'
        )
      );
    } else if (
      name === 'sound' &&
      (page.paragraphs.length === 0 || !page.paragraphs[0].audio_file)
    ) {
      dispatch(
        alertShowThunk(
          'warning',
          'Sorry, audio for this chapter is not available yet'
        )
      );
    } else if (name !== 'focus') {
      dispatch(setMode(name));
    }
  };

  return (
    <aside className={styles.aside}>
      <div className={styles.toolbar}>
        <div
          className={`${styles.buttonWrapper} ${
            styles.homeWrapper
          } ${!isMenuOpen && styles.hidedButtonWrapper}`}
        >
          {reader.menu.map((el: ToolbarItem) => (
            <NavLink
              key={el.id}
              to={`${
                el.name === 'chapters'
                  ? `/${el.url}?bookId=${home.currentBook.id}`
                  : `/?back=${true}`
              }`}
              activeClassName={styles.activeLink}
            >
              <ToolbarButton
                active={false}
                key={el.id}
                imageName={el.title}
                child={icons[el.name]}
                setButton={(name: string) => {}}
                open={isMenuOpen}
              />
            </NavLink>
          ))}
        </div>
        <div
          className={`${styles.buttonWrapper} ${!isMenuOpen &&
            styles.hidedButtonWrapper}`}
        >
          {reader.toolbar.map((el: ToolbarItem) => (
            <ButtonPopup
              isHideActiveArrow={
                reader.mode === 'video' || reader.mode === 'sound'
              }
              key={el.id}
              el={el}
              child={icons[el.name]}
              childBlue={icons[el.name + 'Blue']}
              someFunction={() => {
                setIsMenuOpen(false);
                setModeVariant(el.name);
              }}
              isActive={reader.mode === el.name}
              tooltipOpen={isMenuOpen}
            />
          ))}
        </div>
        <div
          className={`${styles.buttonWrapper} ${!isMenuOpen &&
            styles.hidedButtonWrapper}`}
        >
          <a
            href="https://www.youtube.com/playlist?list=PLWHrHZILAGdQl8jnjklPmYfgi-K7sIyNE"
            target="_blank"
            className={styles.tourButton}
            rel="noreferrer"
          >
            <ToolbarButton
              key={'tour'}
              imageName={'Help'}
              child={icons['streamline']}
              setButton={(name: string) => {}}
            />
          </a>
        </div>
      </div>
      <div
        className={`${styles.mobileMenu} ${isMenuOpen &&
          styles.mobileMenuActive} ${reader?.mode === 'sound' &&
          styles.mobileHidePagination}`}
      >
        <ToolbarButton
          key={'menu'}
          imageName={'Menu'}
          child={icons['menu']}
          setButton={(name: string) => {
            setIsMenuOpen(value => !value);
          }}
        />
      </div>
    </aside>
  );
};

export default Toolbar;
