import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authAPI } from '../api/auth-api';
import { userAPI } from '../api/user-api';
import { alertShowThunk } from './Alert';
import { DispatchType } from './store';

export type UserType = {
  id: number;
  firstname: string;
  lastname: string;
  where_studying: string;
  updated_at: string;
  email: string;
  email_verified_at: string | null;
  is_admin: boolean;
  created_at: string;
};

type AuthStateType = {
  login: any;
  register: any;
  restore: any;
  userSession: UserSessionType;
  validation: any;
};

export type UserSessionType = {
  access_token: string;
  expires_in: number;
  token_type: string;
  user: UserType;
};

type AuthError = {
  isError: boolean;
  message: string;
};

// @ts-ignore
let userSession = JSON.parse(localStorage.getItem('userSession'));

const defaultState: AuthStateType = {
  userSession: userSession,
  login: {},
  register: {},
  restore: {
    email: '',
    inProgress: false,
    didReset: false,
  },
  validation: {
    isError: false,
    errors: {
      firstname: '',
      lastname: '',
      email: '',
      where_studying: '',
      password: '',
      password_confirmation: '',
      credentials: '',
    },
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.userSession = action.payload.data;
      localStorage.setItem('userSession', JSON.stringify(state.userSession));
    },
    profile: (state, action: PayloadAction<any>) => {
      state.userSession = action.payload.data;
    },
    authError: (state, action: PayloadAction<any>) => {
      state.validation.isError = true;
      state.validation.errors = action.payload.data;
    },
    logoutUser: (state) => {
      localStorage.removeItem('userSession');
      state.userSession = {} as UserSessionType;
    },
    passwordEmail: (state, action: PayloadAction<any>) => {
      state.restore.inProgress = true;
    },
    passwordReset: (state, action: PayloadAction<any>) => {
      state.restore.didReset = true;
    },
    clearErrors: (state) => {
      state.validation.errors = '';
    },
  },
});

const authReducer = authSlice.reducer;

export const {
  setUser,
  profile,
  authError,
  logoutUser,
  passwordEmail,
  clearErrors,
} = authSlice.actions;
export default authReducer;

export const loginThunk =
  (email: string, password: string) => async (dispatch: DispatchType) => {
    try {
      const authDetails = await authAPI.login(email, password);
      dispatch(setUser(authDetails));
    } catch (err) {
      // @ts-ignore
      dispatch(authError(err.response));
    }
  };

export const registerThunk =
  (data: object) => async (dispatch: DispatchType) => {
    try {
      const authDetails = await authAPI.register(data);
      dispatch(setUser(authDetails));
      // @ts-ignore
      dispatch(alertShowThunk('success', authDetails.data.message));
    } catch (err) {
      // @ts-ignore
      dispatch(authError(err.response));
    }
  };

export const profileThunk = () => async (dispatch: DispatchType) => {
  try {
    const userDetails = await userAPI.profile();
    dispatch(profile(userDetails));
  } catch (err) {
    // @ts-ignore
    dispatch(profile(err.response));
  }
};

export const logoutThunk = () => async (dispatch: DispatchType) => {
  localStorage.clear();
  dispatch(logoutUser());
};

export const passwordEmailThunk =
  (email: string) => async (dispatch: DispatchType) => {
    try {
      const passwordEmailResponse = await authAPI.passwordEmail(email);
      dispatch(passwordEmail(passwordEmailResponse));
      // @ts-ignore
      dispatch(alertShowThunk('success', passwordEmailResponse.data.message));
    } catch (err) {
      // @ts-ignore
      dispatch(authError(err.response));
    }
  };

export const passwordResetThunk =
  (data: object) => async (dispatch: DispatchType) => {
    try {
      const passwordResetResponse = await authAPI.passwordReset(data);
      // @ts-ignore
      dispatch(loginThunk(data.email, data.password));
      // @ts-ignore
      dispatch(alertShowThunk('success', passwordResetResponse.data.message));
    } catch (err) {
      // @ts-ignore
      dispatch(authError(err.response));
    }
  };
