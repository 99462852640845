import React, { Component } from 'react';
import styles from './Profile.module.scss';
import { bindActionCreators } from 'redux';
import { DispatchType, StateType } from '../../redux/store';
import { connect } from 'react-redux';
import { PropsType } from '../../helpers/typeHelpers';

class Profile extends Component<PropsType> {
  render() {
    return (
      <div className={styles.wrapper}>
        <main>
          <h3>Some profile will be here</h3>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state: StateType) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
