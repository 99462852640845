import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import styles from './Register.module.scss';
import { connect } from 'react-redux';
import { DispatchType, StateType } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import ResetPassword from '../../components/ResetPassword/ResetPassword';
import ResetEmail from '../../components/ResetEmail/ResetEmail';
import RegisterMain from '../../components/RegisterMain/RegisterMain';
import Layout from '../../components/Layout/Layout';

import { clearErrors } from '../../redux/Auth';

export const Register = (props: any) => {
  const auth = useSelector((state: StateType) => state.auth);
  let history = useHistory();
  const dispatch: any = useDispatch();

  const handleBackClick = () => {
    localStorage.setItem('isBackToHomeLogin', 'true');
    dispatch(clearErrors());
    history.goBack();
  };

  return (
    <Layout signIn loginButtonPressedMain={() => handleBackClick()}>
      {/* <main className={styles.form}> */}
      {/* <Route
              exact
              path="/password/email"
              render={() => {
                return <ResetEmail />;
              }}
            />
            */}
      {/* </main> */}

      <Route
        exact
        path="/password/reset/:token"
        render={() => {
          return <ResetPassword />;
        }}
      />
      <Route
        exact
        path="/register"
        render={() => {
          return (
            <div className={styles.wrapper}>
              <div className={styles.infoBlock}>
                <p className={styles.heading}>
                  Hey,
                  <span className={styles.boldText}>
                    {' '}
                    {localStorage.getItem('firstName')}!
                  </span>
                </p>
                <p>
                  Do you know what LEO can do for you? Finish
                  <br /> your sign up and discover
                </p>
                <div className={styles.innerInfoBlock}>
                  <div className={styles.blockItem}>
                    <img
                      src={require('../../assets/images/register-book.svg')}
                    />
                    <p>Read, Listen or watch at your own pace.</p>
                  </div>
                  <div className={styles.blockItem}>
                    <img
                      src={require('../../assets/images/register-book-extended.svg')}
                    />
                    <p>
                      Adapt to your preferences like fonts, spacings and
                      colours.
                    </p>
                  </div>
                  <div className={styles.blockItem}>
                    <img
                      src={require('../../assets/images/register-phone.svg')}
                    />
                    <p>
                      Use Leo for free when and where you want at your terms.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.registerBlockWrapper}>
                <RegisterMain />
              </div>
            </div>
          );
        }}
      />
    </Layout>
  );
};
const mapStateToProps = (state: StateType) => {
  return {
    state: state.auth
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
