import React, {} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {DispatchType, StateType} from "../../../redux/store";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {alertCloseThunk} from "../../../redux/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function CustomizedSnackbars(props: any) {
  const classes = useStyles();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    props.alertCloseThunk();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={props.alert.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={props.alert.type}>
          {props.alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state: StateType) => {
  return {
    auth: state.auth,
    alert: state.alert,
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => {
  return bindActionCreators({alertCloseThunk}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedSnackbars);
