import React, { useEffect } from 'react';
import styles from './FAQ.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../redux/store';
import { logoutThunk } from '../../redux/Auth';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FAQdata } from '../../helpers/FAQdata';
import Layout from '../../components/Layout/Layout';
import { showTitle } from '../../helpers/constants';

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value?: string;
};

export const FAQ: React.FC<StateType> = () => {
  let history = useHistory();
  const profile = useSelector((state: StateType) => state.auth);
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState('general');
  showTitle('FAQ');
  window.scrollTo({ top: 0, behavior: 'smooth' });

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch(logoutThunk);
  }, [dispatch]);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleBackClickSignUp = () => {
    localStorage.setItem('isBackToHomeSignUp', 'true');
    history.push('/login');
  };

  const handleBackClickLogin = () => {
    localStorage.setItem('isBackToHomeLogin', 'true');
    history.push('/login');
  };

  return (
    <Layout
      signUpButtonPressedMain={() => handleBackClickSignUp()}
      loginButtonPressedMain={() => handleBackClickLogin()}
    >
      <main className={styles.main}>
        <div className={styles.toHome}>
          <div className={styles.button} onClick={handleBackClick}>
            <h1>Need help?</h1>
          </div>
        </div>
        <div className={styles.faq}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            className={styles.tabs}
            // indicatorColor="primary"
            classes={{ indicator: styles.tabIndicator }}
            textColor="primary"
            variant="fullWidth"
            centered
          >
            <Tab
              value="general"
              label="General"
              classes={{ selected: styles.tabSelected }}
            />
            <Tab
              value="reading"
              label="Reading"
              classes={{ selected: styles.tabSelected }}
            />
            <Tab
              value="listening"
              label="Listening"
              classes={{ selected: styles.tabSelected }}
            />
            <Tab
              value="watching"
              label="Watching"
              classes={{ selected: styles.tabSelected }}
            />
          </Tabs>
          <TabPanel value={tabValue} index={'general'}>
            {FAQdata.general.map((el: any, index: number) => (
              <>
                <Accordion className={styles.accordionItem}>
                  <AccordionSummary
                    className={styles.accordionSummary}
                    classes={{ expandIcon: styles.accordionExpandIcon }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={styles.accordionHeading}>
                      {el.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={styles.accordionDetails}>
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{ __html: el.answer }}
                      ></div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {FAQdata.general.length > index + 1 && <hr />}
              </>
            ))}
          </TabPanel>
          <TabPanel value={tabValue} index={'reading'}>
            {FAQdata.reading.map((el: any, index: number) => (
              <>
                <Accordion className={styles.accordionItem}>
                  <AccordionSummary
                    className={styles.accordionSummary}
                    classes={{ expandIcon: styles.accordionExpandIcon }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={styles.accordionHeading}>
                      {el.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={styles.accordionDetails}>
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{ __html: el.answer }}
                      ></div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {FAQdata.reading.length > index + 1 && <hr />}
              </>
            ))}
          </TabPanel>
          <TabPanel value={tabValue} index={'listening'}>
            {FAQdata.listening.map((el: any, index: number) => (
              <>
                <Accordion className={styles.accordionItem}>
                  <AccordionSummary
                    className={styles.accordionSummary}
                    classes={{ expandIcon: styles.accordionExpandIcon }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={styles.accordionHeading}>
                      {el.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={styles.accordionDetails}>
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{ __html: el.answer }}
                      ></div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {FAQdata.listening.length > index + 1 && <hr />}
              </>
            ))}
          </TabPanel>
          <TabPanel value={tabValue} index={'watching'}>
            {FAQdata.watching.map((el: any, index: number) => (
              <>
                <Accordion className={styles.accordionItem}>
                  <AccordionSummary
                    className={styles.accordionSummary}
                    classes={{ expandIcon: styles.accordionExpandIcon }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={styles.accordionHeading}>
                      {el.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={styles.accordionDetails}>
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{ __html: el.answer }}
                      ></div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {FAQdata.watching.length > index + 1 && <hr />}
              </>
            ))}
          </TabPanel>
        </div>
      </main>
    </Layout>
  );
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.tabBox} p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default FAQ;
