import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../redux/store';
import { handlerCSS } from '../../helpers/cssHelper';
import { ReactComponent as LeftArrow } from '../../assets/images/LeftArrow.svg';
import styles from './RulerArrow.module.scss';

type PropsType = {
  onTopArrowClick: () => void;
  onBottomArrowClick: () => void;
};

const RulerArrow: React.FC<PropsType> = ({
  onTopArrowClick,
  onBottomArrowClick
}) => {
  const { reader } = useSelector((state: StateType) => state);

  return (
    <div className={styles.rulerArrowWrapper}>
      <div className={styles.rulerArrowContainer}>
        <div className={styles.rulerArrow} id="arrow">
          <div className={styles.rulerIconsWrapper}>
            <div className={styles.rulerIconTop} onClick={onTopArrowClick}>
              <LeftArrow />
            </div>
            <div
              className={styles.rulerIconBottom}
              onClick={onBottomArrowClick}
            >
              <LeftArrow />
            </div>
          </div>
        </div>
        {reader.settings.ruler_top ? (
          <div className={styles.rightStringTop} />
        ) : null}
        {reader.settings.ruler_bottom ? (
          <div className={styles.rightStringBottom} />
        ) : null}
      </div>
    </div>
  );
};

export default RulerArrow;
