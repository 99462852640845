import React, { useEffect, useState } from 'react';
import { Input, Slider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import { setSettings } from '../../../../../redux/Reader';
import styles from './WordSpacingSlider.module.scss';
import { ReactComponent as Wordspacing } from '../../../../../assets/images/Wordspacing.svg';
import SliderComponent from '../../../Slider/Slider';
const range = {
  min: '1',
  max: '10'
};
const WordSpacingSlider = () => {
  const dispatch = useDispatch();
  const { word_spacing } = useSelector(
    (state: StateType) => state.reader.settings
  );
  const [inputValue, setInputValue] = useState(word_spacing);

  useEffect(() => {
    setInputValue(word_spacing);
  }, [word_spacing]);

  const handleChange = (event: any, value: any) => {
    dispatch(setSettings({ word_spacing: value }));
  };

  const handleInputClick = (event: any) => event.target.select();

  const handleInputChange = (event: any) => {
    const ev = Number(event.target.value);
    if (ev === 0) {
      setInputValue('');
    }
    if (ev >= Number(range.min) && ev <= Number(range.max)) {
      setInputValue(String(ev));
    }
  };

  const handleInputKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      dispatch(
        setSettings({
          word_spacing: inputValue.length ? inputValue : range.min
        })
      );
    }
  };

  return (
    <div>
      <div className={styles.titleBlock}>
        <Wordspacing width={'24px'} className={styles.icon} />
        <div className={styles.sliderTitle}>Word spacing:</div>
        <Input
          className={styles.wordSpacingInput}
          value={inputValue}
          onClick={handleInputClick}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          inputProps={{
            type: 'number',
            'aria-labelledby': 'slider',
            min: Number(range.min),
            max: Number(range.max)
          }}
          disableUnderline={true}
        />
      </div>
      <SliderComponent
        min={Number(range.min)}
        max={Number(range.max)}
        step={0.05}
        value={Number(word_spacing)}
        onChange={handleChange}
      />
    </div>
  );
};

export default WordSpacingSlider;
