import React from 'react';
import styles from './WallpaperPicker.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import { setSettings } from '../../../../../redux/Reader';
import SwitchComponent from '../../../Switch/Switch';

export default function WallpaperPicker() {
  const dispatch = useDispatch();
  const { wallpaper_on } = useSelector(
    (state: StateType) => state.reader.settings
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      dispatch(setSettings({ wallpaper_on: wallpaper_on === 1 ? 0 : 1 }));
    } else {
      dispatch(setSettings({ wallpaper_on: Number(event.target.checked) }));
    }
  };

  return (
    <div className={styles.wallpaperBlock}>
      <div className={styles.title}>Wallpaper</div>
      <SwitchComponent
        checked={Boolean(wallpaper_on)}
        onChange={handleChange}
        color="primary"
        name="checkedB"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </div>
  );
}
