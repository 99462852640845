import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo
} from 'react';
import YouTube from 'react-youtube';
import { useSelector, useDispatch } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button } from '@material-ui/core';

import { ReactComponent as FastRewindIcon } from '../../assets/images/rewind_10_sec.svg';
import { ReactComponent as FastForwardIcon } from '../../assets/images/skip_10_sec.svg';
import { ReactComponent as FullscreenIcon } from '../../assets/images/Fullscreen.svg';
import { ReactComponent as Play } from '../../assets/images/Play.svg';
import { ReactComponent as Pause } from '../../assets/images/Pause.svg';
import { ReactComponent as ModalCloseIcon } from '../../assets/images/modalCloseIcon.svg';

import { handlerCSS } from '../../helpers/cssHelper';
import { StateType } from '../../redux/store';
import { useInterval } from '../../helpers/customHooks';
import {
  setSelectedParagraph,
  ParagraphType,
  setPageNumber
} from '../../redux/Page';

import styles from './VideoPlayer.module.scss';
import { isMobileWidth } from '../../helpers/constants';

type PropsVideo = {
  videoId: string;
  startSeconds: number;
  endSeconds: number;
};

const VideoPlayer = ({
  paragraph,
  closeVideo,
  isChapter,
  chapterPlay,
  isPageEnded,
  playerHide,
  pause,
  fullScreen,
  setFullscreen
}: any) => {
  const isDragging = useRef(false);
  const dragHeadRef = useRef<any>();
  const player = useRef<any>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [play, setPlay] = useState(false);
  const [propsVideo, setPropsVideo] = useState<PropsVideo>({} as PropsVideo);
  const [progress, setProgress] = useState(0);
  const [nextParagraph, setNextParagraph] = useState<any>({});
  const [delay, setDelay] = useState(100);
  const [currentTime, setCurrentTime] = useState(0);
  const [chapterLength, setChapterLength] = useState(0);
  const [videoLength, setVideoLength] = useState(0);
  const [chosenParagraph, setChosenParagraph] = useState<any>({});
  const [nextPage, setNextPage] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isMobileVideoInitiated, setIsMobileVideoInitiated] = useState(false);
  const { page } = useSelector((state: StateType) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSelectedParagraph(0));
      playerHide();
    };
  }, []);

  useEffect(() => {
    if (
      isMobileWidth &&
      isVideoReady &&
      !isMobileVideoInitiated &&
      window.navigator.appVersion.indexOf('Mac') !== -1
    ) {
      setPlay(true);
      forward();
      rewind();
      setIsMobileVideoInitiated(true);
    }
  }, [isVideoReady]);

  //pause video if user selected an other paragraph
  useEffect(() => {
    if (pause) {
      setPlay(true);
      player.current.internalPlayer.pauseVideo();
    }
  }, [pause]);

  useEffect(() => {
    if (page.chapterOrParagraph === '' && page.chosenParagraphId === 0) {
      setPlay(false);
      setProgress(0);
      setChosenParagraph(0);
      setPropsVideo({} as PropsVideo);
      player.current.internalPlayer.stopVideo();
    }
  }, [page.chapterOrParagraph, page.chosenParagraphId]);

  useEffect(() => {
    let next: any = {};

    page.paragraphs.forEach(
      (el, index) =>
        el.id === paragraph.id && (next = { ...page.paragraphs[index + 1] })
    );

    const nextPageParagraphTime =
      page.paragraphs[0].chapter_id === page.nextParagraphs[0].chapter_id &&
      page.nextParagraphs[0].video_time;
    if (isChapter) {
      setPropsVideo({
        videoId:
          paragraph.video_file &&
          paragraph.video_file.replace('https://youtu.be/', ''),
        startSeconds:
          Number(paragraph.video_time) === 0 ? 1 : Number(paragraph.video_time),
        endSeconds: chapterLength
      });
      setNextParagraph(next);
    } else if (!isChapter && paragraph) {
      setPropsVideo({
        videoId:
          paragraph.video_file &&
          paragraph.video_file.replace('https://youtu.be/', ''),
        startSeconds:
          Number(paragraph.video_time) === 0 ? 1 : Number(paragraph.video_time),
        endSeconds: next.video_time
          ? Number(next.video_time)
          : Number(nextPageParagraphTime)
      });
      setNextParagraph(next);
    }
  }, [paragraph]);

  useInterval(
    () => {
      let videoLength = 0;

      if (isChapter) {
        videoLength = chapterLength - propsVideo.startSeconds;
      } else if (!isChapter && propsVideo.endSeconds !== 0) {
        videoLength = propsVideo.endSeconds - propsVideo.startSeconds;
      } else if (!isChapter && propsVideo.endSeconds === 0) {
        videoLength = chapterLength - propsVideo.startSeconds;
      }

      setVideoLength(videoLength);
      //take current playing time and compute the percent value for a progress bar
      player.current.internalPlayer
        .getCurrentTime()
        .then((res: number) => {
          setCurrentTime(res - propsVideo.startSeconds);
        })
        .then(() => {
          const progPercents = Math.round(currentTime / (videoLength / 100));
          setProgress(
            progPercents < 0 || isNaN(progPercents) ? 0 : progPercents
          );
          if (videoLength > 0 && progPercents > 99) {
            closeVideo();
          }
        });

      //set length of a chapter
      player.current.internalPlayer.getDuration().then((res: number) => {
        setChapterLength(res);
      });

      // check which paragraph is should be selected for
      page.paragraphs.forEach((el, index) => {
        if (
          el.id === page.chosenParagraphId &&
          page.nextParagraphs[0].chapter_id === page.paragraphs[0].chapter_id
        ) {
          setChosenParagraph({ ...page.paragraphs[index + 1] });
        }
      });

      const isInRange = (min: number, max: number) => {
        if (max !== undefined && currentTime + min >= Number(max)) {
          chosenParagraph.id && chapterPlay(chosenParagraph.id);
        } else if (
          max === undefined &&
          currentTime + min >= Number(page.nextParagraphs[0].video_time)
        ) {
          chapterPlay(page.paragraphs[page.paragraphs.length - 1].id + 1);
          if (
            !chosenParagraph.id &&
            page.nextParagraphs[0].chapter_id === page.paragraphs[0].chapter_id
          ) {
            isPageEnded(page.paragraphs[page.paragraphs.length - 1].id + 1);
          } else if (
            page.nextParagraphs[0].chapter_id === page.paragraphs[0].chapter_id
          ) {
            isPageEnded(page.paragraphs[page.paragraphs.length - 1].id + 1);
          }

          //if there is no next selected paragraph than set the current as a selected
          // and also stop when the current paragraph is ended
          if (!chosenParagraph.id) {
            chapterPlay(page.paragraphs[page.paragraphs.length - 1].id);
            progress >= 99 && setPlay(false);
          }
        }
      };

      currentTime > 0 &&
        isChapter &&
        isInRange(propsVideo.startSeconds, chosenParagraph.video_time);

      //  logic for step back for rewind
      //let valueInSeconds = Math.round((videoLength / 100) * progress);
      const currentPosition = propsVideo.startSeconds + currentTime;

      if (isChapter) {
        page.paragraphs.forEach((el: ParagraphType, index) => {
          const nextEl = page.paragraphs[index + 1]
            ? page.paragraphs[index + 1].audio_time
            : page.nextParagraphs[0].audio_time;
          const prevEl = page.prevParagraphs[page.prevParagraphs.length - 1];
          if (
            currentPosition > Number(el.audio_time) &&
            currentPosition < Number(nextEl)
          ) {
            chapterPlay(el.id);
          } else if (
            currentPosition > Number(prevEl.audio_time) &&
            currentPosition < Number(page.paragraphs[0].audio_time)
          ) {
            chapterPlay(prevEl.id);
            isPageEnded(page.paragraphs[page.paragraphs.length - 1].id - 1);
            dispatch(setPageNumber(Number(el.page) - 1));
          }
        });
      }
      // handle play/pause when paragraph is end

      if (!isChapter && progress >= 99) {
        setProgress(0);
        setPlay(false);
      }
    },

    !play ? delay : null
  );

  const onMouseDown = useCallback(e => {
    if (dragHeadRef.current && dragHeadRef.current.contains(e.target)) {
      isDragging.current = true;
    }
  }, []);

  const onMouseUp = useCallback(() => {
    if (isDragging.current) {
      isDragging.current = false;
    }
  }, []);

  const onMouseMove = useCallback(
    e => {
      if (isDragging.current) {
        setPosition({
          x: position.x + e.movementX,
          y: position.y + e.movementY
        });
      }
    },
    [position]
  );

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove, onMouseDown, onMouseUp]);

  const stylesPlayer = useMemo(
    () => ({
      cursor: isDragging.current ? '-webkit-grabbing' : '-webkit-grab',
      transform: `translate(${position.x}px, ${position.y}px)`,
      transition: isDragging.current ? 'none' : 'transform 500ms'
    }),
    [position]
  );

  const opts: any = {
    playerVars: {
      autoplay: isMobileWidth ? 0 : 1,
      controls: 0,
      rel: 0
    },
    frameborder: 0,
    modestbranding: 1
  };

  useEffect(() => {
    page.chosenParagraphId !== 0 &&
      player.current.internalPlayer.loadVideoById(propsVideo);
    setPlay(false);
  }, [nextParagraph]);

  const playPause = () => {
    if (!play) {
      player.current.internalPlayer.pauseVideo();
      setPlay(true);
    } else {
      player.current.internalPlayer.playVideo();
      setPlay(false);
    }
  };
  const rewind = () => {
    player.current.internalPlayer.getCurrentTime().then((res: number) => {
      res > propsVideo.startSeconds + 10 &&
        player.current.internalPlayer.seekTo(res - 10);
    });
  };
  const forward = () => {
    const endTime = propsVideo.startSeconds + videoLength;
    player.current.internalPlayer.getCurrentTime().then((res: number) => {
      if (res < endTime - 10) {
        player.current.internalPlayer.seekTo(res + 10);
      } else if (res < endTime - 5) {
        player.current.internalPlayer.seekTo(res + 5);
      }
    });
  };

  const fullScreenHandler = () => {
    setFullscreen(!fullScreen);

    fullScreen
      ? handlerCSS({ fullScreen: '2' })
      : handlerCSS({ fullScreen: '10' });
  };

  const onReady = (e: any) => {
    e.target.pauseVideo();
    setIsVideoReady(true);
  };

  return (
    <div className={styles.parent}>
      <div className={`${styles.wrapper}`} id="player">
        <div
          ref={dragHeadRef}
          className={`${fullScreen && styles.fullScreenSet}`}
          style={stylesPlayer}
        >
          <div className={styles.hoverDiv}>
            <div className={styles.controls}>
              <div>
                <Button className={styles.rewind} onClick={rewind}>
                  <FastRewindIcon />
                </Button>
                <Button
                  className={styles.playPause}
                  onClick={playPause}
                  disabled={!play && progress === 0}
                >
                  {play ? <Play /> : <Pause />}
                </Button>
                <Button className={styles.forward} onClick={forward}>
                  <FastForwardIcon />
                </Button>
                <Button
                  className={styles.fullScreen}
                  onClick={fullScreenHandler}
                >
                  <FullscreenIcon />
                </Button>
                <Button className={styles.closeButton} onClick={closeVideo}>
                  <ModalCloseIcon />
                </Button>
              </div>
              <div className={styles.progressBar}>
                <LinearProgress
                  classes={{
                    root: styles.progressBarRoot,
                    bar: styles.progressBarBar
                  }}
                  variant="determinate"
                  value={progress}
                />
              </div>
            </div>
          </div>
          <YouTube ref={player} onReady={onReady} opts={opts} />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
