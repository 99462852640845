import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';

import Cookie from '../common/Cookie/Cookie';

import styles from './Footer.module.scss';

type Props = {
  isWhiteBackground?: boolean;
};

const Footer: React.FC<Props> = ({ isWhiteBackground }) => {
  const footerRef: any = useRef();

  return (
    <footer
      ref={footerRef}
      className={`${styles.footer} ${
        isWhiteBackground && styles.whiteBackground
      }`}
    >
      <div className={styles.left_group}>
        <div className={styles.links}>
          <NavLink to={'/terms'}>Terms of use</NavLink>
          <NavLink to={'/privacy'}>Privacy Policy</NavLink>
          <NavLink to={'/help'}>FAQ</NavLink>
        </div>
        <div className={styles.commercialBreak}>
          <p>&#169; Commercial Break, 2023</p>
        </div>
      </div>
      <div className={styles.right_group}>
        <div>
          Created in the UK{'  '}
          <img src={require('../../assets/images/Britain.png')} />
        </div>
        <div>
          Built in Ukraine{'  '}
          <img src={require('../../assets/images/Ukraine.png')} />
        </div>
      </div>
      <div className={styles.commercialBreakMobile}>
        <p>&#169; Commercial Break, 2023</p>
      </div>
      <Cookie anchorEl={footerRef} />
    </footer>
  );
};

export default Footer;
