import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import styles from './Login.module.scss';
import { Button, Modal } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { TextField } from 'formik-material-ui';
import { Link, Redirect } from 'react-router-dom';
import { loginThunk } from '../../redux/Auth';
import { connect } from 'react-redux';
import { DispatchType, StateType } from '../../redux/store';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

const Login = (props: any) => {
  const initialValues = {
    email: '',
    password: '',
  };

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
      .min(8, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  function onSubmit(values: any, { setSubmitting }: any) {
    Promise.resolve('').then(
      props.loginThunk(values.email, values.password).then(function () {
        setSubmitting(false);
      })
    );
  }

  if (props.state.userSession && props.state.userSession.user) {
    return <Redirect to={'/'} />;
  }

  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.description}>
        <h1>
          WHERE
          <br /> THE BOOKS
          <br /> ADAPT
          <br /> TO YOU
        </h1>
        <p>
          We are a neurodivergent friendly eReader designed
          <br />
          for education in the creative industries
        </p>
      </div>
      <div className={styles.form}>
        <div className={styles.title}>
          <h1>WELCOME</h1>
          {props.state.validation.errors.credentials ? (
            <Alert className={styles.alertError} severity="error">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.state.validation.errors.credentials,
                }}
              />
            </Alert>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state.auth,
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => {
  return bindActionCreators({ loginThunk }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
