import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Button } from '@material-ui/core';

import styles from './Button.module.scss';

type PropsT = {
  variant: 'primary' | 'secondary' | 'ghost';
  children: ReactElement;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
};

const ButtonComponent = ({ children, variant, onClick, type }: PropsT) => {
  return (
    <div className={styles.mainButton}>
      <Button
        type={type}
        onClick={onClick}
        id={styles.button}
        className={
          variant === 'ghost'
            ? styles.ghostButton
            : variant === 'secondary'
            ? styles.secondaryButton
            : styles.primaryButton
        }
      >
        {children}
      </Button>
    </div>
  );
};

export default ButtonComponent;
