import React, { useEffect } from 'react';
import styles from './Users.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../redux/store';

export const Users: React.FC<StateType> = (props) => {
  const profile = useSelector((state: StateType) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  return (
    <div className={styles.home}>
      <h1>Users</h1>
    </div>
  );
};

export default Users;
