import {
  instance,
  ResultCodesEnum,
  ResponseType,
} from './api';

type LoginResponseType = {
  message: string;
};

export const authAPI = {
  login(
    email: string,
    password: string,
    //rememberMe = false
  ) {
    return instance.post<ResponseType<LoginResponseType,
      ResultCodesEnum>>('auth/login', {email, password});
  },
  register(data: object) {
    return instance.post<ResponseType<LoginResponseType,
      ResultCodesEnum /*| ResultCodeForCaptchaEnum*/>>('auth/register', data);
  },
  logout() {
    return instance.delete(`auth/login`);
  },
  passwordEmail(email: string) {
    return instance.post<ResponseType<LoginResponseType,
      ResultCodesEnum>>('auth/password/email', {email});
  },
  passwordReset(data: object) {
    return instance.post<ResponseType<
      ResultCodesEnum>>('auth/password/reset', data);
  },
};
