import React from 'react';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import styles from './Switch.module.scss';
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 49,
  height: 26,
  padding: 0,
  zIndex: 1,
  paddingRight: 1,
  paddingBottom: 1,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '200ms',
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#6F8EF0',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
    zIndex: 3
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

type Props = {
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: any) => void;
  color?: 'primary' | 'secondary' | 'default';
  name: string;
  inputProps?: any;
};

export const SwitchComponent: React.FC<Props> = ({
  checked,
  onChange,
  color,
  name,
  inputProps
}) => {
  return (
    <div className={styles.switchWrapper}>
      <IOSSwitch
        checked={checked}
        onClick={onChange}
        color={color}
        name={name}
        inputProps={inputProps}
      />
    </div>
  );
};

export default SwitchComponent;
