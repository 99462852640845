import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import toolbarReducer from './Toolbar';
import readerReducer from './Reader';
import homeReducer from './Home';
import authReducer from './Auth';
import pageReducer from './Page';
import chaptersReducer from './Chapters';
import profileReducer from './Profile';
import alertReducer from "./Alert";
import dictionaryReducer from "./Dictionary";

const middleware = [...getDefaultMiddleware({serializableCheck: false})];

export type StateType = ReturnType<typeof store.getState>;
export type DispatchType = typeof store.dispatch;

const store = configureStore({
  reducer: {
    toolbar: toolbarReducer,
    reader: readerReducer,
    home: homeReducer,
    auth: authReducer,
    chapters: chaptersReducer,
    page: pageReducer,
    profile: profileReducer,
    alert: alertReducer,
    dictionary: dictionaryReducer,
  },
  middleware,
});
export default store;
