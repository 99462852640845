import React, { useEffect, RefObject, useRef, useState } from 'react';

export const useFocus = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    if (ref && ref.current) {
      return ref.current.focus();
    }
  }, [ref]);
};

export const useInterval = (callback: any, delay: any) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      //@ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({
    x: null,
    y: null
  });
  useEffect(() => {
    const mouseMoveHandler = (event: any) => {
      const { clientX, clientY } = event;
      setMousePosition({ x: clientX, y: clientY });
    };
    document.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, []);
  return mousePosition;
};

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState<{
    x: number | null;
    y: number | null;
  }>({
    x: null,
    y: null
  });
  useEffect(() => {
    const scrollMoveHandler = () => {
      setScrollPosition({
        x: window.scrollX,
        y: window.scrollY
      });
    };
    document.addEventListener('scroll', scrollMoveHandler);
    return () => {
      document.removeEventListener('scroll', scrollMoveHandler);
    };
  }, []);
  return scrollPosition;
};
