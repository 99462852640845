import React, { useRef, useState, ReactElement, useEffect } from 'react';
import styles from './ButtonPopup.module.scss';
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import ToolPopup from '../ToolbarButton/ToolPopup/ToolPopup';
import FocusPopup from '../ToolbarButton/ToolPopup/FocusPopup/FocusPopup';
import RulerPopup from '../ToolbarButton/ToolPopup/RulerPopup/RulerPopup';
import { setActive } from '../../../redux/Reader';
import { useDispatch } from 'react-redux';
import { isBigMobileWidth } from '../../../helpers/constants';

type Props = {
  child: ReactElement;
  childBlue: ReactElement;
  el: {
    id: number;
    name: string;
    title: string;
    isSelected: boolean;
  };
  someFunction: Function;
  isActive?: boolean;
  isHideActiveArrow?: boolean;
  tooltipOpen?: boolean;
};

const ButtonPopup: React.FC<Props> = ({
  child,
  el,
  someFunction,
  childBlue,
  isHideActiveArrow,
  isActive,
  tooltipOpen
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handlePopoverOpen = (e: any, iconName: string) => {
    setAnchorEl(e.currentTarget);
    dispatch(setActive(iconName));
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    dispatch(setActive(''));
  };
  const open = Boolean(anchorEl);
  const id = open ? 'mouse-over-popover' : undefined;

  if (el.id === 4 && isBigMobileWidth) {
    return null;
  }

  return (
    <div
      id={el.name}
      className={`${isActive && styles.activeButton}`}
      onClick={() => someFunction()}
    >
      <ToolbarButton
        isHideActiveArrow={isHideActiveArrow}
        active={el.isSelected}
        key={el.id}
        imageName={el.title}
        child={child}
        childBlue={childBlue}
        aria-describedby={id}
        setElClick={(e: any) => handlePopoverOpen(e, el.name)}
        open={tooltipOpen}
      />

      {el.name === 'reading' && (
        <ToolPopup
          open={open}
          anchorEl={anchorEl}
          handlePopoverClose={handlePopoverClose}
        />
      )}
      {el.name === 'focus' && (
        <FocusPopup
          open={open}
          anchorEl={anchorEl}
          handlePopoverClose={handlePopoverClose}
        />
      )}
      {el.name === 'ruler' && (
        <RulerPopup
          open={open}
          anchorEl={anchorEl}
          handlePopoverClose={handlePopoverClose}
        />
      )}
    </div>
  );
};

export default ButtonPopup;
